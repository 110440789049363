import {Component, Input} from '@angular/core';
import {AbstractInputDirective} from '../AbstractInputDirective';

@Component({
  selector: 'digires-num-input',
  templateUrl: './num-input.component.html',
  styleUrls: ['./num-input.component.scss']
})
export class NumInputComponent extends AbstractInputDirective {
  @Input() decimalDigits: number = 2;
}
