import { HybridProjectFilterOption } from '../../model/general/HybridProjectFilterOption';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { BusinessModel } from '../../model/general/BusinessModel';
import { ProjectPhase } from '../../model/general/ProjectPhase';
import { ProjectStage } from '../../model/general/ProjectStage';
import { ProjectStatus } from '../../model/general/ProjectStatus';
import { ProjectType } from '../../model/general/ProjectType';
import { RouteToMarket } from '../../model/general/RouteToMarket';
import { Technology } from '../../model/general/Technology';
import { ConstructionCluster } from '../../model/geo/ConstructionCluster';
import { CountryAlpha2Code } from '../../model/geo/CountryAlpha2Code';
import { DevelopmentCluster } from '../../model/geo/DevelopmentCluster';
import { DevelopmentDirectorate } from '../../model/geo/DevelopmentDirectorate';
import { GlobalRegion } from '../../model/geo/GlobalRegion';
import { ProjectFilter } from '../../model/ProjectFilter';
import { NotificationService } from '../other/notification.service';
import { MyProjectsFilterOption } from '../../model/general/MyProjectsFilterOption';
import { FormUtils } from '../../FormUtils';
import { ProjectManagingApplication } from '../../model/general/ProjectManagingApplication';

@Injectable({
  providedIn: 'root'
})
export class ProjectFilterService {

  private readonly LOCAL_STORAGE_FILTER_KEY: string = 'digires-project-filter';
  private readonly allManagingApps: ProjectManagingApplication[] = Object.values(ProjectManagingApplication);
  private readonly allTechnologies: Technology[] = Object.values(Technology);
  private readonly allTypes = Object.values(ProjectType);
  private readonly allStatuses = Object.values(ProjectStatus);
  private readonly allPhases = Object.values(ProjectPhase);
  private readonly allStages = Object.values(ProjectStage);
  private readonly allGlobalRegions = Object.values(GlobalRegion);
  private readonly allDevCluster = Object.values(DevelopmentCluster);
  private readonly allConsCluster = Object.values(ConstructionCluster);
  private readonly allBusinessModels = Object.values(BusinessModel);
  private readonly allRouteToMarket = Object.values(RouteToMarket);
  private readonly allDevelopmentDirectorate = Object.values(DevelopmentDirectorate);

  private projectFilter: BehaviorSubject<ProjectFilter> = new BehaviorSubject<ProjectFilter>(this.createDefaultFilter());

  constructor(
    private notificationService: NotificationService,
    private client: HttpClient,
  ) {
    this.loadFilter();
  }

  public loadFilter() {
    const loadedFilter = this.loadFilterFromLocalStorage();
    if (loadedFilter) {
      this.projectFilter.next(loadedFilter);
    } else {
      this.projectFilter.next(this.createDefaultFilter());
    }
  }

  createHttpParamsForFilter(filter: ProjectFilter) {
    return {
      projectName: filter.projectName || '',
      managerUserId: filter.managerUserId || '',
      managingApplication: filter.managingApplication || [],
      technology: filter.technology || [],
      type: filter.type || [],
      status: filter.status || [],
      phase: filter.phase || [],
      stage: filter.stage || [],
      businessModel: filter.businessModel || [],
      hybridProject: filter.hybridProject || HybridProjectFilterOption.ALL_PROJECTS,
      teamId: filter.teamId || [],
      routeToMarket: filter.routeToMarket || [],
      developmentDirectorate: filter.developmentDirectorate || [],
      countryCode: filter.countryCode || [],
      globalRegion: filter.globalRegion || [],
      developmentCluster: filter.developmentCluster || [],
      constructionCluster: filter.constructionCluster || [],
      region: filter.region || [],
      myProjects: filter.myProjects || MyProjectsFilterOption.ALL_PROJECTS,
    };
  }

  async getProjectIdsFor(filter: ProjectFilter): Promise<string[]> {
    return await firstValueFrom(this.client.get<string[]>('/api/project-ids', {
      params: new HttpParams({fromObject: this.createHttpParamsForFilter(filter)})
    }));
  }

  observeProjectFilter(): Observable<ProjectFilter> {
    this.loadFilter();
    return this.projectFilter.asObservable();
  }

  getProjectFilter(): ProjectFilter {
    return this.projectFilter.getValue();
  }

  patchFilter(values: any) {
    const oldFilter = this.getProjectFilter();
    const newFilter = Object.assign(Object.assign(new ProjectFilter(), oldFilter), values);
    if (this.isFilterDifferent(oldFilter, newFilter)) {
      this.projectFilter.next(newFilter);
      this.saveFilterToLocalStorage();
    }
  }

  public isFilterDifferent(a: ProjectFilter, b: ProjectFilter): boolean {
    return a.projectName !== b.projectName
      || a.myProjects !== b.myProjects
      || a.hybridProject !== b.hybridProject
      || a.managerUserId !== b.managerUserId
      || !FormUtils.arraysEquals(a.managingApplication, b.managingApplication)
      || !FormUtils.arraysEquals(a.technology, b.technology)
      || !FormUtils.arraysEquals(a.type, b.type)
      || !FormUtils.arraysEquals(a.status, b.status)
      || !FormUtils.arraysEquals(a.phase, b.phase)
      || !FormUtils.arraysEquals(a.stage, b.stage)
      || !FormUtils.arraysEquals(a.countryCode, b.countryCode)
      || !FormUtils.arraysEquals(a.globalRegion, b.globalRegion)
      || !FormUtils.arraysEquals(a.developmentCluster, b.developmentCluster)
      || !FormUtils.arraysEquals(a.constructionCluster, b.constructionCluster)
      || !FormUtils.arraysEquals(a.businessModel, b.businessModel)
      || !FormUtils.arraysEquals(a.routeToMarket, b.routeToMarket)
      || !FormUtils.arraysEquals(a.developmentDirectorate, b.developmentDirectorate)
      || !FormUtils.arraysEquals(a.region, b.region)
      || !FormUtils.arraysEquals(a.teamId, b.teamId)
      ;
  }

  filterForCountry(countryCode: CountryAlpha2Code): void {
    this.patchFilter({
      countryCode: [countryCode],
    });
  }

  filterForType(value: Technology): void {
    if (this.getProjectFilter().technology.indexOf(value) >= 0) {
      return;
    }
    this.patchFilter({
      technology: [...this.getProjectFilter().technology, value],
    });
  }

  filterForMyProjects(value: MyProjectsFilterOption) {
    this.patchFilter({
      myProjects: value,
    });
  }

  removeNameFilter() {
    this.patchFilter({
      projectName: null,
    });
  }

  removeTeamFilter(value: string) {
    this.patchFilter({
      teamId: this.copyArrayAndRemove(this.getProjectFilter().teamId, value, []),
    });
  }

  removeProjectManagerFilter() {
    this.patchFilter({
      managerUserId: '',
    });
  }

  removeManagingApplicationFilter(value: ProjectManagingApplication) {
    this.patchFilter({
      managingApplication: this.copyArrayAndRemove(this.getProjectFilter().managingApplication, value, this.allManagingApps),
    });
  }

  removeTechnologyFilter(value: Technology) {
    this.patchFilter({
      technology: this.copyArrayAndRemove(this.getProjectFilter().technology, value, this.allTechnologies),
    });
  }

  removeHybridProjectStatusFilter() {
    this.patchFilter({
      hybridProject: HybridProjectFilterOption.ALL_PROJECTS,
    });
  }

  removeTypeFilter(value: ProjectType) {
    this.patchFilter({
      type: this.copyArrayAndRemove(this.getProjectFilter().type, value, this.allTypes),
    });
  }

  removeMyProjectFilter() {
    this.patchFilter({
      myProjects: MyProjectsFilterOption.ALL_PROJECTS,
    });
  }

  removeStatusFilter(value: ProjectStatus) {
    this.patchFilter({
      status: this.copyArrayAndRemove(this.getProjectFilter().status, value, this.allStatuses),
    });
  }

  removePhaseFilter(value: ProjectPhase) {
    this.patchFilter({
      phase: this.copyArrayAndRemove(this.getProjectFilter().phase, value, this.allPhases),
    });
  }

  removeStageFilter(value: ProjectStage) {
    this.patchFilter({
      stage: this.copyArrayAndRemove(this.getProjectFilter().stage, value, this.allStages),
    });
  }

  removeCountryFilter(value: CountryAlpha2Code) {
    this.patchFilter({
      countryCode: this.copyArrayAndRemove(this.getProjectFilter().countryCode, value, []),
    });
  }

  removeRegionFilter(value: string) {
    this.patchFilter({
      region: this.copyArrayAndRemove(this.getProjectFilter().region, value, []),
    });
  }

  removeGlobalRegionFilter(value: GlobalRegion) {
    this.patchFilter({
      globalRegion: this.copyArrayAndRemove(this.getProjectFilter().globalRegion, value, this.allGlobalRegions),
    });
  }

  removeDevelopmentClusterFilter(value: DevelopmentCluster) {
    this.patchFilter({
      developmentCluster: this.copyArrayAndRemove(this.getProjectFilter().developmentCluster, value, this.allDevCluster),
    });
  }

  removeConstructionClusterFilter(value: ConstructionCluster) {
    this.patchFilter({
      constructionCluster: this.copyArrayAndRemove(this.getProjectFilter().constructionCluster, value, this.allConsCluster),
    });
  }

  removeBusinessModelFilter(value: BusinessModel) {
    this.patchFilter({
      businessModel: this.copyArrayAndRemove(this.getProjectFilter().businessModel, value, this.allBusinessModels),
    });
  }

  removeRouteToMarketFilter(value: RouteToMarket) {
    this.patchFilter({
      routeToMarket: this.copyArrayAndRemove(this.getProjectFilter().routeToMarket, value, this.allRouteToMarket),
    });
  }

  removeDevelopmentDirectorateFilter(value: DevelopmentDirectorate) {
    this.patchFilter({
      developmentDirectorate: this.copyArrayAndRemove(this.getProjectFilter().developmentDirectorate, value, this.allDevelopmentDirectorate),
    });
  }

  resetFilters() {
    this.patchFilter(this.createDefaultFilter());
  }

  // noinspection JSMethodCanBeStatic
  private copyArrayAndRemove<X>(original: X[], value: X, all: X[]): X[] {
    const result = original.slice();
    const index = result.indexOf(value);
    if (index >= 0) {
      result.splice(index, 1);
    }
    if (result.length === 0) {
      return all;
    }
    return result;
  }

  public createFilter(filter: Partial<ProjectFilter>): ProjectFilter {
    return Object.assign(this.createDefaultFilter(), filter);
  }

  public createDefaultFilter(): ProjectFilter {
    return {
      projectName: null,
      myProjects: MyProjectsFilterOption.ALL_PROJECTS,
      hybridProject: HybridProjectFilterOption.ALL_PROJECTS,
      managingApplication: this.allManagingApps,
      technology: this.allTechnologies,
      type: this.allTypes,
      status: [ProjectStatus.ACTIVE, ProjectStatus.ON_HOLD],
      phase: this.allPhases,
      stage: this.allStages,
      businessModel: this.allBusinessModels,
      managerUserId: null,
      teamId: [],
      routeToMarket: this.allRouteToMarket,
      developmentDirectorate: this.allDevelopmentDirectorate,
      countryCode: [],
      globalRegion: this.allGlobalRegions,
      developmentCluster: this.allDevCluster,
      constructionCluster: this.allConsCluster,
      region: [],
    };
  }

  private loadFilterFromLocalStorage(): ProjectFilter {
    const fromLocalStorage = localStorage.getItem(this.LOCAL_STORAGE_FILTER_KEY);
    if (!fromLocalStorage) {
      return null;
    }
    const loadedFilter: ProjectFilter = Object.assign(new ProjectFilter(), JSON.parse(fromLocalStorage));
    if (this.isValidFilter(loadedFilter)) {
      return loadedFilter;
    }
    return null;
  }

  private saveFilterToLocalStorage(): void {
    const filterStr = JSON.stringify(this.projectFilter.getValue());
    localStorage.setItem(this.LOCAL_STORAGE_FILTER_KEY, filterStr);
  }

  // noinspection JSMethodCanBeStatic
  private isValidFilter(filter: ProjectFilter): boolean {
    return filter
      && filter.managingApplication
      && filter.technology
      && filter.type
      && filter.status
      && filter.phase
      && filter.stage
      && filter.countryCode
      && filter.globalRegion
      && filter.developmentCluster
      && filter.constructionCluster
      && filter.hybridProject
      && filter.myProjects
      && filter.teamId
      && true
      ;
  }

}
