import {DateUtil} from '../DateUtil';

export class ReportMonth {

  public static readonly MONTH_ABBR = [
    'Jan. ', 'Feb. ', 'Mar. ', 'Apr. ', 'May. ', 'Jun. ', 'Jul. ', 'Aug. ', 'Sep. ', 'Oct. ', 'Nov. ', 'Dec. '
  ];
  public static readonly MONTH_NAME = [
    'January ', 'February ', 'March ', 'April ', 'May ', 'June ', 'July ', 'August ', 'September ', 'October ', 'November ', 'December '
  ];

  year: number;
  month: number;

  public static alignedWithRitasMonth(): ReportMonth {
    const date = new Date(Date.now());
    const dayOfMonth = date.getDate();
    if (dayOfMonth < 16) {
      date.setDate(1);
      date.setMonth(date.getMonth() - 1);
    }
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
  }

  public static today(): ReportMonth {
    const date = new Date(Date.now());
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
  }

  public static previous(): ReportMonth {
    const date = new Date(Date.now());
    const firstDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return {
      year: firstDayOfPreviousMonth.getFullYear(),
      month: firstDayOfPreviousMonth.getMonth() + 1,
    };
  }

  public static previousOf(reportMonth: ReportMonth): ReportMonth {
    const firstDayOfPreviousMonth = new Date(reportMonth.year, reportMonth.month - 2, 1);
    return {
      year: firstDayOfPreviousMonth.getFullYear(),
      month: firstDayOfPreviousMonth.getMonth() + 1,
    };
  }

  public static from(date: Date): ReportMonth {
    if (!DateUtil.isDate(date)) {
      date = DateUtil.toDate(date);
    }
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
  }

  public static of(month: number, year: number): ReportMonth {
    return {
      year: year,
      month: month,
    };
  }

  public static fromString_yyyy_mm(str: string): ReportMonth {
    if (str.length === 7 && str[4] === '-') {
      const yearStr = str.substring(0, 4);
      const monthStr = str.substring(5, 7);
      const yearVal = Number(yearStr);
      const monthVal = Number(monthStr);
      return {
        year: yearVal,
        month: monthVal,
      };
    }
    return {
      year: 0,
      month: 0,
    };
  }

  public static isValid(rm: ReportMonth): boolean {
    return rm.year >= 2000 && rm.year <= 9999 && rm.month >= 1 && rm.month <= 12;
  }

  public static isBefore(rm: ReportMonth, other: ReportMonth): boolean {
    return this.isValid(rm) && (rm.year < other.year || (rm.year === other.year && rm.month < other.month));
  }

  public static isAfter(rm: ReportMonth, other: ReportMonth): boolean {
    return this.isValid(rm) && (rm.year > other.year || (rm.year === other.year && rm.month > other.month));
  }

  public static isSame(rm: ReportMonth, other: ReportMonth): boolean {
    return rm.year === other.year && rm.month === other.month;
  }

  public static inPast(rm: ReportMonth): boolean {
    return this.isBefore(rm, this.today());
  }

  public static inFuture(rm: ReportMonth): boolean {
    return this.isAfter(rm, this.today());
  }

  public static mm_yyyy(rm: ReportMonth): string {
    return this.padNum(rm.month) + '-' + rm.year;
  }

  public static mmm_yyyy(rm: ReportMonth): string {
    return this.MONTH_ABBR[rm.month - 1] + rm.year;
  }

  public static monthName(rm: ReportMonth): string {
    return this.MONTH_NAME[rm.month - 1];
  }

  public static yyyy_mm(rm: ReportMonth): string {
    return rm.year + '-' + this.padNum(rm.month);

  }
  public static mm_yyyy_for_chris(rm: ReportMonth): string {
    return this.padNum(rm.month) + '/' + rm.year;
  }

  private static padNum(value: number): string {
    if (value < 10) {
      return '0' + value;
    }
    return '' + value;
  }

  public static compare(a: ReportMonth, b: ReportMonth): number {
    const valA = a.year * 100 + a.month;
    const valB = b.year * 100 + b.month;
    return valB - valA;
  }

}
