import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {AbstractInputDirective} from '../AbstractInputDirective';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'digires-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.scss']
})
export class ListInputComponent extends AbstractInputDirective {

  @ViewChild('input') inputElement: MatSelect;

  @Input() includeBlank = false;
  @Input() items: any[] = [];
  @Input() itemToId: ((value: any) => any) = value => value;
  @Input() itemToLabel: ((value: any) => string) = value => value;

  getReadonlyValue(): string {
    const formValue = this.getValue();
    if (!formValue) {
      return null;
    }
    const choiceValue = this.items.find(choice => this.itemToId(choice) === formValue);
    if (!choiceValue) {
      return null;
    }
    return this.itemToLabel(choiceValue);
  }

  requestFocusForInput() {
    if (this.inputElement) {
      this.inputElement.focus();
      this.inputElement.open();
    }
  }

}
