import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IptPhase } from './model/team/IptPhase';
import { TeamMember } from './model/team/TeamMember';
import { GetUsers } from './services/user/GetUsers';
import { NonDeletableRoleId } from './services/team-role/NonDeletableRoleId';
import { GetTeamRoles } from './services/team-role/GetTeamRoles';
import { ProjectPhase } from './model/general/ProjectPhase';

export class TeamRoleUtil {

  static getIptPhaseFor(projectPhase: ProjectPhase, milestones: {commonDefId: string, done: boolean}[]): IptPhase {
    switch (projectPhase) {
      case ProjectPhase.DEVELOPMENT: return this.isPmg2Completed(milestones)
        ? IptPhase.DEVELOPMENT_CLOSING
        : IptPhase.DEVELOPMENT_INITIAL;
      case ProjectPhase.CONSTRUCTION: return IptPhase.CONSTRUCTION;
      case ProjectPhase.OPERATIONS: return IptPhase.OPERATIONS;
    }
  }

  static isPmg2Completed(milestones: {commonDefId: string, done: boolean}[]): boolean {
    return milestones.some(ms => !!ms.commonDefId && ms.commonDefId.endsWith('_pmg2') && ms.done);
  }

  static getManagerRoleIdForPhase(phase: ProjectPhase): string {
    switch (phase) {
      case ProjectPhase.DEVELOPMENT: return NonDeletableRoleId.PMD;
      case ProjectPhase.CONSTRUCTION: return NonDeletableRoleId.PMC;
      case ProjectPhase.OPERATIONS: return NonDeletableRoleId.PMO;
    }
    return null;
  }

  static getDeputyManagerRoleIdForPhase(phase: ProjectPhase): string {
    switch (phase) {
      case ProjectPhase.DEVELOPMENT: return NonDeletableRoleId.DPMD;
      case ProjectPhase.CONSTRUCTION: return NonDeletableRoleId.DPMC;
      case ProjectPhase.OPERATIONS: return NonDeletableRoleId.DPMO;
    }
    return null;
  }

  public static validateMandatoryUser(formControl: AbstractControl): ValidationErrors | null {
    if (formControl.value && formControl.value.fullName) {
      return null;
    }
    return {'invalid_user': formControl.value};
  }

  public static validateUser(formControl: AbstractControl): ValidationErrors | null {
    if (!formControl.value || (formControl.value.id && formControl.value.fullName)) {
      // either control is empty or a user should be selected
      return null;
    }
    return {'invalid_user': formControl.value};
  }

  constructor(private phase: IptPhase) {}

  createEntriesForTeam(
    team: TeamMember[],
    userMap: {[userId: string]: GetUsers},
    roleMap: {[roleId: string]: GetTeamRoles},
  ): TeamMemberEntry[] {
    const entries = [];
    let nextEntryId = 0;
    for (const member of team) {
      const entry = this.createEntryForMember(member, userMap, roleMap, nextEntryId++);
      entries.push(entry);
    }
    this.addMissingRoles(entries, Object.values(roleMap));
    return entries;
  }

  createEntryForMember(
    member: TeamMember,
    userMap: {[userId: string]: GetUsers},
    roleMap: {[roleId: string]: GetTeamRoles},
    nextEntryId: number,
  ): TeamMemberEntry {
    const id: string = '' + nextEntryId;
    const user = userMap[member.userId];
    const role = roleMap[member.roleId];
    return this.createEntry(id, user, role, member.disableEmails);
  }

  createEntry(
    entryId: string,
    user: GetUsers,
    role: GetTeamRoles,
    disableEmails: boolean
  ): TeamMemberEntry {
    const mandatory = this.isMandatory(role);
    return Object.assign(new TeamMemberEntry(), {
      id: entryId,
      fg: new FormGroup({
        user: new FormControl(user, mandatory ? TeamRoleUtil.validateMandatoryUser : TeamRoleUtil.validateUser),
        role: new FormControl(role, Validators.required),
        enableEmails: new FormControl(!disableEmails),
      }),
      core: this.isCore(role),
      mandatory: mandatory,
      alwaysVisible: this.isAlwaysVisible(role),
    });
  }

  isManager(role: GetTeamRoles): boolean {
    return role && role.id === this.getManagerRoleId();
  }

  getManagerRoleId(): string {
    switch (this.phase) {
      case IptPhase.DEVELOPMENT_INITIAL: return NonDeletableRoleId.PMD;
      case IptPhase.DEVELOPMENT_CLOSING: return NonDeletableRoleId.PMD;
      case IptPhase.CONSTRUCTION: return NonDeletableRoleId.PMC;
      case IptPhase.OPERATIONS: return NonDeletableRoleId.PMO;
    }
    return null;
  }

  isDeputyManager(role: GetTeamRoles): boolean {
    return role && role.id === this.getDeputyManagerRoleId();
  }

  getDeputyManagerRoleId(): string {
    switch (this.phase) {
      case IptPhase.DEVELOPMENT_INITIAL: return NonDeletableRoleId.DPMD;
      case IptPhase.DEVELOPMENT_CLOSING: return NonDeletableRoleId.DPMD;
      case IptPhase.CONSTRUCTION: return NonDeletableRoleId.DPMC;
      case IptPhase.OPERATIONS: return NonDeletableRoleId.DPMO;
    }
    return null;
  }

  isRoleDeletable(role: GetTeamRoles): boolean {
    return role && !NonDeletableRoleId[role.id] && !this.isMandatory(role) && !this.isAlwaysVisible(role);
  }

  isMandatory(role: GetTeamRoles): boolean {
    return role?.settings[this.phase]?.mandatory;
  }

  isAlwaysVisible(role: GetTeamRoles): boolean {
    return role?.settings[this.phase]?.alwaysVisible;
  }

  isCore(role: GetTeamRoles): boolean {
    return role?.settings[this.phase]?.core;
  }

  comparatorInCore(): (a: GetTeamRoles, b: GetTeamRoles) => number {
    const phase = this.phase;
    return (a, b) => a.settings[phase].indexCore - b.settings[phase].indexCore;
  }

  comparatorInWider(): (a: GetTeamRoles, b: GetTeamRoles) => number {
    const phase = this.phase;
    return (a, b) => a.settings[phase].indexWider - b.settings[phase].indexWider;
  }

  calculateCoreRoles(allEntries: TeamMemberEntry[]): TeamMemberEntry[] {
    const filtered = allEntries.filter(entry => !entry.duplicated && entry.core);
    return filtered.sort((a, b) => this.sortEntries(a, b,
      role => {
        const idx = role.settings[this.phase].indexCore;
        return !idx && idx !== 0 ? 999999 : idx;
      }));
  }

  calculateWiderRoles(allEntries: TeamMemberEntry[]): TeamMemberEntry[] {
    return allEntries
      .filter(entry => entry.duplicated || !entry.core)
      .sort((a, b) => this.sortEntries(a, b,
        role => {
          const idx = role.settings[this.phase].indexWider;
          return !idx && idx !== 0 ? 999999 : idx;
        }));
  }

  private sortEntries(
    a: TeamMemberEntry,
    b: TeamMemberEntry,
    indexGetter: (role: GetTeamRoles) => number,
  ): number {
    const roleA: GetTeamRoles = a.getRole();
    const roleB: GetTeamRoles = b.getRole();
    if (!roleA) {
      return 1;
    }
    if (!roleB) {
      return -1;
    }
    const indexA = indexGetter(roleA);
    const indexB = indexGetter(roleB);
    const compareIndex = indexA - indexB;
    if (compareIndex !== 0) {
      return compareIndex;
    }
    return roleA.name.localeCompare(roleB.name);
  }

  getMissingRoles(entries: TeamMemberEntry[], allRoles: GetTeamRoles[]): GetTeamRoles[] {
    const usedRoleIds = new Set<string>();
    for (const entry of entries) {
      const role = entry.getRole();
      if (role) {
        usedRoleIds.add(role.id);
      }
    }
    const importantRoles: GetTeamRoles[] = [];
    for (const role of allRoles) {
      if (this.isAlwaysVisible(role) || this.isMandatory(role)) {
        importantRoles.push(role);
      }
    }
    const result: GetTeamRoles[] = [];
    for (const role of importantRoles) {
      if (!usedRoleIds.has(role.id)) {
        result.push(role);
      }
    }
    return result;
  }

  private addMissingRoles(entries: TeamMemberEntry[], allRoles: GetTeamRoles[]): void {
    let nextEntryId = this.getNextEntryId(entries);
    const missingRoles = this.getMissingRoles(entries, allRoles);
    for (const role of missingRoles) {
      const id: string = '' + nextEntryId++;
      const entry = this.createEntry(id, null, role, false);
      entries.push(entry);
    }
  }

  getNextEntryId(entries: TeamMemberEntry[]) {
    return entries.length === 0 ? 0 : Math.max(...entries.map(e => parseFloat(e.id))) + 1;
  }

  checkForDuplicates(entries: TeamMemberEntry[]): void {
    const usedRoleIds = new Map<string, TeamMemberEntry>();
    for (const entry of entries) {
      const role = entry.getRole();
      if (role) {
        if (usedRoleIds.has(role.id)) {
          entry.duplicated = true;
          entry.firstDuplicated = false;
          usedRoleIds.get(role.id).firstDuplicated = true;
        } else {
          entry.duplicated = false;
          entry.firstDuplicated = false;
          usedRoleIds.set(role.id, entry);
        }
      }
    }
  }

}
export class TeamMemberEntry {
  id: string;
  fg: FormGroup;
  core: boolean;
  mandatory: boolean;
  alwaysVisible: boolean;
  firstDuplicated: boolean;
  duplicated: boolean;

  // noinspection JSUnusedGlobalSymbols
  nicify(): string {
    const fgValue = this.fg.value;
    let val;
    if (fgValue.user && fgValue.user.getMemberName && fgValue.role && fgValue.role.name) {
      val = fgValue.user.getMemberName() + '[' + fgValue.role.name + ']';
    } else {
      val = fgValue.user +  + '[' + fgValue.role + '] ';
    }
    if (this.core) {
      val += 'C';
    }
    if (this.mandatory) {
      val += 'M';
    }
    if (this.alwaysVisible) {
      val += 'V';
    }
    if (this.duplicated) {
      val += 'D';
    }
    return val;
  }

  isRoleChangePossible(): boolean {
    return !this.mandatory && !this.alwaysVisible;
  }

  getUser(): GetUsers {
    const userVal = this.fg?.value?.user;
    return userVal?.fullName ? userVal : null;
  }

  getUserData(property: string): string {
    const user = this.getUser();
    return user ? user[property] : null;
  }

  getRole(): GetTeamRoles {
    const roleVal = this.fg?.value?.role;
    return roleVal?.name ? roleVal : null;
  }

  getTooltip(): string {
    return this.getRole()?.tooltip;
  }

  getRoleName(): string {
    return this.getRole()?.name || 'Team Member';
  }

  isEnableEmails(): boolean {
    return !!this.fg?.value?.enableEmails;
  }
}
