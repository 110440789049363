<aside class="row">
  {{ getCreatedDateFormatted() }}
</aside>
<div class="padding-top-one">
  <a
    routerLink="{{
      '/' + routePaths?.PROJECT_DETAILS + '/' + projectId + '/overview'
    }}"
    class="dark-anchor-underlined"
  >
    {{ projectName }}
  </a>
</div>
<h3>
  <span class="row">
    <span
      class="{{ categoryToFilter[newsItem.category].css }}"
      matTooltip="{{ categoryToLabel[newsItem.category] }}"
      [ngSwitch]="newsItem.category"
    >
      <mat-icon *ngSwitchCase="newsCategory.STATUS_UPDATE">update</mat-icon>
      <mat-icon
        *ngSwitchCase="newsCategory.HIGHLIGHT"
        svgIcon="news-category-highlight"
      ></mat-icon>
      <mat-icon *ngSwitchCase="newsCategory.RISK">warning</mat-icon>
      <mat-icon *ngSwitchCase="newsCategory.REPORT_COMMENT">done_all</mat-icon>
      <mat-icon *ngSwitchCase="newsCategory.REPORT_VALIDATION_PMD"
        >done</mat-icon
      >
      <mat-icon *ngSwitchCase="newsCategory.REPORT_VALIDATION_TLD"
        >done</mat-icon
      >
      <mat-icon *ngSwitchCase="newsCategory.REPORT_VALIDATION_PMC"
        >done</mat-icon
      >
      <mat-icon *ngSwitchCase="newsCategory.REPORT_VALIDATION_PPU"
        >done_all</mat-icon
      >
    </span>
    <span class="break-spaces w-100prc max-width-100prc">
      {{ newsItem.title }} by
      {{ author ? author.fullName : newsItem.authorUserId }}
    </span>
  </span>
  <button
    *ngIf="editable && canEdit"
    mat-icon-button
    type="button"
    (click)="onEdit()"
  >
    <mat-icon>edit</mat-icon>
  </button>
</h3>
<pre>{{ newsItem.content }}</pre>
<div>
  <span class="news-item-entry-category-and-tag"
    >Category: {{ categoryToLabel[newsItem.category] }}</span
  >
  <span class="news-item-entry-category-and-tag"
    >Tag: {{ tagToLabel[newsItem.tag] }}</span
  >
</div>
<div *ngIf="loading" class="loading-spinner">
  <mat-progress-spinner
    diameter="25"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
