export enum BusinessModel {
  BUILD_OWN = 'BUILD_OWN',
  BUILD_SELL_PART = 'BUILD_SELL_PART',
  BUILD_SELL_ALL = 'BUILD_SELL_ALL',
  DEVELOP_SELL = 'DEVELOP_SELL',
}
export const businessModelToLabel: {[value: string]: string} = {
  [BusinessModel.BUILD_OWN]: 'Build & Own',
  [BusinessModel.BUILD_SELL_PART]: 'Build & Sell (Part)',
  [BusinessModel.BUILD_SELL_ALL]: 'Build & Sell (All)',
  [BusinessModel.DEVELOP_SELL]: 'Develop & Sell',
};
