import { FncGroup } from './FncGroup';

export enum UserLoginState {
  AUTHENTICATION_NOT_STARTED = 'AUTHENTICATION_NOT_STARTED',
  BEFORE_LOGIN = 'BEFORE_LOGIN',
  FULL_LOGIN_SUCCESS = 'FULL_LOGIN_SUCCESS',
  LOGIN_DIGIRES_ERROR = 'LOGIN_DIGIRES_ERROR',
  LOGIN_MSAL_ERROR = 'LOGIN_MSAL_ERROR',
}
export interface CurrentLoginState {
  state: UserLoginState;
  errorMessage?: string;
  user?: CurrentLoginUser;
}
export interface CurrentLoginUser {
  id: string;
  fullName: string;
  groups: FncGroup[];
  groupsSet: Set<FncGroup>;
}
