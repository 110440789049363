import { ProjectPhase } from '../general/ProjectPhase';
import { ProjectStage } from '../general/ProjectStage';
import { Technology } from '../general/Technology';
import { CountryAlpha2Code } from '../geo/CountryAlpha2Code';
import { GetReferenceResponse } from '../user-guides-table.model';
import { Currency } from './../general/Currency';
import { DiscussedInTeam } from './DiscussedInTeam';
import { LessonLearntBestPractice } from './LessonLearntBestPractice';

export class LessonLearnt {
  id: string;
  itemId: string; // Format: LL-Year-RDSPP-Nr
  countryCodes: CountryAlpha2Code[] = [];
  projectId: string;
  createDate: Date;
  authorUserId: string;
  identifierUserId: string;
  discussedInTeam: DiscussedInTeam;
  status: string;
  technologies: Technology[];
  applicableTo: string;
  lessonsLearnedBestPractice: LessonLearntBestPractice;
  projectPhase: ProjectPhase[] = [];
  projectStage: ProjectStage[] = [];
  categoryIds: string[] = [];
  detailsOfIssue: string;
  mitigationForFutureProjects: string;
  comment: string;
  severity: string;
  delay: number;
  impactOnBudget: number;
  currency: Currency;
  hse: number;
  quality: string;
  links: GetReferenceResponse[]

  lastChange: Date;
  restoreStatus: string;
}
