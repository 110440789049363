import {NewsCategory, newsCategoryToLabel} from '../../model/news/NewsCategory';

export interface NewsFilter {
  id: NewsCategory;
  label: string;
  css: string;
  categories: NewsCategory[];
  checked: boolean;
  color: string;
}
export const AllNewsFilters: NewsFilter[] = [
  {
    id: NewsCategory.STATUS_UPDATE,
    label: newsCategoryToLabel[NewsCategory.STATUS_UPDATE],
    css: 'news-category-update',
    categories: [NewsCategory.STATUS_UPDATE],
    checked: true,
    color: '#4dafe6'
  },
  {
    id: NewsCategory.HIGHLIGHT,
    label: newsCategoryToLabel[NewsCategory.HIGHLIGHT],
    css: 'news-category-highlight',
    categories: [NewsCategory.HIGHLIGHT],
    checked: true,
    color: '#72b692'
  },
  {
    id: NewsCategory.RISK,
    label: newsCategoryToLabel[NewsCategory.RISK],
    css: 'news-category-risk',
    categories: [NewsCategory.RISK],
    checked: true,
    color: '#d33a39'
  },
  {
    id: NewsCategory.REPORT_COMMENT,
    label: 'Report Comments & Validation',
    css: 'news-category-validation',
    categories: [
      NewsCategory.REPORT_COMMENT,
      NewsCategory.REPORT_VALIDATION_PMD,
      NewsCategory.REPORT_VALIDATION_TLD,
      NewsCategory.REPORT_VALIDATION_PMC,
      NewsCategory.REPORT_VALIDATION_PPU,
    ],
    checked: true,
    color: '#53555b'
  },
];
export const newsCategoryToFilter: {[newsCategory: string]: NewsFilter} = {};
for (const filter of AllNewsFilters) {
  for (const category of filter.categories) {
    newsCategoryToFilter[category] = filter;
  }
}
