<form [formGroup]="internalFormGroup">
  <digires-state-indicator [formCtrlName]="fullFormCtrlName" [readOnly]="readOnly" [readOnlyReason]="readOnlyReason" [finModel]="finModel"></digires-state-indicator>
  <mat-form-field [ngClass]="{changed:isDirty()}">
    <label>
      <input *ngIf="readOnly"
             matInput
             type="text"
             readonly
             placeholder="{{placeholder}}"
             [value]="getReadonlyValue()"/>
    </label>
    <mat-select *ngIf="!readOnly"
                [required]="required && !readOnly"
                [multiple]="multiSelect"
                formControlName="multiSelect"
                placeholder="{{placeholder}}"
                disableOptionCentering
                panelClass="mat-dropdown-panel-override"
                (selectionChange)="onItemSelected($event)">
      <mat-option *ngIf="noItemName" [value]="NO_ITEMS">{{noItemName}}</mat-option>
      <mat-option *ngIf="allItemName" [value]="ALL_ITEMS">{{allItemName}}</mat-option>
      <mat-option *ngFor="let item of items" [value]="itemToId(item)" [title]="itemToLabel(item)">
        {{itemToLabel(item)}}
      </mat-option>
    </mat-select>

    <mat-error>
      <div *ngIf="error">{{error}}</div>
      <ng-container *ngFor="let err of errors">
        <div *ngIf="deepFormGroup.controls[formCtrlName].hasError(err.id)">{{err.message}}</div>
      </ng-container>
    </mat-error>
  </mat-form-field>
  <mat-icon *ngIf="!!tooltip" class="tooltip-icon" matTooltipPosition="above" matTooltip="{{tooltip}}" svgIcon="tooltip"></mat-icon>
  <mat-icon *ngIf="!!finModel" class="warning-triangle" [class.transparent]="!finModel.hasUpload || !finModel.isReadable(fullFormCtrlName) || finModel.isRead(fullFormCtrlName)"
            matTooltip="Note: The value in this field was not populated by the most recent upload of the financial model">
    warning
  </mat-icon>
</form>
