<div class="lessons-learnt-container">
  <digires-text-input
    id="itemId"
    [formGroup]="formGroup"
    formCtrlName="itemId"
    placeholder="Item Id"
    tooltip="This is the unique identifier reference for this Lesson Learnt Item/Best Practice Item"
    [readOnly]="true"
    [readOnlyReason]="'The ID will be auto calculated'"
    [error]="errorText"
  ></digires-text-input>

  <digires-text-input
    id="projectName"
    [formGroup]="formGroup"
    formCtrlName="projectName"
    placeholder="Project Name"
    tooltip="The name of the project"
    required
    [readOnly]="true"
    [readOnlyReason]="'The Project Name will be auto selected'"
    [error]="errorText"
  ></digires-text-input>

  <digires-multi-list-input
    id="countryCodes"
    [formGroup]="formGroup"
    [items]="allCountryIds"
    [allItemName]="'All Countries'"
    formCtrlName="countryCodes"
    placeholder="Country / Countries"
    tooltip="This Item will be applicable to projects in this Country/Countries"
    [itemToLabel]="countryToLabel"
    [multiSelect]="true"
    required
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-multi-list-input>

  <digires-date-input
    id="createDate"
    [formGroup]="formGroup"
    formCtrlName="createDate"
    placeholder="Item Creation Date"
    required
    tooltip="The date that the Item was first logged into the Lessons Learnt register"
    [error]="errorText"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-date-input>

  <digires-user-input
    id="authorUserId"
    [formGroup]="formGroup"
    formCtrlName="authorUserId"
    placeholder="Author of Item"
    required
    tooltip="The name of the person who has entered the Item into the Lessons Learnt register"
    [error]="errorText"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-user-input>

  <digires-user-input
    id="identifierUserId"
    [formGroup]="formGroup"
    formCtrlName="identifierUserId"
    placeholder="Identifier of Item"
    required
    tooltip="The name of the person who have identified the Item"
    [error]="errorText"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-user-input>

  <digires-list-input
    id="lessonsLearnedBestPractice"
    [formGroup]="formGroup"
    [items]="allLessonLearntBestPracticeOptions"
    formCtrlName="lessonsLearnedBestPractice"
    placeholder="Lesson Learnt / Best Practice"
    required
    tooltip="Identifies if the Item relates to a Lesson Learnt (i.e. experiences distilled from past activities that should be actively taken into account to inform future actions and behaviours therefore being adopted through Continuous Improvement of our ways of working) or symbolises Best Practice (i.e. optimal performance of the organisation through the application of the most effective ways of working)"
    [itemToLabel]="lessonLearntBestPracticeToLabel"
    [error]="errorText"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-list-input>

  <digires-multi-list-input
    id="projectPhase"
    [formGroup]="formGroup"
    [items]="projectPhases"
    [allItemName]="'All Project Phases'"
    formCtrlName="projectPhase"
    placeholder="Project Phase"
    required
    tooltip="The Project Phase relates to the current phase of the project's life cycle: Development; Construction or Operations."
    [itemToLabel]="phaseToLabel"
    [error]="errorText"
    [multiSelect]="true"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-multi-list-input>

  <digires-multi-list-input
    id="projectStage"
    [formGroup]="formGroup"
    [items]="projectStages"
    [allItemName]="'All Project Stages'"
    formCtrlName="projectStage"
    placeholder="Project Stage"
    required
    tooltip="The Project Stage relates to the current stage of the project's life cycle during the Development Phase: 1. Origination; 2. Early Development; 3. Mid-Development; 4. Late Development; 5. Ready for FID (5a. Post FID (Conditionally); 5b. Post FID (Fully Secured)); N/A in Construction; or N/A in Operations."
    [itemToLabel]="stageToLabel"
    [error]="errorText"
    [multiSelect]="true"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-multi-list-input>

  <digires-multi-dropdown-input
    [configId]="['lessons-learnt-categories-development', 'lessons-learnt-categories-construction']"
    [formGroup]="formGroup"
    [allItemName]="'All Categories'"
    formCtrlName="categories"
    placeholder="Categories"
    required
    tooltip="This identifies the relevant categories that the Item is applicable to."
    [error]="errorText"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-multi-dropdown-input>

  <digires-dropdown-input
    id="status"
    configId="lessons-learnt-status"
    [formGroup]="formGroup"
    formCtrlName="status"
    placeholder="Status"
    tooltip="'Active' shows those that are Active (i.e. Status: Identification, Consideration, Implementation); and 'Inactive' shows those that are Inactive (i.e. Status: Archived.)'"
    required
    [error]="errorText"
  ></digires-dropdown-input>

  <digires-list-input
    id="severity"
    [formGroup]="formGroup"
    [items]="['Low', 'Medium', 'High']"
    formCtrlName="severity"
    placeholder="Severity"
    required
    tooltip="This identifies how severe the impact of the Item could be on the business: Low, Medium or High"
    [error]="errorText"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-list-input>

  <digires-text-input
    id="detailsOfIssue"
    [formGroup]="formGroup"
    formCtrlName="detailsOfIssue"
    placeholder="Details of Issue"
    required
    [textarea]="true"
    tooltip="This identifies the details of the issue that has been identified"
    [error]="errorText"
    [fixedRowCount]="6"
    class="full-width"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-text-input>

  <digires-text-input
    id="mitigationForFutureProjects"
    [formGroup]="formGroup"
    formCtrlName="mitigationForFutureProjects"
    placeholder="Mitigation for future projects"
    required
    [textarea]="true"
    tooltip="This identifies what steps should be taken to ensure negative effects are avoided in the future and that positive effects are harnessed to drive optimal performance"
    [error]="errorText"
    [fixedRowCount]="6"
    class="full-width"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-text-input>

  <digires-text-input
    id="comment"
    [formGroup]="formGroup"
    formCtrlName="comment"
    placeholder="Comment"
    [textarea]="true"
    tooltip="This identifies any further comments that the Author wants to make to the Item"
    [fixedRowCount]="6"
    class="full-width"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-text-input>

  <digires-list-input
    id="discussedInTeam"
    [formGroup]="formGroup"
    [items]="allDiscussedInTeamOptions"
    formCtrlName="discussedInTeam"
    placeholder="Discussed in Integrated Project Team"
    [itemToLabel]="discussedInTeamToLabel"
    tooltip="The relevant members of the Integrated Project Team have been involved in discussions about this matter"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-list-input>

  <digires-multi-list-input
    id="technologies"
    [formGroup]="formGroup"
    [items]="technologies"
    [allItemName]="'All Technologies'"
    formCtrlName="technologies"
    placeholder="Technologies"
    tooltip="The relevant technologies for this Lesson Learnt"
    [itemToLabel]="technologyToLabel"
    [multiSelect]="true"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-multi-list-input>

  <digires-dropdown-input
    id="applicableTo"
    configId="lessons-learnt-applicableTo"
    [formGroup]="formGroup"
    formCtrlName="applicableTo"
    placeholder="Applicable To"
    tooltip="This identifies what scope the Item has in terms of how widely it is applicable"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-dropdown-input>

  <digires-num-input
    id="delay"
    [formGroup]="formGroup"
    formCtrlName="delay"
    placeholder="Delay [days]"
    tooltip="This is an indication of the possible number of days the timescales of a project could be impacted by the Item. Delays to project timescales are shown as a positive number; whereas foreshortening of project timescales are shown as a negative number."
    [error]="'Only whole numbers are allowed'"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-num-input>

  <digires-num-input
    id="impactOnBudget"
    [formGroup]="formGroup"
    formCtrlName="impactOnBudget"
    placeholder="Impact on Budget [million]"
    tooltip="This is an indication of the possible impact on the budget for a project shown in millions of the currency selected"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-num-input>

  <digires-list-input
    id="currency"
    [formGroup]="formGroup"
    [items]="currencies"
    formCtrlName="currency"
    placeholder="Currency"
    [itemToLabel]="currencyToLabel"
    tooltip="The currency of the impact on budget"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-list-input>

  <digires-num-input
    id="hse"
    [formGroup]="formGroup"
    formCtrlName="hse"
    placeholder="HSE [LTIF Caused or Prevented]"
    tooltip="This is an indication of the frequency of 'Lost Time Incidents' in relation to Health & Safety matters that could be caused (shown as a positive number) or prevented (shown as a negative number)"
    [error]="'Only whole numbers are allowed'"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-num-input>

  <digires-text-input
    id="quality"
    [formGroup]="formGroup"
    formCtrlName="quality"
    placeholder="Quality"
    tooltip="Enter any information about the relevance of the matter on the Quality of the project"
    [readOnly]="isReadOnly()"
    [readOnlyReason]="readOnlyReason()"
  ></digires-text-input>

  <digires-button
  class="references"
  (click)="openReferenceDialog()"
  >
  <mat-icon>description</mat-icon>
    Links to Reference
</digires-button>
</div>
