import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DropDownConfig} from '../../model/drop-down/DropDownConfig';
import {DropDownOption} from '../../model/drop-down/DropDownOption';
import {DropDownService} from '../../services/other/drop-down.service';
import {AbstractInputDirective} from '../AbstractInputDirective';

@Component({
  selector: 'digires-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss']
})
export class DropdownInputComponent extends AbstractInputDirective implements OnChanges {

  @Input() configId: string;
  config: DropDownConfig;
  allOptions: DropDownOption[];

  constructor(private dropDownService: DropDownService) {
    super();
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.dropDownService
      .fetchConfig(this.configId)
      .then(config => {
        this.config = config;
        this.allOptions = config?.values || [];
      });
  }

  getReadonlyValue(): string {
    const formValue = this.getValue();
    if (!formValue) {
      return null;
    }
    const selectedOption = this.allOptions?.find(opt => opt.id === formValue);
    return selectedOption?.label || formValue;
  }

}
