import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '../../model/Page';
import { NewsItem } from '../../model/news/NewsItem';
import { Subject, Observable, firstValueFrom } from 'rxjs';
import { NotificationService } from './notification.service';
import { PostNews } from './PostNews';
import { PutNews } from './PutNews';


@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private newsUpdateSubject = new Subject<string[]>();

  constructor(
    private notificationService: NotificationService,
    private client: HttpClient,
  ) { }

  observeNewsUpdates(): Observable<string[]> {
    return this.newsUpdateSubject;
  }

  async loadNewsForProject(projectId: string, offset: number, limit: number): Promise<Page<NewsItem>> {
    const promise = firstValueFrom(this.client.get<Page<NewsItem>>(`/api/v1/projects/${projectId}/news`, {
      params: new HttpParams({
        fromObject: {
          projectId: projectId,
          offset: offset.toString(),
          limit: limit.toString(),
        }
      }),
    }));
    this.notificationService.handlePromiseError('Loading News Items failed...', promise);
    return promise;
  }

  async loadNewsForAllProjects(projectIds: string[], offset: number, limit: number): Promise<Page<NewsItem>> {
    const promise = firstValueFrom(this.client.post<Page<NewsItem>>('/api/news/filter', {
      projectIds: projectIds,
      offset: offset.toString(),
      limit: limit.toString(),
    }));
    this.notificationService.handlePromiseError('Loading News Items failed...', promise);
    return promise;
  }

  async postNewsItem(projectId: string, body: PostNews): Promise<NewsItem> {
    const promise = firstValueFrom(this.client.post<NewsItem>('/api/news', body));
    this.notificationService.handlePromise('News Item created!', 'Creating News Item failed...', promise);
    promise.then(newsItem => {
      this.newsUpdateSubject.next(newsItem.projectIds);
    });
    return promise;
  }

  async putNewsItem(id: string, body: PutNews): Promise<NewsItem> {
    const promise = firstValueFrom(this.client.put<NewsItem>(`/api/news/${id}`, body));
    this.notificationService.handlePromise('News Item updated!', 'Updating News Item failed...', promise);
    promise.then(newsItem => {
      this.newsUpdateSubject.next(newsItem.projectIds);
    });
    return promise;
  }

  async deleteNewsItem(newsItem: NewsItem) {
    const promise = firstValueFrom(this.client.delete<void>(`/api/news/${newsItem.id}`));
    this.notificationService.handlePromise('News Item deleted!', 'Deleting News Item failed...', promise);
    promise.then(() => {
      this.newsUpdateSubject.next(newsItem.projectIds);
    });
    return promise;
  }

  async loadNewsItem(id: string) {
    return firstValueFrom(this.client.get<NewsItem>(`/api/news/${id}`));
  }

}
