import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProjectTypeImageModule } from '../../project-overview-kpi-box/shared/components/project-type-image/project-type-image.module';
import { TypeFilteringButtonComponent } from './type-filtering-button.component';

@NgModule({
  declarations: [TypeFilteringButtonComponent],
  exports: [TypeFilteringButtonComponent],
  imports: [CommonModule, ProjectTypeImageModule, MatTooltipModule],
})
export class TypeFilteringButtonModule {}
