import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable, Subject} from 'rxjs';
import {CountryAlpha2Code} from '../../model/geo/CountryAlpha2Code';
import {ProjectFilter} from '../../model/ProjectFilter';
import {ProjectFilterService} from './project-filter.service';
import {GetProjects} from './GetProjects';
import {BulkLoadService} from '../bulk-load.service';
import {Chunk} from '../Chunk';
import {Cache} from '../Cache';
import {NotificationService} from '../other/notification.service';
import {Country} from '../../model/geo/Country';
import {BY_ALPHA_2} from '../../model/geo/Countries';
import {GlobalRegion} from '../../model/geo/GlobalRegion';
import {DevelopmentCluster} from '../../model/geo/DevelopmentCluster';
import {GetProjectManagers} from '../user/GetProjectManagers';
import { GetProjectCreationData } from './GetProjectCreationData';

@Injectable({
  providedIn: 'root'
})
export class ProjectListService {

  private readonly cache = new Cache<GetProjects>(p => p.projectId);
  private readonly filteredProjects = new BehaviorSubject<GetProjects[]>([]);

  constructor(
    private filterService: ProjectFilterService,
    private bulkLoader: BulkLoadService,
    private notificationService: NotificationService,
    private client: HttpClient,
  ) {}

  updateProjectById(projectId: string, promise: Promise<GetProjects>) {
    return this.cache.putOne(projectId, promise);
  }

  observeFilteredProjects(): Observable<GetProjects[]> {
    return this.filteredProjects.asObservable();
  }

  fetchProjectsByCurrentFilter(): Observable<Chunk<GetProjects>> {
    const observable = this.fetchProjectsByFilter(this.filterService.getProjectFilter());
    observable.subscribe(bulkLoad => {
      this.filteredProjects.next(bulkLoad.loadedElements);
    });
    return observable;
  }

  fetchProjectsByFilter(filter: ProjectFilter): Observable<Chunk<GetProjects>> {
    const subject = new Subject<Chunk<GetProjects>>();
    this.filterService
      .getProjectIdsFor(filter)
      .then(projectIds => {
        let lastChunk: Chunk<GetProjects> = null;
        this.fetchProjectsByIds(projectIds).subscribe({
          next: value => {
            lastChunk = value;
            subject.next(value);
          },
          error: error => subject.error(error),
          complete: () => {
            lastChunk = {
              expectedCount: lastChunk?.expectedCount || 0,
              loadedCount: lastChunk?.loadedCount || 0,
              loadedElements: lastChunk?.loadedElements || [],
              finished: true,
            };
            subject.next(lastChunk);
            subject.complete();
          }
        });
      })
      .catch(error => {
        subject.error(error);
        subject.complete();
      });
    return subject;
  }

  fetchProjectsByIds(projectIds: string[]): Observable<Chunk<GetProjects>> {
    return this.cache.getAllChunked(
      projectIds,
      this.loadAllProjects.bind(this),
    );
  }

  fetchProjectById(projectId: string): Promise<GetProjects> {
    return this.cache.getOne(
      projectId,
      this.loadOneProject.bind(this),
    );
  }

  invalidateProjectListCacheFor(projectIds: string[]) {
    this.cache.invalidateSome(projectIds);
  }

  private loadOneProject(projectId: string): Promise<GetProjects> {
    const promise = firstValueFrom(
      this.client.get<GetProjects[]>('/api/v1/projects', {
        params: new HttpParams({
          fromObject: {
            projectIds: [projectId],
          }
        })
      })
    );
    return promise.then(list => list[0]);
  }

  private loadAllProjects(projectIds: string[]): Observable<Chunk<GetProjects>> {
    return this.bulkLoader.chunkedLoading<GetProjects>('/api/v1/projects', 'projectIds', projectIds);
  }

  getUsedCountryCodes(): Promise<CountryAlpha2Code[]> {
    return firstValueFrom(this.client.get<CountryAlpha2Code[]>('/api/country-codes'));
  }

  getUsedCountries(): Promise<Country[]> {
    return this.getUsedCountryCodes()
      .then(countryCodes => {
        const countries = countryCodes.map(value => BY_ALPHA_2[value]).filter(country => !!country);
        countries.sort((s1, s2) => s1.name.localeCompare(s2.name));
        return countries;
      });
  }

  getUsedGlobalRegions(): Promise<GlobalRegion[]> {
    return this.getUsedCountryCodes()
      .then(countryCodes => {
        const globalRegions = [...new Set(countryCodes.map(value => BY_ALPHA_2[value].globalRegion).filter(country => !!country)).values()];
        globalRegions.sort((s1, s2) => s1.localeCompare(s2));
        return globalRegions;
      });
  }

  getUsedDevelopmentClusters(): Promise<DevelopmentCluster[]> {
    return this.getUsedCountryCodes()
      .then(countryCodes => {
        const clusters = [...new Set(countryCodes.map(value => BY_ALPHA_2[value].developmentCluster).filter(country => !!country)).values()];
        clusters.sort((s1, s2) => s1.localeCompare(s2));
        return clusters;
      });
  }

  getUsedProjectNames(): Promise<{[projectId: string] : string}> {
    return firstValueFrom(this.client.get<{[projectId: string] : string}>('/api/project-names'));
  }

  getProjectCreationData(): Promise<GetProjectCreationData[]> {
    return firstValueFrom(this.client.get<GetProjectCreationData[]>('/api/project-creation-data'));
  }

  getAllManagers(): Promise<GetProjectManagers[]> {
    return firstValueFrom(this.client.get<GetProjectManagers[]>('/api/project-managers'));
  }

  async setValidationLock(projectId: string, value: boolean): Promise<GetProjects> {
    const url = `/api/v1/projects/${projectId}/validation-lock`;

    await firstValueFrom(this.client.put<void>(url, {value}));
    return this.cache.putOne(projectId, this.loadOneProject(projectId));
  }

}
