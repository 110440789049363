export enum ProjectType {
  GRID_NEW = 'GRID_NEW',
  GRID_REPOWERING_ENLARGEMENT = 'GRID_REPOWERING_ENLARGEMENT',
  GRID_REPOWERING_REPLACEMENT = 'GRID_REPOWERING_REPLACEMENT',
  GRID_REPOWERING_SIMILAR = 'GRID_REPOWERING_SIMILAR',
  GRID_REPOWERING_LIFE_EXTENSION = 'GRID_REPOWERING_LIFE_EXTENSION',
  HYDROGEN_NEW = 'HYDROGEN_NEW',
  HYDROGEN_REPOWERING_ENLARGEMENT = 'HYDROGEN_REPOWERING_ENLARGEMENT',
  HYDROGEN_REPOWERING_REPLACEMENT = 'HYDROGEN_REPOWERING_REPLACEMENT',
  HYDROGEN_REPOWERING_SIMILAR = 'HYDROGEN_REPOWERING_SIMILAR',
  HYDROGEN_REPOWERING_LIFE_EXTENSION = 'HYDROGEN_REPOWERING_LIFE_EXTENSION',
  CABLE_POOLING_NEW = 'CABLE_POOLING_NEW',
  CABLE_POOLING_REPOWERING_ENLARGEMENT = 'CABLE_POOLING_REPOWERING_ENLARGEMENT',
  CABLE_POOLING_REPOWERING_REPLACEMENT = 'CABLE_POOLING_REPOWERING_REPLACEMENT',
  CABLE_POOLING_REPOWERING_SIMILAR = 'CABLE_POOLING_REPOWERING_SIMILAR',
  CABLE_POOLING_REPOWERING_LIFE_EXTENSION = 'CABLE_POOLING_REPOWERING_LIFE_EXTENSION',
}
export const projectTypeToLabel: {[value: string]: string} = {
  [ProjectType.GRID_NEW]: 'New Project (Grid Export)',
  [ProjectType.GRID_REPOWERING_ENLARGEMENT]: 'Repowering - Replacement Project with Larger Site Area (Grid Export)',
  [ProjectType.GRID_REPOWERING_REPLACEMENT]: 'Repowering - Replacement Project (Grid Export)',
  [ProjectType.GRID_REPOWERING_SIMILAR]: 'Repowering - Like for Like/Similar Project (Grid Export)',
  [ProjectType.GRID_REPOWERING_LIFE_EXTENSION]: 'Repowering - Life Extension (Grid Export)',

  [ProjectType.HYDROGEN_NEW]: 'New Project (Hydrogen Export)',
  [ProjectType.HYDROGEN_REPOWERING_ENLARGEMENT]: 'Repowering - Replacement Project with Larger Site Area (Hydrogen Export)',
  [ProjectType.HYDROGEN_REPOWERING_REPLACEMENT]: 'Repowering - Replacement Project (Hydrogen Export)',
  [ProjectType.HYDROGEN_REPOWERING_SIMILAR]: 'Repowering - Like for Like/Similar Project (Hydrogen Export)',
  [ProjectType.HYDROGEN_REPOWERING_LIFE_EXTENSION]: 'Repowering - Life Extension (Hydrogen Export)',

  [ProjectType.CABLE_POOLING_NEW]: 'New Project (Cable Pooling)',
  [ProjectType.CABLE_POOLING_REPOWERING_ENLARGEMENT]: 'Repowering - Replacement Project with Larger Site Area (Cable Pooling)',
  [ProjectType.CABLE_POOLING_REPOWERING_REPLACEMENT]: 'Repowering - Replacement Project (Cable Pooling)',
  [ProjectType.CABLE_POOLING_REPOWERING_SIMILAR]: 'Repowering - Like for Like/Similar Project (Cable Pooling)',
  [ProjectType.CABLE_POOLING_REPOWERING_LIFE_EXTENSION]: 'Repowering - Life Extension (Cable Pooling)',
};
