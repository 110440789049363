<h2>{{ title }}</h2>
<div class="row-between">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter for" #input/>
  </mat-form-field>
  <button mat-button type="button" class="btnNeutral" (click)="addNewRow()">
    Add Link
  </button>
</div>

<form [formGroup]="formGroup">

  <table mat-table [dataSource]="dataSource" matSort aria-label="Link Reference Table" class="mat-elevation-z8">

    <ng-container matColumnDef="ref">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">
        Ref
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id">
        <mat-form-field>
          <input required
                 type="text"
                 matInput
                 formControlName="ref"
                 [ngClass]="isFieldChanged(element, 'ref') ? 'has-changes' : ''"/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Title
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id"  class="cell">
        <mat-form-field class="form-input">
          <input
            required
            type="text"
            matInput
            formControlName="title"
            [ngClass]="isFieldChanged(element, 'title') ? 'has-changes' : ''"/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Type
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id" class="cell">
        <ng-container>
          <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select
              formControlName="type"
            >
              <mat-option value="{{ fileReferenceType }}">{{
                fileReferenceType | titlecase
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="link">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Link
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id" class="cell">
        <ng-container>
          <mat-form-field class="form-input">
            <input
              required
              type="text"
              matInput
              formControlName="link"
              [ngClass]="isFieldChanged(element, 'link') ? 'has-changes' : ''"
            />
          </mat-form-field>
          <mat-error *ngIf="hasErrorPattern(element, 'link') && !hasErrorRequired(element, 'link')">
            Link is not correct, it must start with
            https://rwe.sharepoint.com/ or I:\\
          </mat-error>
        </ng-container>

        <a *ngIf="!element.link"
           target="_blank"
           [href]="getSanitizeUrl(element.link)"
           class="link"
        >{{ element.link }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="information">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Information
      </th>
      <td mat-cell *matCellDef="let element" class="cell">
        <mat-icon
          svgIcon="information"
          [matTooltip]="getText(element)"
          matTooltipClass="tooltip"
          class="custom-icon-table"
        ></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Action
      </th>
      <td mat-cell *matCellDef="let element">
          <button
                  mat-icon-button
                  class="button-icon"
                  (click)="openDeleteConfirmationDialog(element)"
          >
            <mat-icon>delete</mat-icon>
          </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell no-data" colspan="6">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>
</form>
<mat-paginator
  [pageSize]="5"
  [pageSizeOptions]="[5,10,20,50]"
  aria-label="Select page of users"
></mat-paginator>
