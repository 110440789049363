import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DragulaModule } from 'ng2-dragula';
import { ProjectOverviewTypeFilteringModule } from '../project-overview/project-overview-type-filtering/project-overview-type-filtering.module';
import { ChevronHorizontalComponent } from './components/chevron-horizontal/chevron-horizontal.component';
import { ChevronVerticalComponent } from './components/chevron-vertical/chevron-vertical.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DeleteConfirmDialogComponent } from './components/delete-confirm-dialog/delete-confirm-dialog.component';
import { FileCardComponent } from './components/file-card/file-card.component';
import { FinancialModelUploadDialogComponent } from './components/financial-model/financial-model-upload-dialog/financial-model-upload-dialog.component';
import { FinancialModelComponent } from './components/financial-model/financial-model.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { InfinityScrollModule } from './components/infinity-scroll/infinity-scroll.module';
import { LessonsLearntDialogContentComponent } from './components/lessons-learnt-overview/lessons-learnt-dialog/lessons-learnt-dialog-content/lessons-learnt-dialog-content.component';
import { LessonsLearntDialogComponent } from './components/lessons-learnt-overview/lessons-learnt-dialog/lessons-learnt-dialog.component';
import { LessonsLearntOverviewComponent } from './components/lessons-learnt-overview/lessons-learnt-overview.component';
import { LocationPickerMapComponent } from './components/location-picker/location-picker-map/location-picker-map.component';
import { LocationPickerComponent } from './components/location-picker/location-picker.component';
import { ConstructionMilestoneEntryComponent } from './components/milestone-list/construction-milestone-entry/construction-milestone-entry.component';
import { GovernanceGroupSelectorComponent } from './components/milestone-list/governance-group-selector/governance-group-selector.component';
import { MilestoneEntryComponent } from './components/milestone-list/milestone-entry/milestone-entry.component';
import { MilestoneCategoryIconComponent } from './components/milestone-list/milestone-legend/milestone-category-icon/milestone-category-icon.component';
import { MilestoneLegendComponent } from './components/milestone-list/milestone-legend/milestone-legend.component';
import { MilestoneListComponent } from './components/milestone-list/milestone-list.component';
import { UserMilestoneEntryComponent } from './components/milestone-list/user-milestone-entry/user-milestone-entry.component';
import { NewsItemDialogComponent } from './components/news-item-dialog/news-item-dialog.component';
import { NewsListEntryComponent } from './components/news-list/news-list-entry/news-list-entry.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { PopoutPrintInstructionDialogComponent } from './components/popout-print-instruction-dialog/popout-print-instruction-dialog.component';
import { ProjectFilterChipsComponent } from './components/project-filter-chips/project-filter-chips.component';
import { ProjectFilterSidebarComponent } from './components/project-filter-sidebar/project-filter-sidebar.component';
import { ProjectFilterTechnologyComponent } from './components/project-filter-sidebar/project-filter-technology/project-filter-technology.component';
import { StageMatrixProgressComponent } from './components/stage-matrix-progress/stage-matrix-progress.component';
import { TaskDetailsComponent } from './components/task-list/task-details/task-details.component';
import { TaskEntryComponent } from './components/task-list/task-entry/task-entry.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { TeamMemberBubbleComponent } from './components/team-member-bubble/team-member-bubble.component';
import { TeamMemberDetailsDialogComponent } from './components/team-member-bubble/team-member-details-dialog/team-member-details-dialog.component';
import { TeamMemberEntryComponent } from './components/team-member-list/team-member-entry/team-member-entry.component';
import { TeamMemberListComponent } from './components/team-member-list/team-member-list.component';
import { TechIconComponent } from './components/tech-icon/tech-icon.component';
import { UnitsSolarComponent } from './components/technical/units-solar/units-solar.component';
import { UnitsStorageComponent } from './components/technical/units-storage/units-storage.component';
import { UnitsWindOnshoreComponent } from './components/technical/units-wind-onshore/units-wind-onshore.component';
import { ConfirmationDialogComponent } from './components/user-guides-table/confirmation-dialog/confirmation-dialog.component';
import { UserGuidesTableComponent } from './components/user-guides-table/user-guides-table.component';
import { WeeklyReportProjectTableComponent } from './components/weekly-report/weekly-report-project-table/weekly-report-project-table.component';
import { WeeklyReportTeamsTableComponent } from './components/weekly-report/weekly-report-teams-table/weekly-report-teams-table.component';
import { IsAdminDirective } from './directive/is-admin.directive';
import { IsItSupportDirective } from './directive/is-it-support.directive';
import { IsProcessAdminDirective } from './directive/is-process-admin.directive';
import { AutocompleteInputComponent } from './input/autocomplete-input/autocomplete-input.component';
import { ContactInputComponent } from './input/contact-input/contact-input.component';
import { CountryInputComponent } from './input/country-input/country-input.component';
import { DateInputComponent } from './input/date-input/date-input.component';
import { DropdownInputComponent } from './input/dropdown-input/dropdown-input.component';
import { GroupMultiInputComponent } from './input/group-multi-input/group-multi-input.component';
import { ListInputComponent } from './input/list-input/list-input.component';
import { MultiDropdownInputComponent } from './input/multi-dropdown-input/multi-dropdown-input.component';
import { MultiListInputComponent } from './input/multi-list-input/multi-list-input.component';
import { NumInputComponent } from './input/num-input/num-input.component';
import { PartnerInputComponent } from './input/partner-input/partner-input.component';
import { PercentInputComponent } from './input/percent-input/percent-input.component';
import { PercentageDirective } from './input/percentage.directive';
import { RoleInputComponent } from './input/role-input/role-input.component';
import { StateIndicatorComponent } from './input/state-indicator/state-indicator.component';
import { TeamMemberInputComponent } from './input/team-member-input/team-member-input.component';
import { TextInputComponent } from './input/text-input/text-input.component';
import { ThousandSeparatorDirective } from './input/thousand-separator.directive';
import { UserInputComponent } from './input/user-input/user-input.component';
import { LastLetterPipe } from './pipes/last-letter.pipe';
import { StandardIptImportDialogComponent } from './components/standard-ipt-import-dialog/standard-ipt-import-dialog.component';
import { FakeInputComponent } from './input/fake-input/fake-input.component';
import { StandardIptImportEntryComponent } from './components/standard-ipt-import-dialog/standard-ipt-import-entry/standard-ipt-import-entry.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { StandardIptCreationComponent } from './components/standard-ipt-creation/standard-ipt-creation.component';
import { DigiResButtonComponent } from './input/digires-button/digi-res-button.component';
import { FileRefDialogComponent } from './components/file-ref-dialog/file-ref-dialog.component';
import { FileRefTableComponent } from './components/file-ref-dialog/file-ref-table/file-ref-table.component';
import { DivestmentTimelineComponent } from './components/divestment-timeline/divestment-timeline.component';
import { DivestmentEndOfProcessComponent } from './components/divestment-timeline/divestment-end-of-process/divestment-end-of-process.component';
import { DivestmentMilestoneComponent } from './components/divestment-timeline/divestment-milestone/divestment-milestone.component';
import { GridConnectionsComponent } from './components/technical/connections-grid/grid-connections.component';
import { MeterEntryListComponent } from './components/technical/connections-grid/meter-entry-list/meter-entry-list.component';
import { TransformerEntryListComponent } from './components/technical/connections-grid/transformer-entry-list/transformer-entry-list.component';
import { BannerComponent } from './components/banner/banner.component';
import { MonthInputComponent } from './input/month-input/month-input.component';

@NgModule({
  declarations: [
    GoogleMapComponent,
    ProjectFilterSidebarComponent,
    ProjectFilterChipsComponent,
    PercentageDirective,
    ThousandSeparatorDirective,
    TextInputComponent,
    ListInputComponent,
    TeamMemberBubbleComponent,
    TeamMemberDetailsDialogComponent,
    ChevronHorizontalComponent,
    StageMatrixProgressComponent,
    ChevronVerticalComponent,
    UserInputComponent,
    RoleInputComponent,
    DateInputComponent,
    NumInputComponent,
    MultiListInputComponent,
    DropdownInputComponent,
    PartnerInputComponent,
    ContactInputComponent,
    CountryInputComponent,
    LocationPickerComponent,
    LocationPickerMapComponent,
    MilestoneListComponent,
    MilestoneLegendComponent,
    GovernanceGroupSelectorComponent,
    MilestoneEntryComponent,
    UserMilestoneEntryComponent,
    TeamMemberListComponent,
    TeamMemberEntryComponent,
    UnitsWindOnshoreComponent,
    UnitsSolarComponent,
    UnitsStorageComponent,
    NewsItemDialogComponent,
    ConfirmDialogComponent,
    PercentInputComponent,
    FileCardComponent,
    AutocompleteInputComponent,
    MilestoneCategoryIconComponent,
    PopoutPrintInstructionDialogComponent,
    IsAdminDirective,
    IsProcessAdminDirective,
    IsItSupportDirective,
    NewsListEntryComponent,
    NewsListComponent,
    TaskListComponent,
    TaskDetailsComponent,
    TaskEntryComponent,
    LastLetterPipe,
    DeleteConfirmDialogComponent,
    FinancialModelComponent,
    FinancialModelUploadDialogComponent,
    WeeklyReportProjectTableComponent,
    WeeklyReportTeamsTableComponent,
    TeamMemberInputComponent,
    StateIndicatorComponent,
    ConstructionMilestoneEntryComponent,
    TechIconComponent,
    LessonsLearntOverviewComponent,
    LessonsLearntDialogComponent,
    ProjectFilterTechnologyComponent,
    LessonsLearntDialogContentComponent,
    GroupMultiInputComponent,
    UserGuidesTableComponent,
    ConfirmationDialogComponent,
    MultiDropdownInputComponent,
    StandardIptCreationComponent,
    FakeInputComponent,
    StandardIptImportDialogComponent,
    StandardIptImportEntryComponent,
    DigiResButtonComponent,
    FileRefDialogComponent,
    FileRefTableComponent,
    DivestmentTimelineComponent,
    DivestmentEndOfProcessComponent,
    DivestmentMilestoneComponent,
    GridConnectionsComponent,
    MeterEntryListComponent,
    TransformerEntryListComponent,
    BannerComponent,
    MonthInputComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    GoogleMapsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    MatCardModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    InfinityScrollModule,
    DragulaModule,
    MatProgressBarModule,
    ProjectOverviewTypeFilteringModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    AgGridModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports: [
    GoogleMapComponent,
    ProjectFilterSidebarComponent,
    ProjectFilterChipsComponent,
    PercentageDirective,
    TeamMemberBubbleComponent,
    ThousandSeparatorDirective,
    ChevronHorizontalComponent,
    StageMatrixProgressComponent,
    ChevronVerticalComponent,
    UserInputComponent,
    RoleInputComponent,
    TextInputComponent,
    DateInputComponent,
    ListInputComponent,
    MultiListInputComponent,
    NumInputComponent,
    PartnerInputComponent,
    DropdownInputComponent,
    ContactInputComponent,
    AutocompleteInputComponent,
    CountryInputComponent,
    MilestoneListComponent,
    TeamMemberListComponent,
    UnitsWindOnshoreComponent,
    UnitsSolarComponent,
    UnitsStorageComponent,
    PercentInputComponent,
    FileCardComponent,
    IsAdminDirective,
    IsProcessAdminDirective,
    IsItSupportDirective,
    NewsListEntryComponent,
    NewsListComponent,
    TaskListComponent,
    FinancialModelComponent,
    WeeklyReportProjectTableComponent,
    WeeklyReportTeamsTableComponent,
    TeamMemberInputComponent,
    LastLetterPipe,
    TechIconComponent,
    MilestoneEntryComponent,
    UserGuidesTableComponent,
    ConfirmationDialogComponent,
    LessonsLearntOverviewComponent,
    StandardIptCreationComponent,
    FakeInputComponent,
    TeamMemberEntryComponent,
    StandardIptImportDialogComponent,
    DigiResButtonComponent,
    FileRefDialogComponent,
    DivestmentTimelineComponent,
    GridConnectionsComponent,
    BannerComponent,
    MonthInputComponent,

  ],
})
export class SharedModule {}
