<h2>{{ title }}</h2>
<hr/>

<div class="row-between">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Title" #input/>
  </mat-form-field>
  <button *ngIf="isItSupport" mat-raised-button class="add-button" (click)="addNewRow()">
    {{ addButtonTitle }}
  </button>
</div>

<form [formGroup]="formGroup">

  <table mat-table [dataSource]="dataSource" matSort aria-label="User Guides" class="mat-elevation-z8">

    <ng-container matColumnDef="ref">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">
        Ref
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id">
        <mat-form-field *ngIf="element.action === 'edit' || element.action === 'add'">
          <input required
                 type="text"
                 matInput
                 formControlName="ref"
                 [ngClass]="isFieldChanged(element, 'ref') ? 'has-changes' : ''"/>
        </mat-form-field>

        <ng-container *ngIf="element.action !== 'edit' && element.action !== 'add'">
          {{element.ref}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Title
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id"  class="cell">
        <mat-form-field *ngIf="element.action === 'edit' || element.action === 'add'"
                        class="form-input">
          <input
            required
            type="text"
            matInput
            formControlName="title"
            [ngClass]="isFieldChanged(element, 'title') ? 'has-changes' : ''"/>
        </mat-form-field>

        <ng-container  *ngIf="element.action !== 'edit' && element.action !== 'add'">
          {{element.title}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Type
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id" class="cell">
        <ng-container *ngIf="element.action === 'edit' || element.action === 'add'">
          <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select
              formControlName="type"
            >
              <mat-option value="{{ fileReferenceType }}">{{
                fileReferenceType | titlecase
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="element.action !== 'edit' && element.action !== 'add'">
          {{element.type}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="link">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Link
      </th>
      <td mat-cell *matCellDef="let element" [formGroupName]="element.id" class="cell">
        <ng-container *ngIf="element.action === 'edit' || element.action === 'add'">
          <mat-form-field class="form-input">
            <input
              required
              type="text"
              matInput
              formControlName="link"
              [ngClass]="isFieldChanged(element, 'link') ? 'has-changes' : ''"
            />
          </mat-form-field>
          <mat-error *ngIf="hasErrorPattern(element, 'link') && !hasErrorRequired(element, 'link')">
            Link is not correct, it must start with
            https://rwe.sharepoint.com/ or I:\\
          </mat-error>
          <mat-error *ngIf="hasErrorRequired(element, 'link')">
            Link is is required.
          </mat-error>
        </ng-container>

        <a *ngIf="element.action !== 'edit' && element.action !== 'add'"
           target="_blank"
           [href]="getSantizeUrl(element.link)"
           class="link"
        >{{ element.link }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="information">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Information
      </th>
      <td mat-cell *matCellDef="let element" class="cell">
        <mat-icon
          svgIcon="information"
          [matTooltip]="getText(element)"
          matTooltipClass="tooltip"
          class="custom-icon-table"
        ></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="action" *ngIf="isItSupport">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-column">

        Action
      </th>
      <td mat-cell *matCellDef="let element">
          <button *ngIf="element.action !== 'edit' && element.action !== 'add'"
                  mat-icon-button
                  class="button-icon"
                  (click)="element.action = 'edit'"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <div *ngIf="element.action === 'edit' || element.action === 'add'">
            <button
              mat-icon-button
              class="button-icon"
              (click)="saveData(element)"
              [disabled]="!isValid(element) || !isChanged(element)"
            >
              <mat-icon>save</mat-icon>
            </button>
            <button
              mat-icon-button
              class="button-icon"
              (click)="discard(element)"
              [disabled]="!isValid(element) && element.action === 'edit'"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <button *ngIf="element.action !== 'add'"
                  mat-icon-button
                  class="button-icon"
                  (click)="openDeleteConfirmationDialog(element)"
          >
            <mat-icon>delete</mat-icon>
          </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell no-data" colspan="6">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>
</form>
<mat-paginator
  [pageSize]="5"
  [pageSizeOptions]="[5,10,20,50]"
  aria-label="Select page of users"
></mat-paginator>
