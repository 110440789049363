import {Component, Input, OnChanges} from '@angular/core';
import {FinancialModelReadManager} from '../../../project-details/FinancialModelReadManager';

@Component({
  selector: 'digires-state-indicator',
  templateUrl: './state-indicator.component.html',
  styleUrls: ['./state-indicator.component.scss']
})
export class StateIndicatorComponent {

  @Input() formCtrlName: string = undefined;
  @Input() readOnly: boolean = false;
  @Input() readOnlyReason: string = undefined;
  @Input() finModel: FinancialModelReadManager;

}
