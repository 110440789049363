import {ConstructionCluster} from './ConstructionCluster';
import {Continent} from './Continent';
import {Country} from './Country';
import {CountryAlpha2Code} from './CountryAlpha2Code';
import {DevelopmentCluster} from './DevelopmentCluster';
import {GlobalRegion} from './GlobalRegion';
import {OpCo} from './OpCo';

export const ALL_COUNTRIES: Country[] = [
  {
    name: 'Afghanistan',
    alpha2Code: CountryAlpha2Code.AF,
    continent: Continent.ASIA,
    globalRegion: GlobalRegion.APAC,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '33',
    longitude: '65',
    regions: ['Badakhshan', 'Badghis', 'Baghlan', 'Balkh', 'Bamian', 'Farah', 'Faryab', 'Ghazni', 'Ghowr', 'Helmand', 'Herat', 'Jowzjan', 'Kabul', 'Kandahar', 'Kapisa', 'Khowst', 'Konar', 'Kondoz', 'Laghman', 'Lowgar', 'Nangrahar', 'Nimruz', 'Nurestan', 'Oruzgan', 'Paktia', 'Paktika', 'Parwan', 'Samangan', 'Sar-e Pol', 'Takhar', 'Wardak', 'Zabol']
  },
  {
    name: 'Åland Islands',
    alpha2Code: CountryAlpha2Code.AX,
    continent: Continent.EUROPE,
    globalRegion: GlobalRegion.EU,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '60.116667',
    longitude: '19.9',
    regions: ['Åland Islands']
  },
  {
    name: 'Albania',
    alpha2Code: CountryAlpha2Code.AL,
    continent: Continent.EUROPE,
    globalRegion: GlobalRegion.EU,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '41',
    longitude: '20',
    regions: ['Berat', 'Bulqizë', 'Delvinë', 'Devoll', 'Dibër', 'Durrsës', 'Elbasan', 'Fier', 'Gramsh', 'Gjirokastër',
      'Has', 'Kavajë', 'Kolonjë', 'Korcë', 'Krujë', 'Kuçovë', 'Kukës', 'Kurbin', 'Lezhë', 'Librazhd', 'Lushnjë',
      'Malësi e Madhe', 'Mallakastër', 'Mat', 'Mirditë', 'Peqin', 'Përmet', 'Pogradec', 'Pukë', 'Sarandë', 'Skrapar',
      'Shkodër', 'Tepelenë', 'Tiranë', 'Tropojë', 'Vlorë']
  },
  {
    name: 'Algeria',
    alpha2Code: CountryAlpha2Code.DZ,
    continent: Continent.AFRICA,
    globalRegion: GlobalRegion.NONE,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.NONE,
    latitude: '28',
    longitude: '3',
    regions: ['Adrar', 'Ain Defla', 'Ain Tmouchent', 'Alger', 'Annaba', 'Batna', 'Bechar', 'Bejaia', 'Biskra', 'Blida', 'Bordj Bou Arreridj', 'Bouira', 'Boumerdes', 'Chlef', 'Constantine', 'Djelfa', 'El Bayadh', 'El Oued', 'El Tarf', 'Ghardaia', 'Guelma', 'Illizi', 'Jijel', 'Khenchela', 'Laghouat', 'Mascara', 'Medea', 'Mila', 'Mostaganem', 'Msila', 'Naama', 'Oran', 'Ouargla', 'Oum el Bouaghi', 'Relizane', 'Saida', 'Setif', 'Sidi Bel Abbes', 'Skikda', 'Souk Ahras', 'Tamanghasset', 'Tebessa', 'Tiaret', 'Tindouf', 'Tipaza', 'Tissemsilt', 'Tizi Ouzou', 'Tlemcen']
  },
  {
    name: 'American Samoa',
    alpha2Code: CountryAlpha2Code.AS,
    continent: Continent.AUSTRALIA,
    globalRegion: GlobalRegion.APAC,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '-14.33333333',
    longitude: '-170',
    regions: ['American Samoa']
  },
  {
    name: 'Andorra',
    alpha2Code: CountryAlpha2Code.AD,
    continent: Continent.EUROPE,
    globalRegion: GlobalRegion.EU,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '42.5',
    longitude: '1.5',
    regions: ['Andorra']
  },
  {
    name: 'Angola',
    alpha2Code: CountryAlpha2Code.AO,
    continent: Continent.AFRICA,
    globalRegion: GlobalRegion.NONE,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.NONE,
    latitude: '-12.5',
    longitude: '18.5',
    regions: ['Bengo', 'Benguela', 'Bie', 'Cabinda', 'Cuando-Cubango', 'Cuanza Norte', 'Cuanza Sul', 'Cunene', 'Huambo', 'Huila', 'Luanda', 'Lunda Norte', 'Lunda Sul', 'Malange', 'Moxico', 'Namibe', 'Uige', 'Zaire']
  },
  {
    name: 'Anguilla',
    alpha2Code: CountryAlpha2Code.AI,
    continent: Continent.NORTH_AMERICA,
    globalRegion: GlobalRegion.LATAM,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPAM,
    latitude: '18.25',
    longitude: '-63.16666666',
    regions: ['Anguilla']
  },
  {
    name: 'Antarctica',
    alpha2Code: CountryAlpha2Code.AQ,
    continent: Continent.ANTARCTICA,
    globalRegion: GlobalRegion.NONE,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.NONE,
    latitude: '-74.65',
    longitude: '4.48',
    regions: ['Australian Antarctic Territory']
  },
  {
    name: 'Antigua and Barbuda',
    alpha2Code: CountryAlpha2Code.AG,
    continent: Continent.NORTH_AMERICA,
    globalRegion: GlobalRegion.LATAM,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPAM,
    latitude: '17.05',
    longitude: '-61.8',
    regions: ['Antigua and Barbuda']
  },
  {
    name: 'Argentina',
    alpha2Code: CountryAlpha2Code.AR,
    continent: Continent.SOUTH_AMERICA,
    globalRegion: GlobalRegion.LATAM,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPAM,
    latitude: '-34',
    longitude: '-64',
    regions: ['Capital federal', 'Buenos Aires', 'Catamarca', 'Cordoba', 'Corrientes', 'Chaco', 'Chubut', 'Entre Rios', 'Formosa', 'Jujuy', 'La Pampa', 'Mendoza', 'Misiones', 'Neuquen', 'Rio Negro', 'Salta', 'San Juan', 'San Luis', 'Santa Cruz', 'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego', 'Tucuman']
  },
  {
    name: 'Armenia',
    alpha2Code: CountryAlpha2Code.AM,
    continent: Continent.ASIA,
    globalRegion: GlobalRegion.APAC,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '40',
    longitude: '45',
    regions: ['Erevan', 'Aragacotn', 'Ararat', 'Armavir', 'Gegarkunik\'', 'Kotayk\'', 'Lory', 'Sirak', 'Syunik\'', 'Tavus', 'Vayoc Jor']
  },
  {
    name: 'Aruba',
    alpha2Code: CountryAlpha2Code.AW,
    continent: Continent.NORTH_AMERICA,
    globalRegion: GlobalRegion.LATAM,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPAM,
    latitude: '12.5',
    longitude: '-69.96666666',
    regions: ['Aruba']
  },
  {
    name: 'Australia',
    alpha2Code: CountryAlpha2Code.AU,
    continent: Continent.AUSTRALIA,
    globalRegion: GlobalRegion.APAC,
    developmentCluster: DevelopmentCluster.AUSTRALIA,
    constructionCluster: ConstructionCluster.AUSTRALIA,
    opCo: OpCo.OPEA,
    latitude: '-27',
    longitude: '133',
    regions: ['Australian Capital Territory', 'Northern Territory', 'New South Wales', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia']
  },
  {
    name: 'Austria',
    alpha2Code: CountryAlpha2Code.AT,
    continent: Continent.EUROPE,
    globalRegion: GlobalRegion.EU,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '47.33333333',
    longitude: '13.33333333',
    regions: ['Burgenland', 'Kärnten', 'Niederosterreich', 'Oberosterreich', 'Salzburg', 'Steiermark', 'Tirol', 'Vorarlberg', 'Wien']
  },
  {
    name: 'Azerbaijan',
    alpha2Code: CountryAlpha2Code.AZ,
    continent: Continent.ASIA,
    globalRegion: GlobalRegion.APAC,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '40.5',
    longitude: '47.5',
    regions: ['Naxcivan', 'Ali Bayramli', 'Baki', 'Ganca', 'Lankaran', 'Mingacevir', 'Naftalan', 'Saki', 'Sumqayit', 'Susa', 'Xankandi', 'Yevlax', 'Abseron', 'Agcabadi', 'Agdam', 'Agdas', 'Agstafa', 'Agsu', 'Astara', 'Babak', 'Balakan', 'Barda', 'Beylagan', 'Bilasuvar', 'Cabrayll', 'Calilabad', 'Culfa', 'Daskasan', 'Davaci', 'Fuzuli', 'Gadabay', 'Goranboy', 'Goycay', 'Haciqabul', 'Imisli', 'Ismayilli', 'Kalbacar', 'Kurdamir', 'Lacin', 'Lerik', 'Masalli', 'Neftcala', 'Oguz', 'Ordubad', 'Qabala', 'Qax', 'Qazax', 'Qobustan', 'Quba', 'Qubadli', 'Qusar', 'Saatli', 'Sabirabad', 'Sadarak', 'Sahbuz', 'Salyan', 'Samaxi', 'Samkir', 'Samux', 'Sarur', 'Siyazan', 'Tartar', 'Tovuz', 'Ucar', 'Xacmaz', 'Xanlar', 'Xizi', 'Xocali', 'Xocavand', 'Yardimli', 'Zangilan', 'Zaqatala', 'Zardab']
  },
  {
    name: 'Bahamas',
    alpha2Code: CountryAlpha2Code.BS,
    continent: Continent.NORTH_AMERICA,
    globalRegion: GlobalRegion.LATAM,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPAM,
    latitude: '24.25',
    longitude: '-76',
    regions: ['Acklins and Crooked Islands', 'Bimini', 'Cat Island', 'Exuma', 'Freeport', 'Fresh Creek', 'Governor\'s Harbour', 'Green Turtle Cay', 'Harbour Island', 'High Rock', 'Inagua', 'Kemps Bay', 'Long Island', 'Marsh Harbour', 'Mayaguana', 'New Providence', 'Nicholls Town and Berry Islands', 'Ragged Island', 'Rock Sound', 'Sandy Point', 'San Salvador and Rum Cay']
  },
  {
    name: 'Bahrain',
    alpha2Code: CountryAlpha2Code.BH,
    continent: Continent.ASIA,
    globalRegion: GlobalRegion.APAC,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '26',
    longitude: '50.55',
    regions: ['Al Hadd', 'Al Manamah', 'Al Mintaqah al Gharbiyah', 'Al Mintagah al Wusta', 'Al Mintaqah ash Shamaliyah', 'Al Muharraq', 'Ar Rifa', 'Jidd Hafs', 'Madluat Jamad', 'Madluat Isa', 'Mintaqat Juzur tawar', 'Sitrah', 'Al Manāmah (Al ‘Āşimah)', 'Al Janūbīyah', 'Al Wusţá', 'Ash Shamālīyah']
  },
  {
    name: 'Bangladesh',
    alpha2Code: CountryAlpha2Code.BD,
    continent: Continent.ASIA,
    globalRegion: GlobalRegion.APAC,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '24',
    longitude: '90',
    regions: ['Bagerhat zila', 'Bandarban zila', 'Barguna zila', 'Barisal zila', 'Bhola zila', 'Bogra zila', 'Brahmanbaria zila', 'Chandpur zila', 'Chittagong zila', 'Chuadanga zila', 'Comilla zila', 'Cox\'s Bazar zila', 'Dhaka zila', 'Dinajpur zila', 'Faridpur zila', 'Feni zila', 'Gaibandha zila', 'Gazipur zila', 'Gopalganj zila', 'Habiganj zila', 'Jaipurhat zila', 'Jamalpur zila', 'Jessore zila', 'Jhalakati zila', 'Jhenaidah zila', 'Khagrachari zila', 'Khulna zila', 'Kishorganj zila', 'Kurigram zila', 'Kushtia zila', 'Lakshmipur zila', 'Lalmonirhat zila', 'Madaripur zila', 'Magura zila', 'Manikganj zila', 'Meherpur zila', 'Moulvibazar zila', 'Munshiganj zila', 'Mymensingh zila', 'Naogaon zila', 'Narail zila', 'Narayanganj zila', 'Narsingdi zila', 'Natore zila', 'Nawabganj zila', 'Netrakona zila', 'Nilphamari zila', 'Noakhali zila', 'Pabna zila', 'Panchagarh zila', 'Patuakhali zila', 'Pirojpur zila', 'Rajbari zila', 'Rajshahi zila', 'Rangamati zila', 'Rangpur zila', 'Satkhira zila', 'Shariatpur zila', 'Sherpur zila', 'Sirajganj zila', 'Sunamganj zila', 'Sylhet zila', 'Tangail zila', 'Thakurgaon zila']
  },
  {
    name: 'Barbados',
    alpha2Code: CountryAlpha2Code.BB,
    continent: Continent.NORTH_AMERICA,
    globalRegion: GlobalRegion.LATAM,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPAM,
    latitude: '13.16666666',
    longitude: '-59.53333333',
    regions: ['Barbados']
  },
  {
    name: 'Belarus',
    alpha2Code: CountryAlpha2Code.BY,
    continent: Continent.EUROPE,
    globalRegion: GlobalRegion.EU,
    developmentCluster: DevelopmentCluster.NONE,
    constructionCluster: ConstructionCluster.NONE,
    opCo: OpCo.OPEA,
    latitude: '53',
    longitude: '28',
    regions: ['Brèsckaja voblasc\'', 'Homel\'skaja voblasc', 'Hrodzenskaja voblasc\'', 'Mahilëuskaja voblasc\'', 'Minskaja voblasc\'', 'Vicebskaja voblasc\'']
},
{
  name: 'Belgium',
    alpha2Code: CountryAlpha2Code.BE,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '50.83333333',
  longitude: '4',
  regions: ['Antwerpen', 'Brabant Wallon', 'Hainaut', 'Liege', 'Limburg', 'Luxembourg', 'Namur', 'Oost-Vlaanderen', 'Vlaams-Brabant', 'West-Vlaanderen', 'Brussels']
},
{
  name: 'Belize',
    alpha2Code: CountryAlpha2Code.BZ,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '17.25',
  longitude: '-88.75',
  regions: ['Belize', 'Cayo', 'Corozal', 'Orange Walk', 'Stann Creek', 'Toledo']
},
{
  name: 'Benin',
    alpha2Code: CountryAlpha2Code.BJ,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '9.5',
  longitude: '2.25',
  regions: ['Alibori', 'Atakora', 'Atlantique', 'Borgou', 'Collines', 'Donga', 'Kouffo', 'Littoral', 'Mono', 'Oueme', 'Plateau', 'Zou']
},
{
  name: 'Bermuda',
    alpha2Code: CountryAlpha2Code.BM,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '32.33333333',
  longitude: '-64.75',
  regions: ['Bermuda']
},
{
  name: 'Bhutan',
    alpha2Code: CountryAlpha2Code.BT,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '27.5',
  longitude: '90.5',
  regions: ['Bumthang', 'Chhukha', 'Dagana', 'Gasa', 'Ha', 'Lhuentse', 'Monggar', 'Paro', 'Pemagatshel', 'Punakha', 'Samdrup Jongkha', 'Samtee', 'Sarpang', 'Thimphu', 'Trashigang', 'Trashi Yangtse', 'Trongsa', 'Tsirang', 'Wangdue Phodrang', 'Zhemgang']
},
{
  name: 'Bolivia (Plurinational State of)',
    alpha2Code: CountryAlpha2Code.BO,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-17',
  longitude: '-65',
  regions: ['Cochabamba', 'Chuquisaca', 'El Beni', 'La Paz', 'Oruro', 'Pando', 'Potosi', 'Tarija']
},
{
  name: 'Bonaire, Sint Eustatius and Saba',
    alpha2Code: CountryAlpha2Code.BQ,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '12.15',
  longitude: '-68.266667',
  regions: ['Bonaire, Sint Eustatius and Saba']
},
{
  name: 'Bosnia and Herzegovina',
    alpha2Code: CountryAlpha2Code.BA,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '44',
  longitude: '18',
  regions: ['Federacija Bosna i Hercegovina', 'Republika Srpska']
},
{
  name: 'Botswana',
    alpha2Code: CountryAlpha2Code.BW,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-22',
  longitude: '24',
  regions: ['Central', 'Ghanzi', 'Kgalagadi', 'Kgatleng', 'Kweneng', 'Ngamiland', 'North-East', 'North-West', 'South-East', 'Southern']
},
{
  name: 'Bouvet Island',
    alpha2Code: CountryAlpha2Code.BV,
  continent: Continent.ANTARCTICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-54.43333333',
  longitude: '3.4',
  regions: ['Bouvet Island']
},
{
  name: 'Brazil',
    alpha2Code: CountryAlpha2Code.BR,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-10',
  longitude: '-55',
  regions: ['Acre', 'Alagoas', 'Amazonas', 'Amapa', 'Baia', 'Ceara', 'Distrito Federal', 'Espirito Santo', 'Fernando de Noronha', 'Goias', 'Maranhao', 'Minas Gerais', 'Mato Grosso do Sul', 'Mato Grosso', 'Para', 'Paraiba', 'Pernambuco', 'Piaui', 'Parana', 'Rio de Janeiro', 'Rio Grande do Norte', 'Rondonia', 'Roraima', 'Rio Grande do Sul', 'Santa Catarina', 'Sergipe', 'Sao Paulo', 'Tocatins']
},
{
  name: 'British Indian Ocean Territory',
    alpha2Code: CountryAlpha2Code.IO,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-6',
  longitude: '71.5',
  regions: ['British Indian Ocean Territory']
},
{
  name: 'United States Minor Outlying Islands',
    alpha2Code: CountryAlpha2Code.UM,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: 'undefined',
  longitude: 'undefined',
  regions: ['Baker Island', 'Howland Island', 'Jarvis Island', 'Johnston Atoll', 'Kingman Reef', 'Midway Islands', 'Navassa Island', 'Palmyra Atoll', 'Wake Ialand']
},
{
  name: 'Virgin Islands (British)',
    alpha2Code: CountryAlpha2Code.VG,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '18.431383',
  longitude: '-64.62305',
  regions: ['Virgin Islands (British)']
},
{
  name: 'Virgin Islands (U.S.)',
    alpha2Code: CountryAlpha2Code.VI,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '18.34',
  longitude: '-64.93',
  regions: ['Virgin Islands (U.S.)']
},
{
  name: 'Brunei Darussalam',
    alpha2Code: CountryAlpha2Code.BN,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '4.5',
  longitude: '114.66666666',
  regions: ['Belait', 'Brunei-Muara', 'Temburong', 'Tutong']
},
{
  name: 'Bulgaria',
    alpha2Code: CountryAlpha2Code.BG,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '43',
  longitude: '25',
  regions: ['Blagoevgrad', 'Burgas', 'Dobric', 'Gabrovo', 'Haskovo', 'Jambol', 'Kardzali', 'Kjstendil', 'Lovec', 'Montana', 'Pazardzik', 'Pernik', 'Pleven', 'Plovdiv', 'Razgrad', 'Ruse', 'Silistra', 'Sliven', 'Smoljan', 'Sofia', 'Stara Zagora', 'Sumen', 'Targoviste', 'Varna', 'Veliko Tarnovo', 'Vidin', 'Vraca']
},
{
  name: 'Burkina Faso',
    alpha2Code: CountryAlpha2Code.BF,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '13',
  longitude: '-2',
  regions: ['Bale', 'Bam', 'Banwa', 'Bazega', 'Bougouriba', 'Boulgou', 'Boulkiemde', 'Comoe', 'Ganzourgou', 'Gnagna', 'Gourma', 'Houet', 'Ioba', 'Kadiogo', 'Kenedougou', 'Komondjari', 'Kompienga', 'Kossi', 'Koulpulogo', 'Kouritenga',
  'Kourweogo', 'Leraba', 'Loroum', 'Mouhoun', 'Nahouri', 'Namentenga', 'Nayala', 'Noumbiel', 'Oubritenga', 'Oudalan', 'Passore', 'Poni', 'Sanguie', 'Sanmatenga', 'Seno', 'Siasili', 'Soum', 'Sourou', 'Tapoa', 'Tui', 'Yagha', 'Yatenga', 'Ziro', 'Zondoma', 'Zoundweogo']
},
{
  name: 'Burundi',
    alpha2Code: CountryAlpha2Code.BI,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-3.5',
  longitude: '30',
  regions: ['Bubanza', 'Bujumbura', 'Bururi', 'Cankuzo', 'Cibitoke', 'Gitega', 'Karuzi', 'Kayanza', 'Makamba', 'Muramvya', 'Mwaro', 'Ngozi', 'Rutana', 'Ruyigi']
},
{
  name: 'Cambodia',
    alpha2Code: CountryAlpha2Code.KH,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '13',
  longitude: '105',
  regions: ['Krong Kaeb', 'Krong Pailin', 'Xrong Preah Sihanouk', 'Phnom Penh', 'Baat Dambang', 'Banteay Mean Chey', 'Rampong Chaam', 'Kampong Chhnang', 'Kampong Spueu', 'Kampong Thum', 'Kampot', 'Kandaal', 'Kach Kong', 'Krachoh', 'Mondol Kiri', 'Otdar Mean Chey', 'Pousaat', 'Preah Vihear', 'Prey Veaeng', 'Rotanak Kiri', 'Siem Reab', 'Stueng Traeng', 'Svaay Rieng', 'Taakaev']
},
{
  name: 'Cameroon',
    alpha2Code: CountryAlpha2Code.CM,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '6',
  longitude: '12',
  regions: ['Adamaoua', 'Centre', 'East', 'Far North', 'North', 'South', 'South-West', 'West']
},
{
  name: 'Canada',
    alpha2Code: CountryAlpha2Code.CA,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.NA,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '60',
  longitude: '-95',
  regions: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory']
},
{
  name: 'Cabo Verde',
    alpha2Code: CountryAlpha2Code.CV,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '16',
  longitude: '-24',
  regions: ['Cabo Verde']
},
{
  name: 'Cayman Islands',
    alpha2Code: CountryAlpha2Code.KY,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '19.5',
  longitude: '-80.5',
  regions: ['Cayman Islands']
},
{
  name: 'Central African Republic',
    alpha2Code: CountryAlpha2Code.CF,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '7',
  longitude: '21',
  regions: ['Bangui', 'Bamingui-Bangoran', 'Basse-Kotto', 'Haute-Kotto', 'Haut-Mbomou', 'Kemo', 'Lobaye', 'Mambere-Kadei', 'Mbomou', 'Nana-Grebizi', 'Nana-Mambere', 'Ombella-Mpoko', 'Ouaka', 'Ouham', 'Ouham-Pende', 'Sangha-Mbaere', 'Vakaga']
},
{
  name: 'Chad',
    alpha2Code: CountryAlpha2Code.TD,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '15',
  longitude: '19',
  regions: ['Batha', 'Biltine', 'Borkou-Ennedi-Tibesti', 'Chari-Baguirmi', 'Guera', 'Kanem', 'Lac', 'Logone-Occidental', 'Logone-Oriental', 'Mayo-Kebbi', 'Moyen-Chari', 'Ouaddai', 'Salamat', 'Tandjile']
},
{
  name: 'Chile',
    alpha2Code: CountryAlpha2Code.CL,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-30',
  longitude: '-71',
  regions: ['Aisen del General Carlos Ibanez del Campo', 'Antofagasta', 'Araucania', 'Atacama', 'Bio-Bio', 'Coquimbo', 'Libertador General Bernardo O\'Higgins', 'Los Lagos', 'Magallanes', 'Maule', 'Region Metropolitana de Santiago', 'Tarapaca', 'Valparaiso']
},
{
  name: 'China',
    alpha2Code: CountryAlpha2Code.CN,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '35',
  longitude: '105',
  regions: ['Beijing', 'Chongqing', 'Shanghai', 'Tianjin', 'Anhui', 'Fujian', 'Gansu', 'Guangdong', 'Guizhou', 'Hainan', 'Hebei', 'Heilongjiang', 'Henan', 'Hubei', 'Hunan', 'Jiangsu', 'Jiangxi', 'Jilin', 'Liaoning', 'Qinghai', 'Shaanxi', 'Shandong', 'Shanxi', 'Sichuan', 'Taiwan', 'Yunnan', 'Zhejiang', 'Guangxi', 'Neia Mongol (mn)', 'Xinjiang', 'Xizang', 'Hong Kong', 'Macau']
},
{
  name: 'Christmas Island',
    alpha2Code: CountryAlpha2Code.CX,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-10.5',
  longitude: '105.66666666',
  regions: ['Christmas Island']
},
{
  name: 'Cocos (Keeling) Islands',
    alpha2Code: CountryAlpha2Code.CC,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-12.5',
  longitude: '96.83333333',
  regions: ['Cocos (Keeling) Islands']
},
{
  name: 'Colombia',
    alpha2Code: CountryAlpha2Code.CO,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '4',
  longitude: '-72',
  regions: ['Distrito Capital de Bogotá', 'Amazonea', 'Antioquia', 'Arauca', 'Atlántico', 'Bolívar', 'Boyacá', 'Caldea', 'Caquetá', 'Casanare', 'Cauca', 'Cesar', 'Córdoba', 'Cundinamarca', 'Chocó', 'Guainía', 'Guaviare', 'La Guajira', 'Magdalena', 'Meta', 'Nariño', 'Norte de Santander', 'Putumayo', 'Quindio', 'Risaralda', 'San Andrés, Providencia y Santa Catalina', 'Santander', 'Sucre', 'Tolima', 'Valle del Cauca', 'Vaupés', 'Vichada']
},
{
  name: 'Comoros',
    alpha2Code: CountryAlpha2Code.KM,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-12.16666666',
  longitude: '44.25',
  regions: ['Anjouan Ndzouani', 'Grande Comore Ngazidja', 'Moheli Moili']
},
{
  name: 'Congo',
    alpha2Code: CountryAlpha2Code.CG,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-1',
  longitude: '15',
  regions: ['Brazzaville', 'Bouenza', 'Cuvette', 'Cuvette-Ouest', 'Kouilou', 'Lekoumou', 'Likouala', 'Niari', 'Plateaux', 'Pool', 'Sangha']
},
{
  name: 'Congo (Democratic Republic of the)',
    alpha2Code: CountryAlpha2Code.CD,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '0',
  longitude: '25',
  regions: ['Kinshasa', 'Bandundu', 'Bas-Congo', 'Equateur', 'Haut-Congo', 'Kasai-Occidental', 'Kasai-Oriental', 'Katanga', 'Maniema', 'Nord-Kivu', 'Orientale', 'Sud-Kivu']
},
{
  name: 'Cook Islands',
    alpha2Code: CountryAlpha2Code.CK,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-21.23333333',
  longitude: '-159.76666666',
  regions: ['Cook Islands']
},
{
  name: 'Costa Rica',
    alpha2Code: CountryAlpha2Code.CR,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '10',
  longitude: '-84',
  regions: ['Alajuela', 'Cartago', 'Guanacaste', 'Heredia', 'Limon', 'Puntarenas', 'San Jose']
},
{
  name: 'Croatia',
    alpha2Code: CountryAlpha2Code.HR,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '45.16666666',
  longitude: '15.5',
  regions: ['Bjelovarsko-bilogorska zupanija', 'Brodsko-posavska zupanija', 'Dubrovacko-neretvanska zupanija', 'Istarska zupanija', 'Karlovacka zupanija', 'Koprivnickco-krizevacka zupanija', 'Krapinako-zagorska zupanija', 'Licko-senjska zupanija', 'Medimurska zupanija', 'Osjecko-baranjska zupanija', 'Pozesko-slavonska zupanija', 'Primorsko-goranska zupanija', 'Sisacko-moelavacka Iupanija', 'Splitako-dalmatinska zupanija', 'Sibenako-kninska zupanija', 'Varaidinska zupanija', 'VirovitiEko-podravska zupanija', 'VuRovarako-srijemska zupanija', 'Zadaraka', 'Zagrebacka zupanija']
},
{
  name: 'Cuba',
    alpha2Code: CountryAlpha2Code.CU,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '21.5',
  longitude: '-80',
  regions: ['Camagey', 'Ciego de `vila', 'Cienfuegos', 'Ciudad de La Habana', 'Granma', 'Guantanamo', 'Holquin', 'La Habana', 'Las Tunas', 'Matanzas', 'Pinar del Rio', 'Sancti Spiritus', 'Santiago de Cuba', 'Villa Clara', 'Isla de la Juventud', 'Pinar del Roo', 'Ciego de Avila', 'Camagoey', 'Holgun', 'Sancti Spritus', 'Municipio Especial Isla de la Juventud']
},
{
  name: 'Curaçao',
    alpha2Code: CountryAlpha2Code.CW,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '12.116667',
  longitude: '-68.933333',
  regions: ['Curaçao']
},
{
  name: 'Cyprus',
    alpha2Code: CountryAlpha2Code.CY,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '35',
  longitude: '33',
  regions: ['Ammochostos Magusa', 'Keryneia', 'Larnaka', 'Lefkosia', 'Lemesos', 'Pafos']
},
{
  name: 'Czech Republic',
    alpha2Code: CountryAlpha2Code.CZ,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '49.75',
  longitude: '15.5',
  regions: ['Jihočeský kraj', 'Jihomoravský kraj', 'Karlovarský kraj', 'Královéhradecký kraj', 'Liberecký kraj', 'Moravskoslezský kraj', 'Olomoucký kraj', 'Pardubický kraj', 'Plzeňský kraj', 'Praha, hlavní město', 'Středočeský kraj',
  'Ústecký kraj', 'Vysočina', 'Zlínský kraj']
},
{
  name: 'Denmark',
    alpha2Code: CountryAlpha2Code.DK,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '56',
  longitude: '10',
  regions: ['Frederiksberg', 'Copenhagen City', 'Copenhagen', 'Frederiksborg', 'Roskilde', 'Vestsjælland', 'Storstrøm', 'Bornholm', 'Fyn', 'South Jutland', 'Ribe', 'Vejle', 'Ringkjøbing', 'Århus', 'Viborg', 'North Jutland']
},
{
  name: 'Djibouti',
    alpha2Code: CountryAlpha2Code.DJ,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '11.5',
  longitude: '43',
  regions: ['Ali Sabiah', 'Dikhil', 'Djibouti', 'Obock', 'Tadjoura']
},
{
  name: 'Dominica',
    alpha2Code: CountryAlpha2Code.DM,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '15.41666666',
  longitude: '-61.33333333',
  regions: ['Dominica']
},
{
  name: 'Dominican Republic',
    alpha2Code: CountryAlpha2Code.DO,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '19',
  longitude: '-70.66666666',
  regions: ['Distrito Nacional (Santo Domingo)', 'Azua', 'Bahoruco', 'Barahona', 'Dajabón', 'Duarte', 'El Seybo [El Seibo]', 'Espaillat', 'Hato Mayor', 'Independencia', 'La Altagracia', 'La Estrelleta [Elias Pina]', 'La Romana', 'La Vega', 'Maroia Trinidad Sánchez', 'Monseñor Nouel', 'Monte Cristi', 'Monte Plata', 'Pedernales', 'Peravia', 'Puerto Plata', 'Salcedo', 'Samaná', 'San Cristóbal', 'San Pedro de Macorís', 'Sánchez Ramírez', 'Santiago', 'Santiago Rodríguez', 'Valverde']
},
{
  name: 'Ecuador',
    alpha2Code: CountryAlpha2Code.EC,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-2',
  longitude: '-77.5',
  regions: ['Azuay', 'Bolivar', 'Canar', 'Carchi', 'Cotopaxi', 'Chimborazo', 'El Oro', 'Esmeraldas', 'Galapagos',
  'Guayas', 'Imbabura', 'Loja', 'Los Rios', 'Manabi', 'Morona-Santiago', 'Napo', 'Orellana', 'Pastaza', 'Pichincha', 'Sucumbios', 'Tungurahua', 'Zamora-Chinchipe']
},
{
  name: 'Egypt',
    alpha2Code: CountryAlpha2Code.EG,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '27',
  longitude: '30',
  regions: ['Ad Daqahllyah', 'Al Bahr al Ahmar', 'Al Buhayrah', 'Al Fayym', 'Al Gharbiyah', 'Al Iskandarlyah', 'Al Isma illyah', 'Al Jizah', 'Al Minuflyah', 'Al Minya', 'Al Qahirah', 'Al Qalyublyah', 'Al Wadi al Jadid', 'Ash Sharqiyah', 'As Suways', 'Aswan', 'Asyut', 'Bani Suwayf', 'Bur Sa\'id', 'Dumyat', 'Janub Sina\'', 'Kafr ash Shaykh', 'Matruh', 'Qina', 'Shamal Sina\'', 'Suhaj']
},
{
  name: 'El Salvador',
    alpha2Code: CountryAlpha2Code.SV,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '13.83333333',
  longitude: '-88.91666666',
  regions: ['Ahuachapan', 'Cabanas', 'Cuscatlan', 'Chalatenango', 'Morazan', 'San Miguel', 'San Salvador', 'Santa Ana', 'San Vicente', 'Sonsonate', 'Usulutan']
},
{
  name: 'Equatorial Guinea',
    alpha2Code: CountryAlpha2Code.GQ,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '2',
  longitude: '10',
  regions: ['Region Continental', 'Region Insular', 'Annobon', 'Bioko Norte', 'Bioko Sur', 'Centro Sur', 'Kie-Ntem', 'Litoral', 'Wele-Nzas']
},
{
  name: 'Eritrea',
    alpha2Code: CountryAlpha2Code.ER,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '15',
  longitude: '39',
  regions: ['Anseba', 'Debub', 'Debubawi Keyih Bahri [Debub-Keih-Bahri]', 'Gash-Barka', 'Maakel [Maekel]', 'Semenawi Keyih Bahri [Semien-Keih-Bahri]']
},
{
  name: 'Estonia',
    alpha2Code: CountryAlpha2Code.EE,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '59',
  longitude: '26',
  regions: ['Harjumsa', 'Hitumea', 'Ida-Virumsa', 'Jogevamsa', 'Jarvamsa', 'Lasnemsa', 'Laane-Virumaa', 'Polvamea', 'Parnumsa', 'Raplamsa', 'Saaremsa', 'Tartumsa', 'Valgamaa', 'Viljandimsa', 'Vorumaa']
},
{
  name: 'Ethiopia',
    alpha2Code: CountryAlpha2Code.ET,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '8',
  longitude: '38',
  regions: ['Addis Ababa', 'Dire Dawa', 'Afar', 'Amara', 'Benshangul-Gumaz', 'Gambela Peoples', 'Harari People', 'Oromia', 'Somali', 'Southern Nations, Nationalities and Peoples', 'Tigrai']
},
{
  name: 'Falkland Islands (Malvinas)',
    alpha2Code: CountryAlpha2Code.FK,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-51.75',
  longitude: '-59',
  regions: ['Falkland Islands (Malvinas)']
},
{
  name: 'Faroe Islands',
    alpha2Code: CountryAlpha2Code.FO,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '62',
  longitude: '-7',
  regions: ['Faroe Islands']
},
{
  name: 'Fiji',
    alpha2Code: CountryAlpha2Code.FJ,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-18',
  longitude: '175',
  regions: ['Eastern', 'Northern', 'Western', 'Rotuma']
},
{
  name: 'Finland',
    alpha2Code: CountryAlpha2Code.FI,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.SCANDINAVIA,
  opCo: OpCo.OPEA,
  latitude: '64',
  longitude: '26',
  regions: ['South Karelia', 'South Ostrobothnia', 'Etelä-Savo', 'Häme', 'Itä-Uusimaa', 'Kainuu', 'Central Ostrobothnia', 'Central Finland', 'Kymenlaakso', 'Lapland', 'Tampere Region', 'Ostrobothnia', 'North Karelia', 'Nothern Ostrobothnia', 'Northern Savo', 'Päijät-Häme', 'Satakunta', 'Uusimaa', 'South-West Finland', 'Åland']
},
{
  name: 'France',
    alpha2Code: CountryAlpha2Code.FR,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.FRANCE,
  constructionCluster: ConstructionCluster.FRANCE,
  opCo: OpCo.OPEA,
  latitude: '46',
  longitude: '2',
  regions: [
    'Ain', 'Aisne', 'Allier', 'Alpes-de-Haute-Provence', 'Alpes-Maritimes', 'Ardèche', 'Ardennes', 'Ariège', 'Aube', 'Aude', 'Aveyron',
    'Bas-Rhin', 'Bouches-du-Rhône',
    'Calvados', 'Cantal', 'Charente', 'Charente-Maritime', 'Cher', 'Corrèze', 'Corse-du-Sud', 'Côte-d\'Or', 'Côtes-d\'Armor', 'Creuse',
    'Deux-Sèvres', 'Dordogne', 'Doubs', 'Drôme',
    'Essonne', 'Eure', 'Eure-et-Loir',
    'Finistère',
    'Gard', 'Gers', 'Gironde',
    'Haut-Rhin', 'Haute-Corse', 'Haute-Garonne', 'Haute-Loire', 'Haute-Marne', 'Haute-Saône', 'Haute-Savoie', 'Haute-Vienne', 'Hautes-Alpes', 'Hautes-Pyrénées', 'Hauts-de-Seine', 'Hérault',
    'Indre', 'Ille-et-Vilaine', 'Indre-et-Loire', 'Isère',
    'Jura',
    'La Corse', 'Landes', 'Loir-et-Cher', 'Loire', 'Loire-Atlantique', 'Loiret', 'Lot', 'Lot-et-Garonne', 'Lozère',
    'Maine-et-Loire', 'Manche', 'Marne', 'Mayenne', 'Meurthe-et-Moselle', 'Meuse', 'Morbihan', 'Moselle',
    'Nièvre', 'Nord',
    'Oise', 'Orne',
    'Paris', 'Pas-de-Calais', 'Puy-de-Dôme', 'Pyrénées-Atlantiques', 'Pyrénées-Orientales',
    'Rhône',
    'Saône-et-Loire', 'Sarthe', 'Savoie', 'Seine-et-Marne', 'Seine-Maritime', 'Seine-Saint-Denis', 'Somme',
    'Tarn', 'Tarn-et-Garonne', 'Territoire de Belfort',
    'Val d\'Oise', 'Val-de-Marne', 'Var', 'Vaucluse', 'Vendée', 'Vienne', 'Vosges', 'Yonne', 'Yvelines']
},
{
  name: 'French Guiana',
    alpha2Code: CountryAlpha2Code.GF,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '4',
  longitude: '-53',
  regions: ['French Guiana']
},
{
  name: 'French Polynesia',
    alpha2Code: CountryAlpha2Code.PF,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-15',
  longitude: '-140',
  regions: ['French Polynesia']
},
{
  name: 'French Southern Territories',
    alpha2Code: CountryAlpha2Code.TF,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-49.25',
  longitude: '69.167',
  regions: ['French Southern Territories']
},
{
  name: 'Gabon',
    alpha2Code: CountryAlpha2Code.GA,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-1',
  longitude: '11.75',
  regions: ['Gabon']
},
{
  name: 'Gambia',
    alpha2Code: CountryAlpha2Code.GM,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '13.46666666',
  longitude: '-16.56666666',
  regions: ['Banjul', 'Lower River', 'MacCarthy Island', 'North Bank', 'Upper River']
},
{
  name: 'Georgia',
    alpha2Code: CountryAlpha2Code.GE,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '42',
  longitude: '43.5',
  regions: ['Georgia']
},
{
  name: 'Germany',
    alpha2Code: CountryAlpha2Code.DE,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.GERMANY_NETHERLANDS,
  constructionCluster: ConstructionCluster.GERMANY,
  opCo: OpCo.OPEA,
  latitude: '50',
  longitude: '9',
  regions: ['Baden-Wuerttemberg', 'Bayern', 'Bremen', 'Hamburg', 'Hessen', 'Niedersachsen', 'Nordrhein-Westfalen', 'Rheinland-Pfalz', 'Saarland', 'Schleswig-Holstein', 'Berlin', 'Brandenburg', 'Mecklenburg-Vorpommern', 'Sachsen', 'Sachsen-Anhalt', 'Thueringen']
},
{
  name: 'Ghana',
    alpha2Code: CountryAlpha2Code.GH,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '8',
  longitude: '-2',
  regions: ['Ashanti', 'Brong-Ahafo', 'Greater Accra', 'Upper East', 'Upper West', 'Volta']
},
{
  name: 'Gibraltar',
    alpha2Code: CountryAlpha2Code.GI,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '36.13333333',
  longitude: '-5.35',
  regions: ['Gibraltar']
},
{
  name: 'Greece',
    alpha2Code: CountryAlpha2Code.GR,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.ITALY_GREECE_IBERIA,
  opCo: OpCo.OPEA,
  latitude: '39',
  longitude: '22',
  regions: ['Achaïa', 'Aitolia-Akarnania', 'Argolis', 'Arkadia', 'Arta', 'Attiki', 'Chalkidiki', 'Chania', 'Chios', 'Dodekanisos', 'Drama', 'Evros', 'Evrytania', 'Evvoia', 'Florina', 'Fokis', 'Fthiotis', 'Grevena', 'Ileia', 'Imathia', 'Ioannina', 'Irakleion', 'Karditsa', 'Kastoria', 'Kavalla', 'Kefallinia', 'Kerkyra', 'Kilkis', 'Korinthia', 'Kozani', 'Kyklades', 'Lakonia', 'Larisa', 'Lasithion', 'Lefkas', 'Lesvos', 'Magnisia', 'Messinia', 'Pella', 'Preveza', 'Rethymnon', 'Rodopi', 'Samos', 'Serrai', 'Thesprotia', 'Thessaloniki', 'Trikala', 'Voiotia', 'Xanthi', 'Zakynthos', 'Agio Oros']
},
{
  name: 'Greenland',
    alpha2Code: CountryAlpha2Code.GL,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '72',
  longitude: '-40',
  regions: ['Greenland']
},
{
  name: 'Grenada',
    alpha2Code: CountryAlpha2Code.GD,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '12.11666666',
  longitude: '-61.66666666',
  regions: ['Grenada']
},
{
  name: 'Guadeloupe',
    alpha2Code: CountryAlpha2Code.GP,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '16.25',
  longitude: '-61.583333',
  regions: ['Guadeloupe']
},
{
  name: 'Guam',
    alpha2Code: CountryAlpha2Code.GU,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '13.46666666',
  longitude: '144.78333333',
  regions: ['Chuuk', 'Kosrae', 'Pohnpei', 'Yap']
},
{
  name: 'Guatemala',
    alpha2Code: CountryAlpha2Code.GT,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '15.5',
  longitude: '-90.25',
  regions: ['Alta Verapez', 'Baja Verapez', 'Chimaltenango', 'Chiquimula', 'El Progreso', 'Escuintla', 'Guatemala', 'Huehuetenango', 'Izabal', 'Jalapa', 'Jutiapa', 'Peten', 'Quetzaltenango', 'Quiche', 'Reta.thuleu', 'Sacatepequez', 'San Marcos', 'Santa Rosa', 'Solol6', 'Suchitepequez', 'Totonicapan', 'Zacapa']
},
{
  name: 'Guernsey',
    alpha2Code: CountryAlpha2Code.GG,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '49.46666666',
  longitude: '-2.58333333',
  regions: ['Guernsey']
},
{
  name: 'Guinea',
    alpha2Code: CountryAlpha2Code.GN,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '11',
  longitude: '-10',
  regions: ['Beyla', 'Boffa', 'Boke', 'Coyah', 'Dabola', 'Dalaba', 'Dinguiraye', 'Dubreka', 'Faranah', 'Forecariah', 'Fria', 'Gaoual', 'Guekedou', 'Kankan', 'Kerouane', 'Kindia', 'Kissidougou', 'Koubia', 'Koundara', 'Kouroussa', 'Labe', 'Lelouma', 'Lola', 'Macenta', 'Mali', 'Mamou', 'Mandiana', 'Nzerekore', 'Pita', 'Siguiri', 'Telimele', 'Tougue', 'Yomou']
},
{
  name: 'Guinea-Bissau',
    alpha2Code: CountryAlpha2Code.GW,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '12',
  longitude: '-15',
  regions: ['Bissau', 'Bafata', 'Biombo', 'Bolama', 'Cacheu', 'Gabu', 'Oio', 'Quloara', 'Tombali S']
},
{
  name: 'Guyana',
    alpha2Code: CountryAlpha2Code.GY,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '5',
  longitude: '-59',
  regions: ['Barima-Waini', 'Cuyuni-Mazaruni', 'Demerara-Mahaica', 'East Berbice-Corentyne', 'Essequibo Islands-West Demerara', 'Mahaica-Berbice', 'Pomeroon-Supenaam', 'Potaro-Siparuni', 'Upper Demerara-Berbice', 'Upper Takutu-Upper Essequibo']
},
{
  name: 'Haiti',
    alpha2Code: CountryAlpha2Code.HT,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '19',
  longitude: '-72.41666666',
  regions: ['Grande-Anse', 'Nord-Est', 'Nord-Ouest', 'Ouest', 'Sud', 'Sud-Est', 'Artibonite', 'Centre', 'Nippes',
  'Nord']
},
{
  name: 'Heard Island and McDonald Islands',
    alpha2Code: CountryAlpha2Code.HM,
  continent: Continent.ANTARCTICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-53.1',
  longitude: '72.51666666',
  regions: ['Heard Island and McDonald Islands']
},
{
  name: 'Holy See',
    alpha2Code: CountryAlpha2Code.VA,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '41.9',
  longitude: '12.45',
  regions: ['Holy See']
},
{
  name: 'Honduras',
    alpha2Code: CountryAlpha2Code.HN,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '15',
  longitude: '-86.5',
  regions: ['Atlantida', 'Colon', 'Comayagua', 'Copan', 'Cortes', 'Choluteca', 'El Paraiso', 'Francisco Morazan',
  'Gracias a Dios', 'Intibuca', 'Islas de la Bahia', 'Lempira', 'Ocotepeque', 'Olancho', 'Santa Barbara', 'Valle', 'Yoro']        },
{
  name: 'Hong Kong',
    alpha2Code: CountryAlpha2Code.HK,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '22.25',
  longitude: '114.16666666',
  regions: ['Central and Western', 'Eastern', 'Southern', 'Wan Chai', 'Kowloon City', 'Kwun Tong', 'Sham Shui Po', 'Wong Tai Sin', 'Yau Tsim Mong', 'Islands', 'Kwai Tsing', 'North', 'Sai Kung', 'Sha Tin', 'Tai Po', 'Tsuen Wan', 'Tuen Mun', 'Yuen Long']
},
{
  name: 'Hungary',
    alpha2Code: CountryAlpha2Code.HU,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '47',
  longitude: '20',
  regions: ['Budapest', 'Bács-Kiskun', 'Baranya', 'Békés', 'Borsod-Abaúj-Zemplén', 'Csongrád', 'Fejér', 'Győr-Moson-Sopron', 'Hajdu-Bihar', 'Heves', 'Jász-Nagykun-Szolnok', 'Komárom-Esztergom', 'Nográd', 'Pest', 'Somogy', 'Szabolcs-Szatmár-Bereg', 'Tolna', 'Vas', 'Veszprém', 'Zala', 'Békéscsaba', 'Debrecen', 'Dunaújváros', 'Eger', 'Győr', 'Hódmezővásárhely', 'Kaposvár', 'Kecskemét', 'Miskolc', 'Nagykanizsa', 'Nyiregyháza', 'Pécs', 'Salgótarján', 'Sopron', 'Szeged', 'Székesfehérvár', 'Szekszárd', 'Szolnok', 'Szombathely', 'Tatabánya', 'Zalaegerszeg']
},
{
  name: 'Iceland',
    alpha2Code: CountryAlpha2Code.IS,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '65',
  longitude: '-18',
  regions: ['Austurland', 'Hofuoborgarsvaeoi utan Reykjavikur', 'Norourland eystra', 'Norourland vestra', 'Reykjavik', 'Suourland', 'Suournes', 'Vestfirolr', 'Vesturland']
},
{
  name: 'India',
    alpha2Code: CountryAlpha2Code.IN,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '20',
  longitude: '77',
  regions: ['Maharashtra', 'Karnataka', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Kerala', 'Madhya Pradesh', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Orissa', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Tripura', 'Uttaranchal',
  'Uttar Pradesh', 'West Bengal', 'Andaman and Nicobar Islands', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Lakshadweep', 'Pondicherry']
},
{
  name: 'Indonesia',
    alpha2Code: CountryAlpha2Code.ID,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-5',
  longitude: '120',
  regions: ['Bali', 'Bangka Belitung', 'Banten', 'Bengkulu', 'Gorontalo', 'Irian Jaya', 'Jambi', 'Jawa Barat', 'Jawa Tengah', 'Jawa Timur', 'Kalimantan Barat', 'Kalimantan Timur', 'Kalimantan Selatan', 'Kepulauan Riau', 'Lampung', 'Maluku', 'Maluku Utara', 'Nusa Tenggara Barat', 'Nusa Tenggara Timur', 'Papua', 'Riau', 'Sulawesi Selatan', 'Sulawesi Tengah', 'Sulawesi Tenggara', 'Sulawesi Utara', 'Sumatra Barat', 'Sumatra Selatan', 'Sumatera Utara', 'Jakarta Raya', 'Aceh', 'Yogyakarta']
},
{
  name: 'Côte d Ivoire',
    alpha2Code: CountryAlpha2Code.CI,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '8',
  longitude: '-5',
  regions: ['Côte d Ivoire']
},
{
  name: 'Iran (Islamic Republic of)',
    alpha2Code: CountryAlpha2Code.IR,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '32',
  longitude: '53',
  regions: ['Ardabil', 'Azarbayjan-e Gharbi', 'Azarbayjan-e Sharqi', 'Bushehr', 'Chahar Mahall va Bakhtiari', 'Esfahan', 'Fars', 'Gilan', 'Golestan', 'Hamadan', 'Hormozgan', 'Iiam', 'Kerman', 'Kermanshah', 'Khorasan', 'Khuzestan', 'Kohjiluyeh va Buyer Ahmad', 'Kordestan', 'Lorestan', 'Markazi', 'Mazandaran', 'Qazvin', 'Qom', 'Semnan', 'Sistan va Baluchestan', 'Tehran', 'Yazd', 'Zanjan']
},
{
  name: 'Iraq',
    alpha2Code: CountryAlpha2Code.IQ,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '33',
  longitude: '44',
  regions: ['Al Anbar', 'Al Ba,rah', 'Al Muthanna', 'Al Qadisiyah', 'An Najef', 'Arbil', 'As Sulaymaniyah', 'At Ta\'mim', 'Babil', 'Baghdad', 'Dahuk', 'Dhi Qar', 'Diyala', 'Karbala\'', 'Maysan', 'Ninawa', 'Salah ad Din', 'Wasit']
},
{
  name: 'Ireland',
    alpha2Code: CountryAlpha2Code.IE,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.UK_IRELAND,
  constructionCluster: ConstructionCluster.UK_IRELAND,
  opCo: OpCo.OPEA,
  latitude: '53',
  longitude: '-8',
  regions: ['Cork', 'Clare', 'Cavan', 'Carlow', 'Dublin', 'Donegal', 'Galway', 'Kildare', 'Kilkenny', 'Kerry', 'Longford', 'Louth', 'Limerick', 'Leitrim', 'Laois', 'Meath', 'Monaghan', 'Mayo', 'Offaly', 'Roscommon', 'Sligo', 'Tipperary', 'Waterford', 'Westmeath', 'Wicklow', 'Wexford']
},
{
  name: 'Isle of Man',
    alpha2Code: CountryAlpha2Code.IM,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '54.25',
  longitude: '-4.5',
  regions: ['Isle of Man']
},
{
  name: 'Israel',
    alpha2Code: CountryAlpha2Code.IL,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '31.5',
  longitude: '34.75',
  regions: ['HaDarom', 'HaMerkaz', 'HaZafon', 'Haifa', 'Tel-Aviv', 'Jerusalem']
},
{
  name: 'Italy',
    alpha2Code: CountryAlpha2Code.IT,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.ITALY_IBERIA,
  constructionCluster: ConstructionCluster.ITALY_GREECE_IBERIA,
  opCo: OpCo.OPEA,
  latitude: '42.83333333',
  longitude: '12.83333333',
  regions: ['Agrigento', 'Alessandria', 'Ancona', 'Aosta', 'Arezzo', 'Ascoli Piceno', 'Asti', 'Avellino', 'Bari',
  'Belluno', 'Benevento', 'Bergamo', 'Biella', 'Bologna', 'Bolzano', 'Brescia', 'Brindisi', 'Cagliari', 'Caltanissetta', 'Campobasso', 'Caserta', 'Catania', 'Catanzaro', 'Chieti', 'Como', 'Cosenza', 'Cremona', 'Crotone', 'Cuneo', 'Enna', 'Ferrara', 'Firenze', 'Foggia', 'Forlì-Cesena', 'Frosinone', 'Genova', 'Gorizia', 'Grosseto', 'Imperia', 'Isernia', 'L\'Aquila', 'La Spezia', 'Latina', 'Lecce', 'Lecco', 'Livorno', 'Lodi', 'Lucca', 'Macerata', 'Mantova', 'Massa-Carrara', 'Matera', 'Messina', 'Milano', 'Modena', 'Napoli', 'Novara', 'Nuoro', 'Oristano', 'Padova', 'Palermo', 'Parma', 'Pavia', 'Perugia', 'Pesaro e Urbino', 'Pescara', 'Piacenza', 'Pisa', 'Pistoia', 'Pordenone', 'Potenza', 'Prato', 'Ragusa', 'Ravenna', 'Reggio Calabria', 'Reggio Emilia', 'Rieti', 'Rimini', 'Roma', 'Rovigo', 'Salerno', 'Sassari', 'Savona', 'Siena', 'Siracusa', 'Sondrio', 'Taranto', 'Teramo', 'Terni', 'Torino', 'Trapani', 'Trento', 'Treviso', 'Trieste', 'Udine', 'Varese',
  'Venezia', 'Verbano-Cusio-Ossola', 'Vercelli', 'Verona', 'Vibo Valentia', 'Vicenza', 'Viterbo', 'Carbonia-Iglesias', 'Olbia-Tempio', 'Medio Campidano', 'Ogliastra', 'Barletta-Andria-Trani', 'Fermo', 'Monza e Brianza']
},
{
  name: 'Jamaica',
    alpha2Code: CountryAlpha2Code.JM,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '18.25',
  longitude: '-77.5',
  regions: ['Clarendon', 'Hanover', 'Kingston', 'Portland', 'Saint Andrew', 'Saint Ann', 'Saint Catherine', 'Saint Elizabeth', 'Saint James', 'Saint Mary', 'Saint Thomas', 'Trelawny', 'Westmoreland', 'Manchester']
},
{
  name: 'Japan',
    alpha2Code: CountryAlpha2Code.JP,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '36',
  longitude: '138',
  regions: ['Aichi', 'Akita', 'Aomori', 'Chiba', 'Ehime', 'Fukui', 'Fukuoka', 'Fukusima', 'Gifu', 'Gunma', 'Hiroshima', 'Hokkaido', 'Hyogo', 'Ibaraki', 'Ishikawa', 'Iwate', 'Kagawa', 'Kagoshima', 'Kanagawa', 'Kochi', 'Kumamoto', 'Kyoto', 'Mie', 'Miyagi', 'Miyazaki', 'Nagano', 'Nagasaki', 'Nara', 'Niigata', 'Oita', 'Okayama', 'Okinawa', 'Osaka', 'Saga', 'Saitama', 'Shiga', 'Shimane', 'Shizuoka', 'Tochigi', 'Tokushima', 'Tokyo', 'Tottori', 'Toyama', 'Wakayama', 'Yamagata', 'Yamaguchi', 'Yamanashi']
},
{
  name: 'Jersey',
    alpha2Code: CountryAlpha2Code.JE,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '49.25',
  longitude: '-2.16666666',
  regions: ['Jersey']
},
{
  name: 'Jordan',
    alpha2Code: CountryAlpha2Code.JO,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '31',
  longitude: '36',
  regions: ['Ajln', 'Al \'Aqaba', 'Al Balqa\'', 'Al Karak', 'Al Mafraq', 'Amman', 'At Tafilah', 'Az Zarga', 'Irbid', 'Jarash', 'Ma\'an', 'Madaba']
},
{
  name: 'Kazakhstan',
    alpha2Code: CountryAlpha2Code.KZ,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '48',
  longitude: '68',
  regions: ['Almaty', 'Astana', 'Almaty oblysy', 'Aqmola oblysy', 'Aqtobe oblysy', 'Atyrau oblyfiy', 'Batys Quzaqstan oblysy', 'Mangghystau oblysy', 'Ongtustik Quzaqstan oblysy', 'Pavlodar oblysy', 'Qaraghandy oblysy', 'Qostanay oblysy', 'Qyzylorda oblysy', 'Shyghys Quzaqstan oblysy', 'Soltustik Quzaqstan oblysy', 'Zhambyl oblysy Zhambylskaya oblast\'']
},
{
  name: 'Kenya',
    alpha2Code: CountryAlpha2Code.KE,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '1',
  longitude: '38',
  regions: ['Nairobi Municipality', 'Coast', 'North-Eastern Kaskazini Mashariki', 'Rift Valley', 'Western Magharibi']
},
{
  name: 'Kiribati',
    alpha2Code: CountryAlpha2Code.KI,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '1.41666666',
  longitude: '173',
  regions: ['Gilbert Islands', 'Line Islands', 'Phoenix Islands']
},
{
  name: 'Kuwait',
    alpha2Code: CountryAlpha2Code.KW,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '29.5',
  longitude: '45.75',
  regions: ['Al Ahmadi', 'Al Farwanlyah', 'Al Jahrah', 'Al Kuwayt', 'Hawalli']
},
{
  name: 'Kyrgyzstan',
    alpha2Code: CountryAlpha2Code.KG,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '41',
  longitude: '75',
  regions: ['Bishkek', 'Batken', 'Chu', 'Jalal-Abad', 'Naryn', 'Osh', 'Talas', 'Ysyk-Kol']
},
{
  name: 'Lao People s Democratic Republic',
    alpha2Code: CountryAlpha2Code.LA,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '18',
  longitude: '105',
  regions: ['Vientiane', 'Attapu', 'Bokeo', 'Bolikhamxai', 'Champasak', 'Houaphan', 'Khammouan', 'Louang Namtha',
  'Louangphabang', 'Oudomxai', 'Phongsali', 'Salavan', 'Savannakhet', 'Xaignabouli', 'Xiasomboun', 'Xekong', 'Xiangkhoang']
},
{
  name: 'Latvia',
    alpha2Code: CountryAlpha2Code.LV,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '57',
  longitude: '25',
  regions: ['Aizkraukles Apripkis', 'Alkanes Apripkis', 'Balvu Apripkis', 'Bauskas Apripkis', 'Cesu Aprikis', 'Daugavpile Apripkis', 'Dobeles Apripkis', 'Gulbenes Aprlpkis', 'Jelgavas Apripkis', 'Jekabpils Apripkis', 'Kraslavas Apripkis', 'Kuldlgas Apripkis', 'Limbazu Apripkis', 'Liepajas Apripkis', 'Ludzas Apripkis', 'Madonas Apripkis', 'Ogres Apripkis', 'Preilu Apripkis', 'Rezaknes Apripkis', 'Rigas Apripkis', 'Saldus Apripkis', 'Talsu Apripkis', 'Tukuma Apriplcis', 'Valkas Apripkis', 'Valmieras Apripkis', 'Ventspils Apripkis', 'Daugavpils', 'Jelgava', 'Jurmala', 'Liepaja', 'Rezekne',
  'Riga', 'Ventspils']
},
{
  name: 'Lebanon',
    alpha2Code: CountryAlpha2Code.LB,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '33.83333333',
  longitude: '35.83333333',
  regions: ['Beirout', 'El Begsa', 'Jabal Loubnane', 'Loubnane ech Chemali', 'Loubnane ej Jnoubi', 'Nabatiye']
},
{
  name: 'Lesotho',
    alpha2Code: CountryAlpha2Code.LS,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-29.5',
  longitude: '28.5',
  regions: ['Berea', 'Butha-Buthe', 'Leribe', 'Mafeteng', 'Maseru', 'Mohale\'s Hoek', 'Mokhotlong', 'Qacha\'s Nek', 'Quthing', 'Thaba-Tseka']
},
{
  name: 'Liberia',
    alpha2Code: CountryAlpha2Code.LR,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '6.5',
  longitude: '-9.5',
  regions: ['Bomi', 'Bong', 'Grand Basaa', 'Grand Cape Mount', 'Grand Gedeh', 'Grand Kru', 'Lofa', 'Margibi', 'Maryland', 'Montserrado', 'Nimba', 'Rivercess', 'Sinoe']
},
{
  name: 'Libya',
    alpha2Code: CountryAlpha2Code.LY,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '25',
  longitude: '17',
  regions: ['Libya']
},
{
  name: 'Liechtenstein',
    alpha2Code: CountryAlpha2Code.LI,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '47.26666666',
  longitude: '9.53333333',
  regions: ['Liechtenstein']
},
{
  name: 'Lithuania',
    alpha2Code: CountryAlpha2Code.LT,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '56',
  longitude: '24',
  regions: ['Alytaus Apskritis', 'Kauno Apskritis', 'Klaipedos Apskritis', 'Marijampoles Apskritis', 'Panevezio Apskritis', 'Sisuliu Apskritis', 'Taurages Apskritis', 'Telsiu Apskritis', 'Utenos Apskritis', 'Vilniaus Apskritis']
},
{
  name: 'Luxembourg',
    alpha2Code: CountryAlpha2Code.LU,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '49.75',
  longitude: '6.16666666',
  regions: ['Diekirch', 'GreveNmacher']
},
{
  name: 'Macao',
    alpha2Code: CountryAlpha2Code.MO,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '22.16666666',
  longitude: '113.55',
  regions: ['Macao']
},
{
  name: 'Macedonia (the former Yugoslav Republic of)',
    alpha2Code: CountryAlpha2Code.MK,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '41.83333333',
  longitude: '22',
  regions: ['Macedonia (the former Yugoslav Republic of)']
},
{
  name: 'Madagascar',
    alpha2Code: CountryAlpha2Code.MG,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-20',
  longitude: '47',
  regions: ['Antananarivo', 'Antsiranana', 'Fianarantsoa', 'Mahajanga', 'Toamasina', 'Toliara']
},
{
  name: 'Malawi',
    alpha2Code: CountryAlpha2Code.MW,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-13.5',
  longitude: '34',
  regions: ['Balaka', 'Blantyre', 'Chikwawa', 'Chiradzulu', 'Chitipa', 'Dedza', 'Dowa', 'Karonga', 'Kasungu', 'Likoma Island', 'Lilongwe', 'Machinga', 'Mangochi', 'Mchinji', 'Mulanje', 'Mwanza', 'Mzimba', 'Nkhata Bay', 'Nkhotakota', 'Nsanje', 'Ntcheu', 'Ntchisi', 'Phalomba', 'Rumphi', 'Salima', 'Thyolo', 'Zomba']
},
{
  name: 'Malaysia',
    alpha2Code: CountryAlpha2Code.MY,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '2.5',
  longitude: '112.5',
  regions: ['Wilayah Persekutuan Kuala Lumpur', 'Wilayah Persekutuan Labuan', 'Wilayah Persekutuan Putrajaya', 'Johor', 'Kedah', 'Kelantan', 'Melaka', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Pulau Pinang', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu']
},
{
  name: 'Maldives',
    alpha2Code: CountryAlpha2Code.MV,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '3.25',
  longitude: '73',
  regions: ['Male', 'Alif', 'Baa', 'Dhaalu', 'Faafu', 'Gaaf Alif', 'Gaefu Dhaalu', 'Gnaviyani', 'Haa Alif', 'Haa Dhaalu', 'Kaafu', 'Laamu', 'Lhaviyani', 'Meemu', 'Noonu', 'Raa', 'Seenu', 'Shaviyani', 'Thaa', 'Vaavu']
},
{
  name: 'Mali',
    alpha2Code: CountryAlpha2Code.ML,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '17',
  longitude: '-4',
  regions: ['Bamako', 'Gao', 'Kayes', 'Kidal', 'Xoulikoro', 'Mopti', 'S69ou', 'Sikasso', 'Tombouctou']
},
{
  name: 'Malta',
    alpha2Code: CountryAlpha2Code.MT,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '35.83333333',
  longitude: '14.58333333',
  regions: ['Malta']
},
{
  name: 'Marshall Islands',
    alpha2Code: CountryAlpha2Code.MH,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '9',
  longitude: '168',
  regions: ['Ailinglapalap', 'Ailuk', 'Arno', 'Aur', 'Ebon', 'Eniwetok', 'Jaluit', 'Kili', 'Kwajalein', 'Lae', 'Lib', 'Likiep', 'Majuro', 'Maloelap', 'Mejit', 'Mili', 'Namorik', 'Namu', 'Rongelap', 'Ujae', 'Ujelang', 'Utirik', 'Wotho', 'Wotje']
},
{
  name: 'Martinique',
    alpha2Code: CountryAlpha2Code.MQ,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '14.666667',
  longitude: '-61',
  regions: ['Martinique']
},
{
  name: 'Mauritania',
    alpha2Code: CountryAlpha2Code.MR,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '20',
  longitude: '-12',
  regions: ['Nouakchott', 'Assaba', 'Brakna', 'Dakhlet Nouadhibou', 'Gorgol', 'Guidimaka', 'Hodh ech Chargui', 'Hodh el Charbi', 'Inchiri', 'Tagant', 'Tiris Zemmour', 'Trarza']
},
{
  name: 'Mauritius',
    alpha2Code: CountryAlpha2Code.MU,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-20.28333333',
  longitude: '57.55',
  regions: ['Beau Bassin-Rose Hill', 'Curepipe', 'Port Louis', 'Quatre Bornes', 'Vacosa-Phoenix', 'Black River', 'Flacq', 'Grand Port', 'Moka', 'Pamplemousses', 'Plaines Wilhems', 'Riviere du Rempart', 'Savanne', 'Agalega Islands', 'Cargados Carajos Shoals', 'Rodrigues Island']
},
{
  name: 'Mayotte',
    alpha2Code: CountryAlpha2Code.YT,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-12.83333333',
  longitude: '45.16666666',
  regions: ['Mayotte']
},
{
  name: 'Mexico',
    alpha2Code: CountryAlpha2Code.MX,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '23',
  longitude: '-102',
  regions: ['Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Coahuila', 'Colima', 'Chiapas', 'Chihuahua', 'Durango', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Mexico', 'Michoacin', 'Morelos', 'Nayarit',
  'Nuevo Leon', 'Oaxaca', 'Puebla', 'Queretaro', 'Quintana Roo', 'San Luis Potosi', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatan', 'Zacatecas', 'Distrito Federal']
},
{
  name: 'Micronesia (Federated States of)',
    alpha2Code: CountryAlpha2Code.FM,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '6.91666666',
  longitude: '158.25',
  regions: ['Micronesia (Federated States of)']
},
{
  name: 'Moldova (Republic of)',
    alpha2Code: CountryAlpha2Code.MD,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '47',
  longitude: '29',
  regions: ['Gagauzia, Unitate Teritoriala Autonoma', 'Chisinau', 'Stinga Nistrului, unitatea teritoriala din', 'Balti', 'Cahul', 'Edinet', 'Lapusna', 'Orhei', 'Soroca', 'Taraclia', 'Tighina [Bender]', 'Ungheni']
},
{
  name: 'Monaco',
    alpha2Code: CountryAlpha2Code.MC,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '43.73333333',
  longitude: '7.4',
  regions: ['Monaco']
},
{
  name: 'Mongolia',
    alpha2Code: CountryAlpha2Code.MN,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '46',
  longitude: '105',
  regions: ['Ulaanbaatar', 'Arhangay', 'Bayanhongor', 'Bayan-Olgiy', 'Bulgan', 'Darhan uul', 'Dornod', 'Dornogov,', 'DundgovL', 'Dzavhan', 'Govi-Altay', 'Govi-Smber', 'Hentiy', 'Hovd', 'Hovsgol', 'Omnogovi', 'Orhon', 'Ovorhangay', 'Selenge', 'Shbaatar', 'Tov', 'Uvs']
},
{
  name: 'Montenegro',
    alpha2Code: CountryAlpha2Code.ME,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '42.5',
  longitude: '19.3',
  regions: ['Montenegro']
},
{
  name: 'Montserrat',
    alpha2Code: CountryAlpha2Code.MS,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '16.75',
  longitude: '-62.2',
  regions: ['Montserrat']
},
{
  name: 'Morocco',
    alpha2Code: CountryAlpha2Code.MA,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '32',
  longitude: '-5',
  regions: ['Agadir', 'Aït Baha', 'Aït Melloul', 'Al Haouz', 'Al Hoceïma', 'Assa-Zag', 'Azilal', 'Beni Mellal', 'Ben Sllmane', 'Berkane', 'Boujdour', 'Boulemane', 'Casablanca  [Dar el Beïda]', 'Chefchaouene', 'Chichaoua', 'El Hajeb',
  'El Jadida', 'Errachidia', 'Essaouira', 'Es Smara', 'Fès', 'Figuig', 'Guelmim', 'Ifrane', 'Jerada', 'Kelaat Sraghna', 'Kénitra', 'Khemisaet', 'Khenifra', 'Khouribga', 'Laâyoune (EH)', 'Larache', 'Marrakech', 'Meknsès', 'Nador', 'Ouarzazate', 'Oued ed Dahab (EH)', 'Oujda', 'Rabat-Salé', 'Safi', 'Sefrou', 'Settat', 'Sidl Kacem', 'Tanger', 'Tan-Tan', 'Taounate', 'Taroudannt', 'Tata', 'Taza', 'Tétouan', 'Tiznit']
},
{
  name: 'Mozambique',
    alpha2Code: CountryAlpha2Code.MZ,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-18.25',
  longitude: '35',
  regions: ['Maputo', 'Cabo Delgado', 'Gaza', 'Inhambane', 'Manica', 'Numpula', 'Niaaea', 'Sofala', 'Tete', 'Zambezia']
},
{
  name: 'Myanmar',
    alpha2Code: CountryAlpha2Code.MM,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '22',
  longitude: '98',
  regions: ['Ayeyarwady', 'Bago', 'Magway', 'Mandalay', 'Sagaing', 'Tanintharyi', 'Yangon', 'Chin', 'Kachin', 'Kayah', 'Kayin', 'Mon', 'Rakhine', 'Shan']
},
{
  name: 'Namibia',
    alpha2Code: CountryAlpha2Code.NA,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-22',
  longitude: '17',
  regions: ['Caprivi', 'Erongo', 'Hardap', 'Karas', 'Khomae', 'Kunene', 'Ohangwena', 'Okavango', 'Omaheke', 'Omusati', 'Oshana', 'Oshikoto', 'Otjozondjupa']
},
{
  name: 'Nauru',
    alpha2Code: CountryAlpha2Code.NR,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-0.53333333',
  longitude: '166.91666666',
  regions: ['Nauru']
},
{
  name: 'Nepal',
    alpha2Code: CountryAlpha2Code.NP,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '28',
  longitude: '84',
  regions: ['Nepal']
},
{
  name: 'Netherlands',
    alpha2Code: CountryAlpha2Code.NL,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.GERMANY_NETHERLANDS,
  constructionCluster: ConstructionCluster.NETHERLANDS,
  opCo: OpCo.OPEA,
  latitude: '52.5',
  longitude: '5.75',
  regions: ['Drente', 'Flevoland', 'Friesland', 'Gelderland', 'Groningen', 'Noord-Brabant', 'Noord-Holland', 'Overijssel', 'Utrecht', 'Zuid-Holland', 'Zeeland', 'Limburg']
},
{
  name: 'New Caledonia',
    alpha2Code: CountryAlpha2Code.NC,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-21.5',
  longitude: '165.5',
  regions: ['New Caledonia']
},
{
  name: 'New Zealand',
    alpha2Code: CountryAlpha2Code.NZ,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-41',
  longitude: '174',
  regions: ['Auckland', 'Bay of Plenty', 'Canterbury', 'Gisborne', 'Hawkes Bay', 'Manawatu-Wanganui', 'Marlborough', 'Nelson', 'Northland', 'Otago', 'Southland', 'Taranaki', 'Tasman', 'waikato', 'Wellington', 'West Coast']
},
{
  name: 'Nicaragua',
    alpha2Code: CountryAlpha2Code.NI,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '13',
  longitude: '-85',
  regions: ['Boaco', 'Carazo', 'Chinandega', 'Chontales', 'Esteli', 'Jinotega', 'Leon', 'Madriz', 'Managua', 'Masaya', 'Matagalpa', 'Nueva Segovia', 'Rio San Juan', 'Rivas', 'Atlantico Norte', 'Atlantico Sur']
},
{
  name: 'Niger',
    alpha2Code: CountryAlpha2Code.NE,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '16',
  longitude: '8',
  regions: ['Niamey', 'Agadez', 'Diffa', 'Dosso', 'Maradi', 'Tahoua', 'Tillaberi', 'Zinder']
},
{
  name: 'Nigeria',
    alpha2Code: CountryAlpha2Code.NG,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '10',
  longitude: '8',
  regions: ['Abuja Capital Territory', 'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos', 'Nassarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara']
},
{
  name: 'Niue',
    alpha2Code: CountryAlpha2Code.NU,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-19.03333333',
  longitude: '-169.86666666',
  regions: ['Niue']
},
{
  name: 'Norfolk Island',
    alpha2Code: CountryAlpha2Code.NF,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-29.03333333',
  longitude: '167.95',
  regions: ['Norfolk Island']
},
{
  name: 'Korea (Democratic People s Republic of)',
    alpha2Code: CountryAlpha2Code.KP,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '40',
  longitude: '127',
  regions: ['Kaesong-si', 'Nampo-si', 'Pyongyang-ai', 'Chagang-do', 'Hamgyongbuk-do', 'Hamgyongnam-do', 'Hwanghaebuk-do', 'Hwanghaenam-do', 'Kangwon-do', 'Pyonganbuk-do', 'Pyongannam-do', 'Yanggang-do', 'Najin Sonbong-si']
},
{
  name: 'Northern Mariana Islands',
    alpha2Code: CountryAlpha2Code.MP,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '15.2',
  longitude: '145.75',
  regions: ['Northern Mariana Islands']
},
{
  name: 'Norway',
    alpha2Code: CountryAlpha2Code.NO,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.SCANDINAVIA_BALTICS,
  constructionCluster: ConstructionCluster.SCANDINAVIA,
  opCo: OpCo.OPEA,
  latitude: '63',
  longitude: '10',
  regions: ['Akershus', 'Aust-Agder', 'Buskerud', 'Finumark', 'Hedmark', 'Hordaland', 'Mire og Romsdal', 'Nordland', 'Nord-Trindelag', 'Oppland', 'Oslo', 'Rogaland', 'Sogn og Fjordane', 'Sir-Trindelag', 'Telemark', 'Troms', 'Vest-Agder', 'Vestfold', 'Ostfold', 'Jan Mayen', 'Svalbard']
},
{
  name: 'Oman',
    alpha2Code: CountryAlpha2Code.OM,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '21',
  longitude: '57',
  regions: ['Ad Dakhillyah', 'Al Batinah', 'Al Janblyah', 'Al Wusta', 'Ash Sharqlyah', 'Az Zahirah', 'Masqat', 'Musandam']
},
{
  name: 'Pakistan',
    alpha2Code: CountryAlpha2Code.PK,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '30',
  longitude: '70',
  regions: ['Islamabad', 'Baluchistan (en)', 'North-West Frontier', 'Sind (en)', 'Federally Administered Tribal Aresa', 'Azad Rashmir', 'Northern Areas']
},
{
  name: 'Palau',
    alpha2Code: CountryAlpha2Code.PW,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '7.5',
  longitude: '134.5',
  regions: ['Palau']
},
{
  name: 'Palestine, State of',
    alpha2Code: CountryAlpha2Code.PS,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '31.9',
  longitude: '35.2',
  regions: ['Palestine, State of']
},
{
  name: 'Panama',
    alpha2Code: CountryAlpha2Code.PA,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '9',
  longitude: '-80',
  regions: ['Bocas del Toro', 'Cocle', 'Chiriqui', 'Darien', 'Herrera', 'Loa Santoa', 'Panama', 'Veraguas', 'Comarca de San Blas']
},
{
  name: 'Papua New Guinea',
    alpha2Code: CountryAlpha2Code.PG,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-6',
  longitude: '147',
  regions: ['National Capital District (Port Moresby)', 'Chimbu', 'Eastern Highlands', 'East New Britain', 'East Sepik', 'Enga', 'Gulf', 'Madang', 'Manus', 'Milne Bay', 'Morobe', 'New Ireland', 'North Solomons', 'Santaun', 'Southern Highlands', 'Western Highlands', 'West New Britain']
},
{
  name: 'Paraguay',
    alpha2Code: CountryAlpha2Code.PY,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-23',
  longitude: '-58',
  regions: ['Asuncion', 'Alto Paraguay', 'Alto Parana', 'Amambay', 'Boqueron', 'Caeguazu', 'Caazapl', 'Canindeyu', 'Concepcion', 'Cordillera', 'Guaira', 'Itapua', 'Miaiones', 'Neembucu', 'Paraguari', 'Presidente Hayes', 'San Pedro']
},
{
  name: 'Peru',
    alpha2Code: CountryAlpha2Code.PE,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-10',
  longitude: '-76',
  regions: ['El Callao', 'Ancash', 'Apurimac', 'Arequipa', 'Ayacucho', 'Cajamarca', 'Cuzco', 'Huancavelica', 'Huanuco', 'Ica', 'Junin', 'La Libertad', 'Lambayeque', 'Lima', 'Loreto', 'Madre de Dios', 'Moquegua', 'Pasco', 'Piura', 'Puno', 'San Martin', 'Tacna', 'Tumbes', 'Ucayali']
},
{
  name: 'Philippines',
    alpha2Code: CountryAlpha2Code.PH,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '13',
  longitude: '122',
  regions: ['Abra', 'Agusan del Norte', 'Agusan del Sur', 'Aklan', 'Albay', 'Antique', 'Apayao', 'Aurora', 'Basilan', 'Batasn', 'Batanes', 'Batangas', 'Benguet', 'Biliran', 'Bohol', 'Bukidnon', 'Bulacan', 'Cagayan', 'Camarines Norte', 'Camarines Sur', 'Camiguin', 'Capiz', 'Catanduanes', 'Cavite', 'Cebu', 'Compostela Valley', 'Davao', 'Davao del Sur', 'Davao Oriental', 'Eastern Samar', 'Guimaras', 'Ifugao', 'Ilocos Norte', 'Ilocos Sur', 'Iloilo', 'Isabela', 'Kalinga-Apayso', 'Laguna', 'Lanao del Norte', 'Lanao del Sur', 'La Union', 'Leyte', 'Maguindanao', 'Marinduque', 'Masbate', 'Mindoro Occidental', 'Mindoro Oriental', 'Misamis Occidental', 'Misamis Oriental', 'Mountain Province', 'Negroe Occidental', 'Negros Oriental', 'North Cotabato', 'Northern Samar', 'Nueva Ecija', 'Nueva Vizcaya', 'Palawan', 'Pampanga', 'Pangasinan', 'Quezon', 'Quirino', 'Rizal', 'Romblon', 'Sarangani', 'Siquijor', 'Sorsogon', 'South Cotabato', 'Southern Leyte', 'Sultan Kudarat', 'Sulu', 'Surigao del Norte', 'Surigao del Sur', 'Tarlac', 'Tawi-Tawi', 'Western Samar', 'Zambales', 'Zamboanga del Norte', 'Zamboanga del Sur', 'Zamboanga Sibiguey']
},
{
  name: 'Pitcairn',
    alpha2Code: CountryAlpha2Code.PN,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-25.06666666',
  longitude: '-130.1',
  regions: ['Pitcairn']
},
{
  name: 'Poland',
    alpha2Code: CountryAlpha2Code.PL,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.SCANDINAVIA_BALTICS,
  constructionCluster: ConstructionCluster.POLAND,
  opCo: OpCo.OPEA,
  latitude: '52',
  longitude: '20',
  regions: ['mazowieckie', 'pomorskie', 'dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie',
  'małopolskie', 'opolskie', 'podkarpackie', 'podlaskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie']
},
{
  name: 'Portugal',
    alpha2Code: CountryAlpha2Code.PT,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.ITALY_GREECE_IBERIA,
  opCo: OpCo.OPEA,
  latitude: '39',
  longitude: '-8',
  regions: ['Aveiro', 'Beja', 'Braga', 'Braganca', 'Castelo Branco', 'Colmbra', 'Ovora', 'Faro', 'Guarda', 'Leiria', 'Lisboa', 'Portalegre', 'Porto', 'Santarem', 'Setubal', 'Viana do Castelo', 'Vila Real', 'Viseu', 'Regiao Autonoma dos Acores', 'Regiao Autonoma da Madeira']
},
{
  name: 'Puerto Rico',
    alpha2Code: CountryAlpha2Code.PR,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '18.25',
  longitude: '-66.5',
  regions: ['Puerto Rico']
},
{
  name: 'Qatar',
    alpha2Code: CountryAlpha2Code.QA,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '25.5',
  longitude: '51.25',
  regions: ['Ad Dawhah', 'Al Ghuwayriyah', 'Al Jumayliyah', 'Al Khawr', 'Al Wakrah', 'Ar Rayyan', 'Jariyan al Batnah', 'Madinat ash Shamal', 'Umm Salal']
},
{
  name: 'Republic of Kosovo',
    alpha2Code: CountryAlpha2Code.XK,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '42.666667',
  longitude: '21.166667',
  regions: ['Republic of Kosovo']
},
{
  name: 'Réunion',
    alpha2Code: CountryAlpha2Code.RE,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-21.15',
  longitude: '55.5',
  regions: ['Réunion']
},
{
  name: 'Romania',
    alpha2Code: CountryAlpha2Code.RO,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '46',
  longitude: '25',
  regions: ['Bucuresti', 'Alba', 'Arad', 'Arges', 'Bacau', 'Bihor', 'Bistrita-Nasaud', 'Boto\'ani', 'Bra\'ov', 'Braila', 'Buzau', 'Caras-Severin', 'Ca la ras\'i', 'Cluj', 'Constant\'a', 'Covasna', 'Dambovit\'a', 'Dolj', 'Galat\'i', 'Giurgiu', 'Gorj', 'Harghita', 'Hunedoara', 'Ialomit\'a', 'Ias\'i', 'Ilfov', 'Maramures', 'Mehedint\'i', 'Mures', 'Neamt', 'Olt', 'Prahova', 'Satu Mare', 'Sa laj', 'Sibiu', 'Suceava', 'Teleorman', 'Timis', 'Tulcea', 'Vaslui', 'Valcea', 'Vrancea']
},
{
  name: 'Russian Federation',
    alpha2Code: CountryAlpha2Code.RU,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '60',
  longitude: '100',
  regions: ['Adygeya, Respublika', 'Altay, Respublika', 'Bashkortostan, Respublika', 'Buryatiya, Respublika',
    'Chechenskaya Respublika', 'Chuvashskaya Respublika', 'Dagestan, Respublika', 'Ingushskaya Respublika',
    'Kabardino-Balkarskaya', 'Kalmykiya, Respublika', 'Karachayevo-Cherkesskaya Respublika', 'Kareliya, Respublika',
    'Khakasiya, Respublika', 'Komi, Respublika', 'Mariy El, Respublika', 'Mordoviya, Respublika',
    'Sakha, Respublika [Yakutiya]', 'Severnaya Osetiya, Respublika', 'Tatarstan, Respublika',
    'Tyva, Respublika [Tuva]', 'Udmurtskaya Respublika', 'Altayskiy kray', 'Khabarovskiy kray',
    'Krasnodarskiy kray', 'Krasnoyarskiy kray', 'Primorskiy kray', 'Stavropol\'skiy kray', 'Amurskaya oblast\'',
    'Arkhangel\'skaya oblast', 'Astrakhanskaya oblast\'', 'Belgorodskaya oblast\'', 'Bryanskaya oblast\'',
    'Chelyabinskaya oblast\'', 'Chitinskaya oblast\'', 'Irkutskaya oblast\'', 'Ivanovskaya oblast\'',
    'Kaliningradskaya oblast\'', 'Kaluzhskaya oblast\'', 'Kamchatskaya oblast\'', 'Kemerovskaya oblast\'',
    'Kirovskaya oblast\'', 'Kostromskaya oblast\'', 'Kurganskaya oblast\'', 'Kurskaya oblast\'',
    'Leningradskaya oblast\'', 'Lipetskaya oblast\'', 'Magadanskaya oblast\'', 'Moskovskaya oblast\'',
    'Murmanskaya oblast\'', 'Nizhegorodskaya oblast\'', 'Novgorodskaya oblast\'', 'Novosibirskaya oblast\'',
    'Omskaya oblast\'', 'Orenburgskaya oblast\'', 'Orlovskaya oblast\'', 'Penzenskaya oblast\'',
    'Permskaya oblast\'', 'Pskovskaya oblast\'', 'Rostovskaya oblast\'', 'Ryazanskaya oblast\'',
    'Sakhalinskaya oblast\'', 'Samarskaya oblast\'', 'Saratovskaya oblast\'', 'Smolenskaya oblast\'',
    'Sverdlovskaya oblast\'', 'Tambovskaya oblast\'', 'Tomskaya oblast\'', 'Tul\'skaya oblast', 'Tverskaya oblast\'',
    'Tyumenskaya oblast\'', 'Ul\'yanovskaya oblast', 'Vladimirskaya oblast\'', 'Volgogradskaya oblast\'',
    'Vologodskaya oblast\'', 'Voronezhskaya oblast\'', 'Yaroslavskaya oblast\'', 'Moskva', 'Sankt-Peterburg',
    'Yevreyskaya avtonomnaya oblast\'', 'Aginskiy Buryatskiy avtonomnyy', 'Chukotskiy avtonomnyy okrug',
    'Evenkiyskiy avtonomnyy okrug', 'Khanty-Mansiyskiy avtonomnyy okrug', 'Komi-Permyatskiy avtonomnyy okrug',
    'Koryakskiy avtonomnyy okrug', 'Nenetskiy avtonomnyy okrug', 'Taymyrskiy (Dolgano-Nenetskiy)',
    'Ust\'-Ordynskiy Buryatskiy', 'Yamalo-Nenetskiy avtonomnyy okrug'],
},
{
  name: 'Rwanda',
    alpha2Code: CountryAlpha2Code.RW,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-2',
  longitude: '30',
  regions: ['Butare', 'Byumba', 'Cyangugu', 'Gikongoro', 'Gisenyi', 'Gitarama', 'Kibungo', 'Kibuye', 'Kigali-Rural Kigali y\' Icyaro', 'Kigali-Ville Kigali Ngari', 'Mutara', 'Ruhengeri']
},
{
  name: 'Saint Barthélemy',
    alpha2Code: CountryAlpha2Code.BL,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '18.5',
  longitude: '-63.41666666',
  regions: ['Saint Barthélemy']
},
{
  name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2Code: CountryAlpha2Code.SH,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-15.95',
  longitude: '-5.7',
  regions: ['Saint Helena, Ascension and Tristan da Cunha']
},
{
  name: 'Saint Kitts and Nevis',
    alpha2Code: CountryAlpha2Code.KN,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '17.33333333',
  longitude: '-62.75',
  regions: ['Saint Kitts and Nevis']
},
{
  name: 'Saint Lucia',
    alpha2Code: CountryAlpha2Code.LC,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '13.88333333',
  longitude: '-60.96666666',
  regions: ['Saint Lucia']
},
{
  name: 'Saint Martin (French part)',
    alpha2Code: CountryAlpha2Code.MF,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '18.08333333',
  longitude: '-63.95',
  regions: ['Saint Martin (French part)']
},
{
  name: 'Saint Pierre and Miquelon',
    alpha2Code: CountryAlpha2Code.PM,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '46.83333333',
  longitude: '-56.33333333',
  regions: ['Saint Pierre and Miquelon']
},
{
  name: 'Saint Vincent and the Grenadines',
    alpha2Code: CountryAlpha2Code.VC,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '13.25',
  longitude: '-61.2',
  regions: ['Saint Vincent and the Grenadines']
},
{
  name: 'Samoa',
    alpha2Code: CountryAlpha2Code.WS,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-13.58333333',
  longitude: '-172.33333333',
  regions: ['A\'ana', 'Aiga-i-le-Tai', 'Atua', 'Fa\'aaaleleaga', 'Gaga\'emauga', 'Gagaifomauga', 'Palauli', 'Satupa\'itea', 'Tuamasaga', 'Va\'a-o-Fonoti', 'Vaisigano']
},
{
  name: 'San Marino',
    alpha2Code: CountryAlpha2Code.SM,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '43.76666666',
  longitude: '12.41666666',
  regions: ['San Marino']
},
{
  name: 'Sao Tome and Principe',
    alpha2Code: CountryAlpha2Code.ST,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '1',
  longitude: '7',
  regions: ['Principe', 'Sao Tome']
},
{
  name: 'Saudi Arabia',
    alpha2Code: CountryAlpha2Code.SA,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '25',
  longitude: '45',
  regions: ['Al Batah', 'Al H,udd ash Shamallyah', 'Al Jawf', 'Al Madinah', 'Al Qasim', 'Ar Riyad', 'Asir', 'Ha\'il', 'Jlzan', 'Makkah', 'Najran', 'Tabuk']
},
{
  name: 'Senegal',
    alpha2Code: CountryAlpha2Code.SN,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '14',
  longitude: '-14',
  regions: ['Dakar', 'Diourbel', 'Fatick', 'Kaolack', 'Kolda', 'Louga', 'Matam', 'Saint-Louis', 'Tambacounda', 'Thies', 'Ziguinchor']
},
{
  name: 'Serbia',
    alpha2Code: CountryAlpha2Code.RS,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '44',
  longitude: '21',
  regions: ['Serbia']
},
{
  name: 'Seychelles',
    alpha2Code: CountryAlpha2Code.SC,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-4.58333333',
  longitude: '55.66666666',
  regions: ['Seychelles']
},
{
  name: 'Sierra Leone',
    alpha2Code: CountryAlpha2Code.SL,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '8.5',
  longitude: '-11.5',
  regions: ['Western Area (Freetown)']
},
{
  name: 'Singapore',
    alpha2Code: CountryAlpha2Code.SG,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '1.36666666',
  longitude: '103.8',
  regions: ['Singapore']
},
{
  name: 'Sint Maarten (Dutch part)',
    alpha2Code: CountryAlpha2Code.SX,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '18.033333',
  longitude: '-63.05',
  regions: ['Sint Maarten (Dutch part)']
},
{
  name: 'Slovakia',
    alpha2Code: CountryAlpha2Code.SK,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '48.66666666',
  longitude: '19.5',
  regions: ['Banskobystrický kraj', 'Bratislavský kraj', 'Košický kraj', 'Nitriansky kraj', 'Prešovský kraj', 'Trenčiansky kraj', 'Trnavský kraj', 'Žilinský kraj']
},
{
  name: 'Slovenia',
    alpha2Code: CountryAlpha2Code.SI,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '46.11666666',
  longitude: '14.81666666',
  regions: ['Ajdovscina', 'Beltinci', 'Benedikt', 'Bistrica ob Sotli', 'Bled', 'Bloke', 'Bohinj', 'Borovnica', 'Bovec', 'Braslovce', 'Brda', 'Brezovica', 'Brezica', 'Cankova', 'Celje', 'Cerklje na Gorenjskem', 'Cerknica', 'Cerkno', 'Cerkvenjak', 'Crensovci', 'Crna na Koroskem', 'Crnomelj', 'Destrnik', 'Divaca', 'Dobje', 'Dobrepolje', 'Dobrna', 'Dobrova-Polhov Gradec', 'Dobrovnik', 'Dol pri Ljubljani', 'Dolenjske Toplice', 'Domzale', 'Dornava', 'Dravograd', 'Duplek', 'Gorenja vas-Poljane', 'Gorsnica', 'Gornja Radgona', 'Gornji Grad', 'Gornji Petrovci', 'Grad', 'Grosuplje', 'Hajdina', 'Hoce-Slivnica', 'Hodos', 'Jorjul', 'Hrastnik', 'Hrpelje-Kozina', 'Idrija', 'Ig', 'IIrska Bistrica', 'Ivancna Gorica', 'Izola', 'Jesenice', 'Jezersko', 'Jursinci', 'Kamnik', 'Kanal', 'Kidricevo', 'Kobarid', 'Kobilje', 'Jovevje', 'Komen', 'Komenda', 'Koper', 'Kostel', 'Kozje', 'Kranj', 'Kranjska Gora', 'Krizevci', 'Krsko', 'Kungota', 'Kuzma', 'Lasko', 'Lenart', 'Lendava', 'Litija', 'Ljubljana', 'Ljubno', 'Ljutomer', 'Logatec', 'Loska dolina', 'Loski Potok', 'Lovrenc na Pohorju', 'Luce', 'Lukovica', 'Majsperk', 'Maribor', 'Markovci', 'Medvode', 'Menges', 'Metlika', 'Mezica', 'Miklavz na Dravskern polju', 'Miren-Kostanjevica', 'Mirna Pec', 'Mislinja', 'Moravce', 'Moravske Toplice', 'Mozirje', 'Murska Sobota', 'Muta',
  'Naklo', 'Nazarje', 'Nova Gorica', 'Nova mesto', 'Sveta Ana', 'Sveti Andraz v Slovenskih goricah', 'Sveti Jurij', 'Salovci', 'Sempeter-Vrtojba', 'Sencur', 'Sentilj', 'Sentjernej', 'Sentjur pri Celju', 'Skocjan', 'Skofja Loka', 'Skoftjica',
  'Smarje pri Jelsah', 'Smartno ob Paki', 'Smartno pri Litiji', 'Sostanj', 'Store', 'Tabor', 'Tisina', 'Tolmin', 'Trbovje', 'Trebnje', 'Trnovska vas', 'Trzic', 'Trzin', 'Turnisce', 'Velenje', 'Velika Polana', 'Velika Lasce', 'Verzej', 'Videm', 'Vipava', 'Vitanje', 'Vojnik', 'Vransko', 'Vrhnika', 'Vuzenica', 'Zagorje ob Savi', 'Zavrc', 'Zrece', 'Zalec', 'Zelezniki', 'Zetale', 'Ziri', 'Zirovnica', 'Zuzemberk']
},
{
  name: 'Solomon Islands',
    alpha2Code: CountryAlpha2Code.SB,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-8',
  longitude: '159',
  regions: ['Capital Territory (Honiara)', 'Guadalcanal', 'Isabel', 'Makira', 'Malaita', 'Temotu']
},
{
  name: 'Somalia',
    alpha2Code: CountryAlpha2Code.SO,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '10',
  longitude: '49',
  regions: ['Awdal', 'Bakool', 'Banaadir', 'Bay', 'Galguduud', 'Gedo', 'Hiirsan', 'Jubbada Dhexe', 'Jubbada Hoose', 'Mudug', 'Nugaal', 'Saneag', 'Shabeellaha Dhexe', 'Shabeellaha Hoose', 'Sool', 'Togdheer', 'Woqooyi Galbeed']
},
{
  name: 'South Africa',
    alpha2Code: CountryAlpha2Code.ZA,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-29',
  longitude: '24',
  regions: ['Eastern Cape', 'Free State', 'Gauteng', 'Kwazulu-Natal', 'Mpumalanga', 'Northern Cape', 'Limpopo', 'Western Cape', 'North West']
},
{
  name: 'South Georgia and the South Sandwich Islands',
    alpha2Code: CountryAlpha2Code.GS,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-54.5',
  longitude: '-37',
  regions: ['South Georgia and the South Sandwich Islands']
},
{
  name: 'Korea (Republic of)',
    alpha2Code: CountryAlpha2Code.KR,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '37',
  longitude: '127.5',
  regions: ['Seoul Teugbyeolsi', 'Busan Gwang\'yeogsi', 'Daegu Gwang\'yeogsi', 'Daejeon Gwang\'yeogsi', 'Gwangju Gwang\'yeogsi', 'Incheon Gwang\'yeogsi', 'Ulsan Gwang\'yeogsi', 'Chungcheongbugdo', 'Chungcheongnamdo', 'Gang\'weondo', 'Gyeonggido', 'Gyeongsangbugdo', 'Gyeongsangnamdo', 'Jejudo', 'Jeonrabugdo', 'Jeonranamdo']
},
{
  name: 'South Sudan',
    alpha2Code: CountryAlpha2Code.SS,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '7',
  longitude: '30',
  regions: ['South Sudan']
},
{
  name: 'Spain',
    alpha2Code: CountryAlpha2Code.ES,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.ITALY_IBERIA,
  constructionCluster: ConstructionCluster.ITALY_GREECE_IBERIA,
  opCo: OpCo.OPEA,
  latitude: '40.5',
  longitude: '-4',
  regions: ['Álava', 'Albacete', 'Alicante', 'Almería', 'Asturias', 'Ávila', 'Badajoz', 'Baleares', 'Barcelona', 'Burgos', 'Cáceres', 'Cádiz', 'Cantabria', 'Castellón', 'Ciudad Real', 'Cuenca', 'Girona [Gerona]', 'Granada', 'Guadalajara', 'Guipúzcoa', 'Huelva', 'Huesca', 'Jaén', 'La Coruña', 'La Rioja', 'Las Palmas', 'León', 'Lleida [Lérida]', 'Lugo',
  'Madrid', 'Málaga', 'Murcia', 'Navarra', 'Ourense', 'Palencia', 'Pontevedra', 'Salamanca', 'Santa Cruz de Tenerife', 'Segovia', 'Sevilla', 'Soria', 'Tarragona', 'Teruel', 'Valencia', 'Valladolid', 'Vizcaya', 'Zamora', 'Zaragoza', 'Ceuta', 'Melilla']
},
{
  name: 'Sri Lanka',
    alpha2Code: CountryAlpha2Code.LK,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '7',
  longitude: '81',
  regions: ['Ampara', 'Anuradhapura', 'Badulla', 'Batticaloa', 'Colombo', 'Galle', 'Gampaha', 'Hambantota', 'Jaffna', 'Kalutara', 'Kandy', 'Kegalla', 'Kilinochchi', 'Kurunegala', 'Mannar', 'Matale', 'Matara', 'Monaragala', 'Mullaittivu', 'Nuwara Eliya', 'Polonnaruwa', 'Puttalum', 'Ratnapura', 'Trincomalee', 'VavunLya']
},
{
  name: 'Sudan',
    alpha2Code: CountryAlpha2Code.SD,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '15',
  longitude: '30',
  regions: ['A\'ali an Nil', 'Al Bah al Ahmar', 'Al Buhayrat', 'Al Jazirah', 'Al Khartum', 'Al Qadarif', 'Al Wahdah', 'An Nil', 'An Nil al Abyaq', 'An Nil al Azraq', 'Ash Shamallyah', 'Bahr al Jabal', 'Gharb al Istiwa\'iyah', 'Gharb Ba~r al Ghazal', 'Gharb Darfur', 'Gharb Kurdufan', 'Janub Darfur', 'Janub Rurdufan', 'Jnqall', 'Kassala', 'Shamal Batr al Ghazal', 'Shamal Darfur', 'Shamal Kurdufan', 'Sharq al Istiwa\'iyah', 'Sinnar', 'Warab']
},
{
  name: 'Suriname',
    alpha2Code: CountryAlpha2Code.SR,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '4',
  longitude: '-56',
  regions: ['Brokopondo', 'Commewijne', 'Coronie', 'Marowijne', 'Nickerie', 'Paramaribo', 'Saramacca', 'Sipaliwini', 'Wanica']
},
{
  name: 'Svalbard and Jan Mayen',
    alpha2Code: CountryAlpha2Code.SJ,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '78',
  longitude: '20',
  regions: ['Svalbard and Jan Mayen']
},
{
  name: 'Swaziland',
    alpha2Code: CountryAlpha2Code.SZ,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-26.5',
  longitude: '31.5',
  regions: ['Hhohho', 'Lubombo', 'Manzini', 'Shiselweni']
},
{
  name: 'Sweden',
    alpha2Code: CountryAlpha2Code.SE,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.SCANDINAVIA_BALTICS,
  constructionCluster: ConstructionCluster.SCANDINAVIA,
  opCo: OpCo.OPEA,
  latitude: '60.5',
  longitude: '15',
  regions: ['Blekinge lan', 'Dalarnas lan', 'Gotlands lan', 'Gavleborge lan', 'Hallands lan', 'Jamtlande lan', 'Jonkopings lan', 'Kalmar lan', 'Kronoberge lan', 'Norrbottena lan', 'Skane lan', 'Stockholms lan', 'Sodermanlands lan', 'Uppsala lan', 'Varmlanda lan', 'Vasterbottens lan', 'Vasternorrlands lan', 'Vastmanlanda lan', 'Vastra Gotalands lan', 'Orebro lan', 'Ostergotlands lan']
},
{
  name: 'Switzerland',
    alpha2Code: CountryAlpha2Code.CH,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '47',
  longitude: '8',
  regions: ['Aargau', 'Appenzell Innerrhoden', 'Appenzell Ausserrhoden', 'Bern', 'Basel-Landschaft', 'Basel-Stadt', 'Fribourg', 'Geneva', 'Glarus', 'Graubunden', 'Jura', 'Luzern', 'Neuchatel', 'Nidwalden', 'Obwalden', 'Sankt Gallen',
  'Schaffhausen', 'Solothurn', 'Schwyz', 'Thurgau', 'Ticino', 'Uri', 'Vaud', 'Valais', 'Zug', 'Zurich']
},
{
  name: 'Syrian Arab Republic',
    alpha2Code: CountryAlpha2Code.SY,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '35',
  longitude: '38',
  regions: ['Al Hasakah', 'Al Ladhiqiyah', 'Al Qunaytirah', 'Ar Raqqah', 'As Suwayda\'', 'Dar\'a', 'Dayr az Zawr', 'Dimashq', 'Halab', 'Hamah', 'Jim\'', 'Idlib', 'Rif Dimashq', 'Tarts']
},
{
  name: 'Taiwan',
    alpha2Code: CountryAlpha2Code.TW,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '23.5',
  longitude: '121',
  regions: ['Changhua', 'Chiayi', 'Hsinchu', 'Hualien', 'Ilan', 'Kaohsiung', 'Miaoli', 'Nantou', 'Penghu', 'Pingtung', 'Taichung', 'Tainan', 'Taipei', 'Taitung', 'Taoyuan', 'Yunlin', 'Keelung']
},
{
  name: 'Tajikistan',
    alpha2Code: CountryAlpha2Code.TJ,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '39',
  longitude: '71',
  regions: ['Sughd', 'Khatlon', 'Gorno-Badakhshan']
},
{
  name: 'Tanzania, United Republic of',
    alpha2Code: CountryAlpha2Code.TZ,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-6',
  longitude: '35',
  regions: ['Arusha', 'Dar-es-Salaam', 'Dodoma', 'Iringa', 'Kagera', 'Kaskazini Pemba', 'Kaskazini Unguja', 'Xigoma', 'Kilimanjaro', 'Rusini Pemba', 'Kusini Unguja', 'Lindi', 'Manyara', 'Mara', 'Mbeya', 'Mjini Magharibi', 'Morogoro',
  'Mtwara', 'Pwani', 'Rukwa', 'Ruvuma', 'Shinyanga', 'Singida', 'Tabora', 'Tanga']
},
{
  name: 'Thailand',
    alpha2Code: CountryAlpha2Code.TH,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '15',
  longitude: '100',
  regions: ['Krung Thep Maha Nakhon Bangkok', 'Phatthaya', 'Amnat Charoen', 'Ang Thong', 'Buri Ram', 'Chachoengsao', 'Chai Nat', 'Chaiyaphum', 'Chanthaburi', 'Chiang Mai', 'Chiang Rai', 'Chon Buri', 'Chumphon', 'Kalasin', 'Kamphasng Phet', 'Kanchanaburi', 'Khon Kaen', 'Krabi', 'Lampang', 'Lamphun', 'Loei', 'Lop Buri', 'Mae Hong Son', 'Maha Sarakham', 'Mukdahan', 'Nakhon Nayok', 'Nakhon Pathom', 'Nakhon Phanom', 'Nakhon Ratchasima', 'Nakhon Sawan', 'Nakhon Si Thammarat', 'Nan', 'Narathiwat', 'Nong Bua Lam Phu', 'Nong Khai', 'Nonthaburi', 'Pathum Thani', 'Pattani', 'Phangnga', 'Phatthalung', 'Phayao', 'Phetchabun', 'Phetchaburi', 'Phichit', 'Phitsanulok', 'Phrae', 'Phra Nakhon Si Ayutthaya', 'Phaket', 'Prachin Buri', 'Prachuap Khiri Khan', 'Ranong', 'Ratchaburi', 'Rayong', 'Roi Et', 'Sa Kaeo', 'Sakon Nakhon', 'Samut Prakan', 'Samut Sakhon', 'Samut Songkhram', 'Saraburi', 'Satun', 'Sing Buri', 'Si Sa Ket', 'Songkhla', 'Sukhothai', 'Suphan Buri', 'Surat Thani', 'Surin', 'Tak', 'Trang', 'Trat', 'Ubon Ratchathani', 'Udon Thani', 'Uthai Thani', 'Uttaradit', 'Yala', 'Yasothon']
},
{
  name: 'Timor-Leste',
    alpha2Code: CountryAlpha2Code.TL,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-8.83333333',
  longitude: '125.91666666',
  regions: ['Aileu', 'Ainaro', 'Bacucau', 'Bobonaro', 'Cova Lima', 'Dili', 'Ermera', 'Laulem', 'Liquica', 'Manatuto', 'Manafahi', 'Oecussi', 'Viqueque']
},
{
  name: 'Togo',
    alpha2Code: CountryAlpha2Code.TG,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '8',
  longitude: '1.16666666',
  regions: ['Kara', 'Maritime (Region)', 'Savannes']
},
{
  name: 'Tokelau',
    alpha2Code: CountryAlpha2Code.TK,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-9',
  longitude: '-172',
  regions: ['Tokelau']
},
{
  name: 'Tonga',
    alpha2Code: CountryAlpha2Code.TO,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-20',
  longitude: '-175',
  regions: ['Tonga']
},
{
  name: 'Trinidad and Tobago',
    alpha2Code: CountryAlpha2Code.TT,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '11',
  longitude: '-61',
  regions: ['Couva-Tabaquite-Talparo', 'Diego Martin', 'Eastern Tobago', 'Penal-Debe', 'Princes Town', 'Rio Claro-Mayaro', 'Sangre Grande', 'San Juan-Laventille', 'Siparia', 'Tunapuna-Piarco', 'Western Tobago', 'Arima', 'Chaguanas', 'Point Fortin', 'Port of Spain', 'San Fernando']
},
{
  name: 'Tunisia',
    alpha2Code: CountryAlpha2Code.TN,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '34',
  longitude: '9',
  regions: ['Béja', 'Ben Arous', 'Bizerte', 'Gabès', 'Gafsa', 'Jendouba', 'Kairouan', 'Rasserine', 'Kebili', 'L\'Ariana', 'Le Ref', 'Mahdia', 'La Manouba', 'Medenine', 'Moneatir', 'Naboul', 'Sfax', 'Sidi Bouxid', 'Siliana', 'Sousse',
  'Tataouine', 'Tozeur', 'Tunis', 'Zaghouan']
},
{
  name: 'Turkey',
    alpha2Code: CountryAlpha2Code.TR,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '39',
  longitude: '35',
  regions: ['Adana', 'Ad yaman', 'Afyon', 'Ag r', 'Aksaray', 'Amasya', 'Ankara', 'Antalya', 'Ardahan', 'Artvin', 'Aydin', 'Bal kesir', 'Bartin', 'Batman', 'Bayburt', 'Bilecik', 'Bingol', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Canakkale', 'Cankir', 'Corum', 'Denizli', 'Diyarbakir', 'Duzce', 'Edirne', 'Elazig', 'Erzincan', 'Erzurum', 'Eskis\'ehir', 'Gaziantep', 'Giresun', 'Gms\'hane', 'Hakkari', 'Hatay', 'Igidir', 'Isparta', 'Icel', 'Istanbul', 'Izmir', 'Kahramanmaras', 'Karabk', 'Karaman', 'Kars', 'Kastamonu', 'Kayseri', 'Kirikkale', 'Kirklareli', 'Kirs\'ehir', 'Kilis', 'Kocaeli', 'Konya', 'Ktahya', 'Malatya', 'Manisa', 'Mardin', 'Mugila', 'Mus', 'Nevs\'ehir', 'Nigide', 'Ordu', 'Osmaniye', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop', 'Sivas', 'S\'anliurfa', 'S\'rnak', 'Tekirdag', 'Tokat', 'Trabzon', 'Tunceli', 'Us\'ak', 'Van', 'Yalova', 'Yozgat', 'Zonguldak']
},
{
  name: 'Turkmenistan',
    alpha2Code: CountryAlpha2Code.TM,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '40',
  longitude: '60',
  regions: ['Ahal', 'Balkan', 'Dasoguz', 'Lebap', 'Mary']
},
{
  name: 'Turks and Caicos Islands',
    alpha2Code: CountryAlpha2Code.TC,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '21.75',
  longitude: '-71.58333333',
  regions: ['Turks and Caicos Islands']
},
{
  name: 'Tuvalu',
    alpha2Code: CountryAlpha2Code.TV,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-8',
  longitude: '178',
  regions: ['Tuvalu']
},
{
  name: 'Uganda',
    alpha2Code: CountryAlpha2Code.UG,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '1',
  longitude: '32',
  regions: ['Adjumani', 'Apac', 'Arua', 'Bugiri', 'Bundibugyo', 'Bushenyi', 'Busia', 'Gulu', 'Hoima', 'Iganga', 'Jinja', 'Kabale', 'Kabarole', 'Kaberamaido', 'Kalangala', 'Kampala', 'Kamuli', 'Kamwenge', 'Kanungu', 'Kapchorwa', 'Kasese', 'Katakwi', 'Kayunga', 'Kibaale', 'Kiboga', 'Kisoro', 'Kitgum', 'Kotido', 'Kumi', 'Kyenjojo', 'Lira', 'Luwero', 'Masaka', 'Masindi', 'Mayuge', 'Mbale', 'Mbarara', 'Moroto', 'Moyo', 'Mpigi', 'Mubende', 'Mukono', 'Nakapiripirit', 'Nakasongola', 'Nebbi', 'Ntungamo', 'Pader', 'Pallisa', 'Rakai', 'Rukungiri', 'Sembabule', 'Sironko', 'Soroti', 'Tororo', 'Wakiso', 'Yumbe']
},
{
  name: 'Ukraine',
    alpha2Code: CountryAlpha2Code.UA,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '49',
  longitude: '32',
  regions: ['Cherkas\'ka Oblast', 'Chernihivs\'ka Oblast', 'Chernivets\'ka Oblast', 'Dnipropetrovs\'ka Oblast',
    'Donets\'ka Oblast', 'Ivano-Frankivs\'ka Oblast', 'Kharkivs\'ka Oblast', 'Khersons\'ka Oblast',
    'Khmel\'nyts\'ka Oblast', 'Kirovohrads\'ka Oblast', 'Kyivs\'ka Oblast', 'Luhans\'ka Oblast',
    'L\'vivs\'ka Oblast', 'Mykolaivs\'ka Oblast', 'Odes \'ka Oblast', 'Poltavs\'ka Oblast', 'Rivnens\'ka Oblast',
    'Sums \'ka Oblast', 'Ternopil\'s\'ka Oblast', 'Vinnyts\'ka Oblast', 'Volyos\'ka Oblast', 'Zakarpats\'ka Oblast',
    'Zaporiz\'ka Oblast', 'Zhytomyrs\'ka Oblast', 'Respublika Krym', 'Kyiv', 'Sevastopol']
},
{
  name: 'United Arab Emirates',
    alpha2Code: CountryAlpha2Code.AE,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '24',
  longitude: '54',
  regions: ['Abu Zaby', '\'Ajman', 'Al Fujayrah', 'Ash Shariqah', 'Dubayy', 'Ra\'s al Khaymah', 'Umm al Qaywayn']
},
{
  name: 'United Kingdom',
    alpha2Code: CountryAlpha2Code.GB,
  continent: Continent.EUROPE,
  globalRegion: GlobalRegion.EU,
  developmentCluster: DevelopmentCluster.UK_IRELAND,
  constructionCluster: ConstructionCluster.UK_IRELAND,
  opCo: OpCo.OPEA,
  latitude: '54',
  longitude: '-2',
  regions: ['England', 'Scotland', 'Wales', 'Northern Ireland']
},
{
  name: 'United States of America',
    alpha2Code: CountryAlpha2Code.US,
  continent: Continent.NORTH_AMERICA,
  globalRegion: GlobalRegion.NA,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '38',
  longitude: '-97',
  regions: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
  'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
  'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
  'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
  'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
  'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
  'District of Columbia', 'American Samoa', 'Guam', 'Northern Mariana Islands', 'Puerto Rico', 'Virgin Islands',
  'United States Minor Outlying Islands', 'Armed Forces Europe', 'Armed Forces Americas', 'Armed Forces Pacific']
},
{
  name: 'Uruguay',
    alpha2Code: CountryAlpha2Code.UY,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '-33',
  longitude: '-56',
  regions: ['Artigsa', 'Canelones', 'Cerro Largo', 'Colonia', 'Durazno', 'Flores', 'Lavalleja', 'Maldonado',
  'Montevideo', 'Paysandu', 'Rivera', 'Rocha', 'Salto', 'Soriano', 'Tacuarembo', 'Treinta y Tres']
},
{
  name: 'Uzbekistan',
    alpha2Code: CountryAlpha2Code.UZ,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '41',
  longitude: '64',
  regions: ['Toshkent (city)', 'Qoraqalpogiston Respublikasi', 'Andijon', 'Buxoro', 'Farg\'ona', 'Jizzax',
    'Khorazm', 'Namangan', 'Navoiy', 'Qashqadaryo', 'Samarqand', 'Sirdaryo', 'Surxondaryo', 'Toshkent', 'Xorazm']
},
{
  name: 'Vanuatu',
    alpha2Code: CountryAlpha2Code.VU,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-16',
  longitude: '167',
  regions: ['Malampa', 'Penama', 'Sanma', 'Shefa', 'Tafea', 'Torba']
},
{
  name: 'Venezuela (Bolivarian Republic of)',
    alpha2Code: CountryAlpha2Code.VE,
  continent: Continent.SOUTH_AMERICA,
  globalRegion: GlobalRegion.LATAM,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPAM,
  latitude: '8',
  longitude: '-66',
  regions: ['Diatrito Federal', 'Anzoategui', 'Apure', 'Aragua', 'Barinas', 'Carabobo', 'Cojedes', 'Falcon', 'Guarico',
    'Lara', 'Merida', 'Miranda', 'Monagas', 'Nueva Esparta', 'Portuguesa', 'Tachira', 'Trujillo', 'Vargas', 'Yaracuy',
    'Zulia', 'Delta Amacuro', 'Dependencias Federales']
},
{
  name: 'Viet Nam',
    alpha2Code: CountryAlpha2Code.VN,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '16.16666666',
  longitude: '107.83333333',
  regions: ['Dac Lac', 'An Giang', 'Ba Ria - Vung Tau', 'Bac Can', 'Bac Giang', 'Bac Lieu', 'Bac Ninh', 'Ben Tre',
    'Binh Dinh', 'Binh Duong', 'Binh Phuoc', 'Binh Thuan', 'Ca Mau', 'Can Tho', 'Cao Bang', 'Da Nang, thanh pho',
    'Dong Nai', 'Dong Thap', 'Gia Lai', 'Ha Giang', 'Ha Nam', 'Ha Noi, thu do', 'Ha Tay', 'Ha Tinh', 'Hai Duong',
    'Hai Phong, thanh pho', 'Hoa Binh', 'Ho Chi Minh, thanh pho [Sai Gon]', 'Hung Yen', 'Khanh Hoa', 'Kien Giang',
    'Kon Tum', 'Lai Chau', 'Lam Dong', 'Lang Son', 'Lao Cai', 'Long An', 'Nam Dinh', 'Nghe An', 'Ninh Binh',
    'Ninh Thuan', 'Phu Tho', 'Phu Yen', 'Quang Binh', 'Quang Nam', 'Quang Ngai', 'Quang Ninh', 'Quang Tri',
    'Soc Trang', 'Son La', 'Tay Ninh', 'Thai Binh', 'Thai Nguyen', 'Thanh Hoa', 'Thua Thien-Hue', 'Tien Giang',
    'Tra Vinh', 'Tuyen Quang', 'Vinh Long', 'Vinh Phuc', 'Yen Bai']
},
{
  name: 'Wallis and Futuna',
    alpha2Code: CountryAlpha2Code.WF,
  continent: Continent.AUSTRALIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '-13.3',
  longitude: '-176.2',
  regions: ['Wallis and Futuna']
},
{
  name: 'Western Sahara',
    alpha2Code: CountryAlpha2Code.EH,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '24.5',
  longitude: '-13',
  regions: ['Western Sahara']
},
{
  name: 'Yemen',
    alpha2Code: CountryAlpha2Code.YE,
  continent: Continent.ASIA,
  globalRegion: GlobalRegion.APAC,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.OPEA,
  latitude: '15',
  longitude: '48',
  regions: ['Abyan', 'Adan', 'Ad Dali', 'Al Bayda\'', 'Al Hudaydah', 'Al Mahrah', 'Al Mahwit', 'Amran', 'Dhamar',
  'Hadramawt', 'Hajjah', 'Ibb', 'Lahij', 'Ma\'rib', 'Sa\'dah', 'San\'a', 'Shabwah', 'Ta\'izz']
},
{
  name: 'Zambia',
    alpha2Code: CountryAlpha2Code.ZM,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-15',
  longitude: '30',
  regions: ['Copperbelt', 'Luapula', 'Lusaka', 'North-Western']
},
{
  name: 'Zimbabwe',
    alpha2Code: CountryAlpha2Code.ZW,
  continent: Continent.AFRICA,
  globalRegion: GlobalRegion.NONE,
  developmentCluster: DevelopmentCluster.NONE,
  constructionCluster: ConstructionCluster.NONE,
  opCo: OpCo.NONE,
  latitude: '-20',
  longitude: '30',
  regions: ['Bulawayo', 'Harare', 'Manicaland', 'Mashonaland Central', 'Mashonaland East', 'Mashonaland West', 'Masvingo', 'Matabeleland North', 'Matabeleland South', 'Midlands']
},
];

export const BY_ALPHA_2: {[code: string]: Country} = {};
for (const country of ALL_COUNTRIES) {
  BY_ALPHA_2[country.alpha2Code] = country;
}
export const BY_COUNTRY_NAME: {[countryName: string]: Country} = {};
for (const country of ALL_COUNTRIES) {
  BY_COUNTRY_NAME[country.name] = country;
}
