<mat-dialog-content>

  <digires-file-ref-table
    [title]="'Link Reference Dialog'"
    [inputTableData]="data.entries"
  ></digires-file-ref-table>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button type="button" class="btnNeutral" [mat-dialog-close]="false">Close</button>
  <button mat-button type="button" class="btnNeutral"
          [disabled]="!fileRefTableComponent || fileRefTableComponent?.formGroup?.invalid"
          [mat-dialog-close]="fileRefTableComponent?.formGroup?.value">
    Accept
  </button>
</mat-dialog-actions>
