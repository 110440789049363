<div class="d-flex flex-column min-height-0 max-height-100prc">
  <div class="d-flex flex-0-0-auto row news-list-filter">
    <label
      *ngFor="let filter of allFilters"
      (click)="onFilterChanged(filter)"
      class="d-flex cursor-pointer"
    >
      <input
        type="checkbox"
        class="row cursor-pointer {{ filter.css }}"
        [name]="filter.label"
        [checked]="filter?.checked"
        [value]="filter?.checked"
        [ngStyle]="{
          'accent-color': filter.color,
          'margin-right': '7px'
        }"
      />
      {{ filter.label }}
    </label>
  </div>
  <div class="d-flex flex-1-1-auto min-height-0 max-height-100prc">
    <digires-infinity-scroll
      [hasEntities]="filteredNews"
      [shouldObserve]="currentPageNr < totalPages"
      (changeStep)="onLoadNextPage()"
      class="d-flex flex-column min-height-0 w-100prc max-width-100prc"
    >
      <digires-news-list-entry
        *ngFor="let item of filteredNews"
        [newsItem]="item"
      ></digires-news-list-entry>
    </digires-infinity-scroll>
  </div>
</div>
