import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'digires-udp',
  templateUrl: './udp.component.html',
})
export class UdpComponent implements OnInit, OnDestroy {
  noAccess: boolean = false;
  urlSafe: SafeResourceUrl;
  subscription: Subscription;
  url: string = environment.udpURL;

  constructor(public sanitizer: DomSanitizer, private router: Router) {}

  ngOnInit() {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
    });
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
