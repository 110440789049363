export class StringUtil {

  public static filteredEquals(value: string, searchValue: string): boolean {
    return this.getNormalised(value) === this.getNormalised(searchValue);
  }

  public static filteredIncludes(value: string, searchValue: string): boolean {
    return this.getNormalised(value).includes(this.getNormalised(searchValue));
  }

  public static getNormalised(str: string): string {
    return str.trim().toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace('ł', 'l')
      .replace(/\s+/g, ' ');
  }

  public static getInitialsFromName(name: string): string {
    const words = name.split(' ').filter(s => s.length > 0).map(s => s.toUpperCase());
    const firstInitial = words[0]?.[0] || '';
    if (words.length > 1) {
      const lastInitial = words[words.length - 1]?.[0] || '';
      return `${firstInitial}${lastInitial}`;
    } else {
      return firstInitial;
    }
  }

}
