import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'digires-help-support-dialog',
  templateUrl: './help-support-dialog.component.html',
  styleUrls: ['./help-support-dialog.component.scss']
})
export class HelpSupportDialogComponent {

  public environment = environment;

}
