export enum Currency {
  EUR = 'EUR',
  AUD = 'AUD',
  PLN = 'PLN',
  SEK = 'SEK',
  GBP = 'GBP',
  USD = 'USD',
}
export const currencyToLabel: {[value: string]: string} = {
  [Currency.EUR]: 'Euro (€)',
  [Currency.AUD]: 'Australian dollar ($)',
  [Currency.PLN]: 'Polish złoty (zł)',
  [Currency.SEK]: 'Swedish krona (kr)',
  [Currency.GBP]: 'British pound (£)',
  [Currency.USD]: 'United State Dollar ($)',
};
