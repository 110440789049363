export enum ConstructionCluster {
  NONE = 'NONE',
  AUSTRALIA = 'AUSTRALIA',
  FRANCE = 'FRANCE',
  GERMANY = 'GERMANY',
  ITALY_GREECE_IBERIA = 'ITALY_GREECE_IBERIA',
  NETHERLANDS = 'NETHERLANDS',
  POLAND = 'POLAND',
  SCANDINAVIA = 'SCANDINAVIA',
  UK_IRELAND = 'UK_IRELAND',
}
export const consClusterToLabel: {[constructionCluster: string]: string} = {
  [ConstructionCluster.NONE]: 'Not within a defined Cluster',
  [ConstructionCluster.AUSTRALIA]: 'Australia',
  [ConstructionCluster.FRANCE]: 'France',
  [ConstructionCluster.GERMANY]: 'Germany',
  [ConstructionCluster.ITALY_GREECE_IBERIA]: 'Italy, Greece & Iberia',
  [ConstructionCluster.NETHERLANDS]: 'Netherlands',
  [ConstructionCluster.POLAND]: 'Poland',
  [ConstructionCluster.SCANDINAVIA]: 'Scandinavia',
  [ConstructionCluster.UK_IRELAND]: 'UK & Ireland',
};
