import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'digires-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  readonly flairToCss = {
    positive: 'btnPositive',
    negative: 'btnNegative',
    neutral: 'btnNeutral',
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    title: string,
    message: string,
    actions: {
      id: string,
      label: string,
      tooltip?: string,
      disabled?: boolean,
      flair: 'positive' | 'negative' | 'neutral',
    }[],
  }) {}

}
