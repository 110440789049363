<form>
  <digires-state-indicator *ngIf="showIndicator"
                           [formCtrlName]="fullFormCtrlName"
                           [readOnly]="true"
                           [readOnlyReason]="readOnlyReason"
                           [finModel]="finModel"
  ></digires-state-indicator>
  <mat-form-field>
    <label>
      <input [class.good]="highlight === highlightTypes.GOOD"
             [class.bad]="highlight === highlightTypes.BAD"
             matInput type="text"
             placeholder="{{placeholder}}"
             [readonly]="true"
             [value]="value"
             (change)="onInputChanged()">
    </label>
  </mat-form-field>
  <mat-icon *ngIf="!!tooltip" class="tooltip-icon" matTooltipPosition="above" matTooltip="{{tooltip}}" svgIcon="tooltip"></mat-icon>
  <mat-icon *ngIf="!!finModel" class="warning-triangle" [class.transparent]="!finModel.hasUpload || !finModel.isReadable(fullFormCtrlName) || finModel.isRead(fullFormCtrlName)"
            matTooltip="Note: The value in this field was not populated by the most recent upload of the financial model">
    warning
  </mat-icon>
</form>
