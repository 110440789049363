import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DropDownConfig } from '../../model/drop-down/DropDownConfig';
import { firstValueFrom } from 'rxjs';
import { Cache } from '../Cache';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class DropDownService {

  private readonly cache = new Cache<DropDownConfig>(element => element.id);

  constructor(
    private notificationService: NotificationService,
    private client: HttpClient,
  ) { }

  /**
   * Emits changes in cached {@link DropDownConfig}.
   * Changes to the cache are cause by:
   *
   * {@link fetchConfig()}
   *
   * {@link loadAllConfigs()}
   *
   * {@link saveConfig()}
   *
   * @returns an observable of cached {@link DropDownConfig}
   */
  observeConfig() {
    return this.cache.observeAllElementsList();
  }

  /**
   * Calls the Backend to get a specific {@link DropDownConfig} by id
   *
   * @param configId Id of the partner you want to get
   * @returns a promise with {@link DropDownConfig} or {@link HttpStatusCode.NotFound}
   */
  async fetchConfig(configId: string): Promise<DropDownConfig> {
    const promise = firstValueFrom(this.client.get<DropDownConfig>(`/api/drop-down-config/${configId}`));
    this.notificationService.handlePromiseError('Loading Data-Field Settings failed...', promise);
    return promise.then(config => {
      config.values.sort((a,b) => a.label.localeCompare(b.label))
      this.cache.putOne(configId, promise)
      return config;
    }).catch();
    // await promise.catch(() => {/*nothing to do with failed request*/});
    // return this.cache.putOne(configId, promise)
  }

  /**
   * Calls the backend to get all {@link DropDownConfig} as a List.
   *
   * Stores the data in the cache, which emits the change to {@link observeConfig()}
   */
  async loadAllConfigs() {
    const promise = firstValueFrom(this.client.get<DropDownConfig[]>('/api/drop-down-config'))
    await promise
    this.cache.putAll(promise)
  }

  /**
   * Calls the backend to update a {@link DropDownConfig}.
   *
   * Triggers an emit event of {@link observeConfig()}
   * @param dropDownConfig DropDownConfig data you want to update
   * @returns a promise with the updated {@link DropDownConfig} or {@link HttpStatusCode.Unauthorized}
   */
  async saveConfig(dropDownConfig: DropDownConfig) {
    const promise = firstValueFrom(this.client.post<DropDownConfig>('/api/drop-down-config', dropDownConfig))
    this.notificationService.handlePromise('DropDownConfig updated!', 'Updating DropDownConfig failed...', promise);
    await promise
    return this.cache.putOne(dropDownConfig.id, promise);
  }
}
