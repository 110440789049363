import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {Chunk} from './Chunk';
import * as moment from 'moment';
import { of } from 'rxjs';

interface CacheLine<ELEM> {
  element: ELEM;
  timeStamp: Date;
  promise: Promise<ELEM>;
}
export class Cache<ELEM> {

  private static readonly CACHE_EVICTION_TIME_IN_SECONDS = 60;

  private readonly subjectList = new BehaviorSubject<ELEM[]>([]);
  private readonly subjectMap = new BehaviorSubject<{[id: string]: ELEM}>({});
  private idToElem: {[id: string]: CacheLine<ELEM>} = {};
  private ongoingSubscription: Subscription;
  private ongoingLoadChunk: Subject<Chunk<ELEM>>;
  private ongoingLoadAll: Promise<ELEM[]>;

  constructor(private keyMapper: (elem: ELEM) => string) {}

  public observeAllElementsList(): Observable<ELEM[]> {
    return this.subjectList;
  }

  public observeAllElementsMap(): Observable<{[id: string]: ELEM}> {
    return this.subjectMap;
  }

  public getAllElementsList(): ELEM[] {
    return this.subjectList.getValue();
  }

  public getAllElementsMap(): {[id: string]: ELEM} {
    return this.subjectMap.getValue();
  }

  public invalidateAll() {
    if (this.ongoingSubscription) {
      this.ongoingLoadChunk.complete();
      this.ongoingSubscription.unsubscribe();
      this.ongoingLoadChunk = null;
      this.ongoingSubscription = null;
    }
    this.idToElem = {};
    this.subjectList.next([]);
    this.subjectMap.next({});
  }

  public invalidateSome(ids: string[]) {
    for (const id of ids) {
      delete this.idToElem[id];
    }
    this.updateSubjects();
  }

  public invalidateOne(id: string) {
    delete this.idToElem[id];
    this.updateSubjects();
  }

  private updateCacheLine(id: string, element: ELEM, timeStamp: Date) {
    this.idToElem[id] = {
      element: element,
      timeStamp: timeStamp,
      promise: null,
    };
  }

  private updateSubjects() {
    this.subjectList.next(Object.values(this.idToElem).filter(line => !!line.element).map(line => line.element));
    this.subjectMap.next(this.transformMap());
  }

  public getAllChunked(ids: string[], loader: (ids: string[]) => Subject<Chunk<ELEM>>): Observable<Chunk<ELEM>> {
    const cacheState = this.getCacheState(ids);
    if (cacheState.missingIds.length > 0) {
      const subject = new Subject<Chunk<ELEM>>();
      subject.next({
        expectedCount: ids.length,
        loadedCount: cacheState.cachedElements.length,
        loadedElements: [...cacheState.cachedElements],
        finished: false,
      });
      if (this.ongoingSubscription) {
        this.ongoingSubscription.unsubscribe();
        this.ongoingLoadChunk.complete();
      }
      this.ongoingLoadChunk = loader(cacheState.missingIds);
      this.ongoingSubscription = this.ongoingLoadChunk.subscribe({
        next: bulkLoad => {
          const timeStamp = new Date();
          for (const element of bulkLoad.loadedElements) {
            this.updateCacheLine(this.keyMapper(element), element, timeStamp);
          }
          this.updateSubjects();
          subject.next({
            expectedCount: ids.length,
            loadedCount: cacheState.cachedElements.length + bulkLoad.loadedCount,
            loadedElements: [...cacheState.cachedElements, ...bulkLoad.loadedElements],
            finished: bulkLoad.finished,
          });
        },
        complete: () => {
          this.ongoingLoadChunk = null;
          this.ongoingSubscription = null;
          subject.complete();
        }
      });
      return subject;
    }
    return of({
      expectedCount: ids.length,
      loadedCount: cacheState.cachedElements.length,
      loadedElements: cacheState.cachedElements,
      finished: true,
    });
  }

  public getAllBulk(promise: Promise<ELEM[]>): Promise<ELEM[]> {
    return this.ongoingLoadAll || this.putAll(promise);
  }

  private getCacheState(ids: string[]): {missingIds: string[], cachedElements: ELEM[]} {
    const cachedElements = [];
    const missingIds = [];
    for (const id of ids) {
      const cacheLine = this.idToElem[id];
      if (this.isOutdated(cacheLine?.timeStamp)) {
        missingIds.push(id);
      } else {
        cachedElements.push(cacheLine.element);
      }
    }
    return {missingIds, cachedElements};
  }

  public getOne(id: string, loader: (id: string) => Promise<ELEM>): Promise<ELEM> {
    if (this.isCached(id)) {
      return this.toPromise(id);
    }
    const promise = loader(id);
    this.updateCacheLine(id, null, null);
    this.updateSubjects();
    promise.then(element => {
      this.updateCacheLine(id, element, new Date());
      this.updateSubjects();
    }).catch(() => {/*nothing to do with failed request*/});
    return promise;
  }

  private toPromise(id: string): Promise<ELEM> {
    const cacheLine = this.idToElem[id];
    if (cacheLine.promise) {
      return cacheLine.promise;
    }
    if (this.ongoingLoadAll) {
      return this.ongoingLoadAll.then(() => {
        return this.idToElem[id].element;
      });
    }
    return Promise.resolve(cacheLine.element);
  }

  public putOne(id: string, promise: Promise<ELEM>): Promise<ELEM> {
    if (this.idToElem[id]) {
      this.idToElem[id].promise = promise;
    } else {
      this.idToElem[id] = {element: null, timeStamp: null, promise};
    }
    promise.then(element => {
      this.updateCacheLine(id, element, new Date());
      this.updateSubjects();
    }).catch(() => {/*nothing to do with failed request*/});
    return promise;
  }

  public putAll(promise: Promise<ELEM[]>): Promise<ELEM[]> {
    this.ongoingLoadAll = promise;
    promise.then(elements => {
      const timeStamp = new Date();
      for (const element of elements) {
        this.updateCacheLine(this.keyMapper(element), element, timeStamp);
      }
      this.updateSubjects();
    }).catch(() => {/*nothing to do with failed request*/});
    return promise;
  }

  public isCached(id: string) {
    if (this.ongoingLoadAll) {
      return true;
    }
    const cacheLine = this.idToElem[id];
  // || cacheLine.bulkLoad
    return cacheLine && (cacheLine.promise || !this.isOutdated(cacheLine.timeStamp));
  }

  private isOutdated(timeStamp: Date): boolean {
    if (!timeStamp) {
      return true;
    }
    const timeStampMoment = moment(timeStamp);
    const diffTime = moment(new Date()).diff(timeStampMoment);
    const diffSeconds = moment.duration(diffTime).asSeconds();
    return diffSeconds > Cache.CACHE_EVICTION_TIME_IN_SECONDS;
  }

  private transformMap(): {[id: string]: ELEM} {
    const map = {};
    for (const entry of Object.entries(this.idToElem)) {
      map[entry[0]] = entry[1].element;
    }
    return map;
  }

}
