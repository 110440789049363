import { StandardIpt } from '../../model/standard-ipt/StandardIpt';
import { StandardIptService } from '../../services/standard-ipt/standard-ipt.service';
import { Component, Inject, OnInit } from '@angular/core';
import { TeamMember } from '../../model/team/TeamMember';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Technology } from '../../model/general/Technology';
import { CountryAlpha2Code } from '../../model/geo/CountryAlpha2Code';
import { IptPhase, iptPhaseToProjectPhase } from '../../model/team/IptPhase';
import { TeamMemberEntry, TeamRoleUtil } from '../../TeamRoleUtil';
import { GetUsers } from '../../services/user/GetUsers';
import { GetTeamRoles } from '../../services/team-role/GetTeamRoles';

@Component({
  selector: 'digires-standard-ipt-import-dialog',
  templateUrl: './standard-ipt-import-dialog.component.html',
  styleUrls: ['./standard-ipt-import-dialog.component.scss']
})
export class StandardIptImportDialogComponent implements OnInit {

  importCoreEntries: ImportIptEntry[] = [];
  importWiderEntries: ImportIptEntry[] = [];
  teamRoleUtil: TeamRoleUtil;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      technology: Technology,
      countryCode: CountryAlpha2Code,
      iptPhase: IptPhase,
      members: TeamMemberEntry[],
      userMap: {[userId: string]: GetUsers},
      roleMap: {[roleId: string]: GetTeamRoles},
    },
    private standardIptService: StandardIptService,
  ) {}

  ngOnInit(): void {
    this.teamRoleUtil = new TeamRoleUtil(this.data.iptPhase);
    const projectPhase = iptPhaseToProjectPhase[this.data.iptPhase];
    this.standardIptService.getStandardIptFor( this.data.technology ,this.data.countryCode, projectPhase)
      .then(ipts => this.onStandardIptReceived(ipts));
  }

  private onStandardIptReceived(standardIpts: StandardIpt[]) {
    this.importCoreEntries = [];
    this.importWiderEntries = [];

    const standardIptMembers = standardIpts.find(ipt => ipt.phase === this.data.iptPhase)?.members || [];

    for (const standardMember of standardIptMembers) {
      const role = this.data.roleMap[standardMember?.roleId];
      const user = this.data.userMap[standardMember?.userId];
      if (!role || !user) {
        continue;
      }
      const exactMemberAlreadyExists = this.data.members.some(member =>
        member.getRole()?.id === standardMember.roleId && member.getUser()?.id === standardMember.userId);
      if (exactMemberAlreadyExists) {
        continue;
      }

      const existingIptMembers = this.data.members.filter(member => member.getRole()?.id === standardMember.roleId);

      const entries = this.teamRoleUtil.isCore(role)
        ? this.importCoreEntries
        : this.importWiderEntries;

      if (existingIptMembers.length <= 0) {
        entries.push({
          role: role,
          standardIptMember: standardMember,
          standardIptMemberName: user.fullName,
          existingIptMember: null,
          radBtnFormGroup: new FormGroup({action: new FormControl(ImportAction.INSERT)}),
          actions: [null, ImportAction.INSERT, ImportAction.IGNORE],
        });
      } else {
        for (const existingMember of existingIptMembers) {
          entries.push({
            role: role,
            standardIptMember: standardMember,
            standardIptMemberName: user.fullName,
            existingIptMember: existingMember,
            radBtnFormGroup: new FormGroup({action: new FormControl(ImportAction.DUPLICATE)}),
            actions: [ImportAction.REPLACE, ImportAction.DUPLICATE, ImportAction.KEEP],
          });
        }
      }

    }
    this.importCoreEntries.sort((a, b) => this.teamRoleUtil.comparatorInCore()(a.role, b.role));
    this.importWiderEntries.sort((a, b) => this.teamRoleUtil.comparatorInWider()(a.role, b.role));
  }

  createReturnValue(): ImportIptRequest[] {
    return [
      ...this.importCoreEntries.map(entry => this.toChangeAction(entry)),
      ...this.importWiderEntries.map(entry => this.toChangeAction(entry)),
    ].filter(request => !!request.action);
  }

  private toChangeAction(entry: ImportIptEntry): ImportIptRequest {
    const actionValue: ImportAction = ImportAction[entry.radBtnFormGroup.value.action] || ImportAction.IGNORE;
    let requestAction: ImportIptRequestAction;
    switch (actionValue) {
      case ImportAction.REPLACE: requestAction = ImportIptRequestAction.REPLACE; break;
      case ImportAction.DUPLICATE: requestAction = ImportIptRequestAction.INSERT; break;
      case ImportAction.INSERT: requestAction = ImportIptRequestAction.INSERT; break;
    }
    return {
      existingIptMember: entry.existingIptMember,
      standardIptMember: entry.standardIptMember,
      action: requestAction || null,
    };
  }

}
export interface ImportIptEntry {
  role: GetTeamRoles;
  standardIptMember: TeamMember;
  standardIptMemberName: string;
  existingIptMember: TeamMemberEntry;
  radBtnFormGroup: FormGroup;
  actions: ImportAction[];
}
export enum ImportAction {
  REPLACE = 'REPLACE',
  DUPLICATE = 'DUPLICATE',
  INSERT = 'INSERT',
  IGNORE = 'IGNORE',
  KEEP = 'KEEP',
}
export interface ImportIptRequest {
  existingIptMember: TeamMemberEntry;
  standardIptMember: TeamMember;
  action: ImportIptRequestAction;
}
export enum ImportIptRequestAction {
  REPLACE = 'REPLACE',
  INSERT = 'INSERT',
}
