import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {firstValueFrom, Subject} from 'rxjs';
import {Chunk} from './Chunk';
import {Page} from '../model/Page';

@Injectable({
  providedIn: 'root'
})
export class BulkLoadService {

  constructor(private client: HttpClient) { }

  chunkedLoading<E>(url: string, idParameterName: string, ids: string[]): Subject<Chunk<E>> {
    const payload: Chunk<E> = {
      expectedCount: ids.length,
      loadedCount: 0,
      loadedElements: [],
      finished: false,
    };
    const subject = new Subject<Chunk<E>>();
    subject.next(payload);

    this.recursiveChunkedLoading<E>(
      url,
      idParameterName,
      ids,
      0,
      50,
      elements => {
        payload.loadedCount += elements.length;
        payload.loadedElements.push(...elements);
        subject.next(payload);
      },
      elements => {
        payload.loadedCount += elements.length;
        payload.loadedElements.push(...elements);
        payload.finished = true;
        subject.next(payload);
        subject.complete();
      }
    );

    return subject;
  }

  private recursiveChunkedLoading<E>(
    url: string,
    idParameterName: string,
    ids: string[],
    index: number,
    chunkSize: number,
    onLoad: (elements: E[]) => any,
    onFinish: (elements: E[]) => any,
  ) {
    if (index >= ids.length) {
      return;
    }
    const idSlice = ids.slice(index, index + chunkSize);
    this.loadById<E>(url, idParameterName, idSlice)
      .then(elements => {
        if (elements && elements.length > 0) {
          if (index + chunkSize >= ids.length) {
            onFinish(elements);
          } else {
            onLoad(elements);
            this.recursiveChunkedLoading(url, idParameterName, ids, index + chunkSize, chunkSize, onLoad, onFinish);
          }
        } else {
          onFinish([]);
        }
      });
  }

  private async loadById<E>(
    url: string,
    idParameterName: string,
    ids: string[],
  ): Promise<E[]> {
    return firstValueFrom(this.client.get<E[]>(url, {
      params: new HttpParams({
        fromObject: {
          [idParameterName]: ids,
        }
      })
    }));
  }

  paginatedLoading<E>(url: string, pageSize: number, params?: any): Subject<Page<E>> {
    const subject = new Subject<Page<E>>();
    this.recursivePaginatedLoading(
      url,
      params,
      null,
      pageSize,
      (page: Page<E>) => {
        subject.next(page);
      },
      () => {
        subject.complete();
      }
    );
    return subject;
  }

  private recursivePaginatedLoading<E>(
    url: string,
    params: any,
    prevPage: Page<E>,
    pageSize: number,
    onLoad: (page: Page<E>) => any,
    onFinish: () => any,
  ) {
    if (prevPage?.last) {
      onFinish();
      return;
    }
    let offset = prevPage ? (prevPage.number + 1) * pageSize : 0;
    this.loadPage<E>(
      url, params, offset, pageSize
    ).then(page => {
        if (page.content?.length > 0) {
          onLoad(page);
          if (page.last) {
            onFinish();
          } else {
            this.recursivePaginatedLoading(url, params, page, pageSize, onLoad, onFinish);
          }
        } else {
          onFinish();
        }
      });
  }

  private async loadPage<E>(
    url: string,
    params: any,
    offset: number,
    limit: number,
  ): Promise<Page<E>> {
    return firstValueFrom(this.client.get<Page<E>>(url, {
      params: new HttpParams({
        fromObject: Object.assign({offset, limit}, params)
      })
    }));
  }

}
