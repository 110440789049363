import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {GetProjectsSchedule} from './GetProjectsSchedule';
import {GetProjectsChangeComments} from './GetProjectsChangeComments';
import {GetProjectsGeneralInfo} from './GetProjectsGeneralInfo';
import {calculateDerivedValues, GetProjectsTechnicalData} from './GetProjectsTechnicalData';
import {Cache} from '../Cache';
import {BulkLoadService} from '../bulk-load.service';
import {Chunk} from '../Chunk';
import {GetProjectsTeam} from './GetProjectsTeam';
import {GetProjectsFinancialData} from './GetProjectsFinancialData';
import {GetProjectsConstruction} from './GetProjectsConstruction';
import {ReportMonth} from '../../model/ReportMonth';
import {PutProjects} from './PutProjectsData';
import {GetProjects} from '../project-list/GetProjects';
import {ProjectListService} from '../project-list/project-list.service';
import {GetProjectsFinancialModel} from './GetProjectsFinancialModel';
import {GetProjectsConstructionFinancials} from './GetProjectsConstructionFinancials';
import {GetProjectsCostTrendAnalysis} from './GetProjectsCostTrendAnalysis';
import {GetFinancialModelProperties} from './GetFinancialModelProperties';
import {NotificationService} from '../other/notification.service';
import {PostProjectsHandover} from './PostProjectsHandover';
import { TableReportService } from 'src/app/reports-area/table-report/table-report.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDetailsService {

  private readonly generalInfoCache = new Cache<GetProjectsGeneralInfo>(element => element.projectId);
  private readonly teamCache = new Cache<GetProjectsTeam>(element => element.projectId);
  private readonly scheduleCache = new Cache<GetProjectsSchedule>(element => element.projectId);
  private readonly changeCommentsCache = new Cache<GetProjectsChangeComments>(element => element.projectId);
  private readonly technicalCache = new Cache<GetProjectsTechnicalData>(element => element.projectId);
  private readonly financialCache = new Cache<GetProjectsFinancialData>(element => element.projectId);
  private readonly financialModelCache = new Cache<GetProjectsFinancialModel>(element => element.projectId);
  private readonly constructionCache = new Cache<GetProjectsConstruction>(element => element.projectId);
  private readonly ctaCache = new Cache<GetProjectsConstructionFinancials>(element => element.projectId);
  private fmProps: GetFinancialModelProperties;

  constructor(
    private projectService: ProjectListService,
    private bulkLoader: BulkLoadService,
    private notificationService: NotificationService,
    private tableReportSerivce: TableReportService,
    private client: HttpClient,
  ) {}

  private invalidateAllCaches(projectId: string) {
    this.generalInfoCache.invalidateOne(projectId);
    this.teamCache.invalidateOne(projectId);
    this.scheduleCache.invalidateOne(projectId);
    this.changeCommentsCache.invalidateOne(projectId);
    this.technicalCache.invalidateOne(projectId);
    this.financialCache.invalidateOne(projectId);
    this.financialModelCache.invalidateOne(projectId);
    this.constructionCache.invalidateOne(projectId);
    this.ctaCache.invalidateOne(projectId);
    this.tableReportSerivce.invalidateProjectCache(projectId);
  }

  updateProject(projectId: string, body: PutProjects): Promise<GetProjects> {
    return firstValueFrom(this.client.put<GetProjects>(`/api/v1/projects/${projectId}`, body))
      .then((data: GetProjects) => {
        this.invalidateAllCaches(projectId);
        return this.projectService.updateProjectById(projectId, Promise.resolve(data));
      });
  }

  fetchGeneralInfo(projectId: string): Promise<GetProjectsGeneralInfo> {
    return this.generalInfoCache.getOne(projectId, this.loadOne.bind(this, 'general-information'));
  }

  fetchGeneralInfoForAll(projectIds: string[]): Observable<Chunk<GetProjectsGeneralInfo>> {
    return this.generalInfoCache.getAllChunked(projectIds, this.loadAll.bind(this, 'general-information'));
  }

  fetchTeam(projectId: string): Promise<GetProjectsTeam> {
    return this.teamCache.getOne(projectId, this.loadOne.bind(this, 'team'));
  }

  fetchTeamForAll(projectIds: string[]): Observable<Chunk<GetProjectsTeam>> {
    return this.teamCache.getAllChunked(projectIds, this.loadAll.bind(this, 'team'));
  }

  fetchSchedule(projectId: string): Promise<GetProjectsSchedule> {
    return this.scheduleCache.getOne(projectId, this.loadOne.bind(this, 'schedule'));
  }

  fetchScheduleForAll(projectIds: string[]): Observable<Chunk<GetProjectsSchedule>> {
    return this.scheduleCache.getAllChunked(projectIds, this.loadAll.bind(this, 'schedule'));
  }

  fetchChangeComments(projectId: string): Promise<GetProjectsChangeComments> {
    return this.changeCommentsCache.getOne(projectId, this.loadOne.bind(this, 'change-comments'));
  }

  fetchChangeCommentsForAll(projectIds: string[]): Observable<Chunk<GetProjectsChangeComments>> {
    return this.changeCommentsCache.getAllChunked(projectIds, this.loadAll.bind(this, 'change-comments'));
  }

  fetchConstruction(projectId: string): Promise<GetProjectsConstruction> {
    // return this.constructionCache.getOne(projectId, this.loadOne.bind(this, 'construction'));
    return this.constructionCache.getOne(
      projectId,
      id => {
        return this.loadOne<GetProjectsConstruction>('construction', id).then(cd => {
          cd.reportingMonths.sort((a, b) => ReportMonth.isAfter(a, b) ? 1 : -1);
          return cd;
        });
      }
    );
  }

  fetchTechnicalData(projectId: string): Promise<GetProjectsTechnicalData> {
    return this.technicalCache.getOne(
      projectId,
      id => {
        return this.loadOne<GetProjectsTechnicalData>('technical-data', id).then(td => {
          calculateDerivedValues(td);
          return td;
        });
      }
    );
  }

  fetchTechnicalDataForAll(projectIds: string[]): Observable<Chunk<GetProjectsTechnicalData>> {
    return this.technicalCache.getAllChunked(
      projectIds,
      ids => {
        const subject = this.loadAll<GetProjectsTechnicalData>('technical-data', ids);
        subject.subscribe(chunk => chunk.loadedElements.forEach(td => calculateDerivedValues(td)));
        return subject;
      }
    );
  }

  fetchFinancialData(projectId: string): Promise<GetProjectsFinancialData> {
    return this.financialCache.getOne(
      projectId,
      this.loadOne.bind(this, 'financial-data')
    );
  }

  loadFinancialModelProperties(): Promise<GetFinancialModelProperties> {
    if (this.fmProps) {
      return Promise.resolve(this.fmProps);
    }
    const promise = firstValueFrom(this.client.get<GetFinancialModelProperties>('/api/financial-model/properties'));
    promise.then(value => this.fmProps = value);
    return promise;
  }

  fetchFinancialModel(projectId: string): Promise<GetProjectsFinancialModel> {
    return this.financialModelCache.getOne(
      projectId,
      this.loadOne.bind(this, 'financial-model')
    );
  }

  checkFinancialModelForExistingProject(projectId: string, file: File): Promise<GetProjectsFinancialModel> {
    const url = `/api/v1/projects/${projectId}/financial-model`;
    const formData = new FormData();
    formData.append('file', file);
    return firstValueFrom(this.client.post<GetProjectsFinancialModel>(url, formData));
  }

  checkFinancialModelForProjectCreation(file: File): Promise<GetProjectsFinancialModel> {
    const url = '/api/financial-model';
    const formData = new FormData();
    formData.append('file', file);
    return firstValueFrom(this.client.post<GetProjectsFinancialModel>(url, formData));
  }

  uploadFinancialModel(projectId: string, keyDataChangeReason: string, file: File): Promise<GetProjectsFinancialModel> {
    const url = `/api/v1/projects/${projectId}/financial-model`;
    const formData = new FormData();
    formData.append('file', file);
    if (keyDataChangeReason) {
      formData.append('keyDataChangeReason', keyDataChangeReason);
    }
    const promise = firstValueFrom(this.client.put<GetProjectsFinancialModel>(url, formData));
    this.notificationService.handlePromiseError('Uploading Financial Model failed...', promise);
    return this.financialModelCache.putOne(projectId, promise);
  }

  deleteFinancialModel(projectId: string): Promise<GetProjectsFinancialModel> {
    const url = `/api/v1/projects/${projectId}/financial-model`;
    const promise = firstValueFrom(this.client.delete<GetProjectsFinancialModel>(url));
    this.notificationService.handlePromiseError('Deleting Financial Model failed...', promise);
    return this.financialModelCache.putOne(projectId, promise);
  }

  fetchConstructionFinancials(projectId: string): Promise<GetProjectsConstructionFinancials> {
    return this.ctaCache.getOne(
      projectId,
      this.loadOne.bind(this, 'cost-trend-analysis')
    );
  }

  checkCostTrendAnalysis(projectId: string, file: File): Promise<GetProjectsCostTrendAnalysis> {
    const url = `/api/v1/projects/${projectId}/cost-trend-analysis`;
    const formData = new FormData();
    formData.append('file', file);
    return firstValueFrom(this.client.post<GetProjectsCostTrendAnalysis>(url, formData));
  }

  uploadCostTrendAnalysis(projectId: string, file: File): Promise<GetProjectsConstructionFinancials> {
    const url = `/api/v1/projects/${projectId}/cost-trend-analysis`;
    const formData = new FormData();
    formData.append('file', file);
    const promise = firstValueFrom(this.client.put<GetProjectsConstructionFinancials>(url, formData));
    this.notificationService.handlePromiseError('Uploading Cost Trend Analysis failed...', promise);
    return this.ctaCache.putOne(projectId, promise);
  }

  handoverProject(projectId: string, input: PostProjectsHandover): Promise<void> {
    const url = `/api/v1/projects/${projectId}/handover`;
    const promise = firstValueFrom(this.client.post<void>(url, input));
    this.notificationService.handlePromiseError('Handing over Project to next Phase failed...', promise);
    promise.then(() => {
      this.invalidateAllCaches(projectId);
      this.projectService.invalidateProjectListCacheFor([projectId]);
    });
    return promise;
  }

  private loadOne<X>(resource: string, projectId: string): Promise<X> {
    const url = `/api/v1/projects/${projectId}/${resource}`;
    return firstValueFrom(this.client.get<X>(url));
  }

  private loadAll<X>(resource: string, projectIds: string[]): Subject<Chunk<X>> {
    return this.bulkLoader.chunkedLoading(`/api/${resource}`, 'projectIds', projectIds);
  }

}
