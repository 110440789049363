import {Component, Input} from '@angular/core';
import {AbstractInputDirective} from '../AbstractInputDirective';

@Component({
  selector: 'digires-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent extends AbstractInputDirective {

  @Input() textarea = false;
  @Input() maxLength = -1;
  @Input() fixedRowCount: number = null;

}
