import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { InfinityScrollComponent } from "./infinity-scroll.component";

@NgModule({
    declarations: [InfinityScrollComponent],
    exports: [InfinityScrollComponent],
    imports: [CommonModule]
})
export class InfinityScrollModule {}
