<mat-icon class="navbar-logo" svgIcon="navbar-digires-logo"></mat-icon>
<ul class="navbar-icon-list">
  <ng-container *ngFor="let item of items">
    <li
      *ngIf="item.accessible"
      routerLink="{{ item.routeTo }}"
      matTooltipPosition="right"
      matTooltip="{{ item.tooltip }}"
    >
      <mat-icon
        [class.navbar-active]="isAnySegmentInUrl(item.routeActive)"
        svgIcon="{{ item.icon }}"
      ></mat-icon>
    </li>
  </ng-container>
</ul>
<div style="flex-grow: 1"><!--Intentionally Left Blank--></div>
<ul>
  <li
    class="navbar-user-menu"
    matTooltipPosition="right"
    matTooltip="User Menu"
    [matMenuTriggerFor]="userMenu"
  >
    <mat-icon svgIcon="navbar-user-menu"></mat-icon>
    <div>{{ utcDate }}</div>
    <div>{{ utcTime }}</div>
  </li>
</ul>
<mat-menu
  #userMenu="matMenu"
  yPosition="below"
  [overlapTrigger]="false"
  backdropClass="customize"
  class="mat-user-menu"
>
  <button mat-menu-item (click)="openIntroductionForEveryone()">
    An Introduction for Everyone
  </button>
  <button mat-menu-item (click)="openUserGuide(fileReferenceTypes.SLIDE)">
    User Guide – Slide Packs
  </button>
  <button mat-menu-item (click)="openUserGuide(fileReferenceTypes.VIDEO)">
    User Guide – Training Videos
  </button>
  <button mat-menu-item (click)="openDataProtectionDialog()">
    Data Privacy Guideline
  </button>
  <button mat-menu-item (click)="openSupportDialog()">Support & Help</button>
  <button mat-menu-item (click)="openLogoutDialog()">Logout</button>
</mat-menu>
