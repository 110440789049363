import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DropDownOption } from '../../model/drop-down/DropDownOption';
import { DropDownService } from '../../services/other/drop-down.service';
import { AbstractInputDirective } from '../AbstractInputDirective';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'digires-multi-dropdown-input',
  templateUrl: './multi-dropdown-input.component.html',
  styleUrls: ['./multi-dropdown-input.component.scss']
})
export class MultiDropdownInputComponent extends AbstractInputDirective implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  public readonly NO_ITEMS = {dummy: -1};
  public readonly ALL_ITEMS = {dummy: 1};

  internalFormGroup: FormGroup;
  prevSelectedItems: any[];
  isPatchingFg: boolean = false;
  @Input() configId: string | string[];
  @Input() allItemName: string;
  @Input() noItemName: string;
  items: DropDownOption[] = [];

  constructor(private dropDownService: DropDownService) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.internalFormGroup = new FormGroup({multiSelect: new FormControl()});

    const ids = Array.isArray(this.configId) ? this.configId : [this.configId];

    for(const id of ids) {
      const config = await this.dropDownService.fetchConfig(id);
      const values = config?.values || [];
      this.items.push(...values);
    }

    this.syncFormGroups(this.getValue());

    this.getCtrl()
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(newValue => {
        if (!this.isPatchingFg) {
          this.syncFormGroups(newValue);
        }
      });
    this.syncFormGroups(this.getValue());
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getReadonlyValue(): string {
    const formValue = this.internalFormGroup.value.multiSelect;
    if (!formValue) {
      return null;
    }
      const formValues: any[] = formValue;

      if (this.allItemName && formValues.includes(this.ALL_ITEMS)) {
        return this.allItemName;
      }
      if (this.noItemName && formValues.includes(this.NO_ITEMS)) {
        return this.noItemName;
      }

      const itemValues: any[] = this.items.filter(item => formValues.includes(item.id));
      return itemValues.map(item => item.label).join(', ');
    }

  private syncFormGroups(newValue: string[]) {
    this.isPatchingFg = true;
    const currentValue = newValue
      ? newValue
      : this.items.map(v => v.id);
    if (newValue !== currentValue) {
      this.patch(currentValue);
    }
    if (currentValue && currentValue.length === 0 && this.noItemName) {
      this.prevSelectedItems = [this.NO_ITEMS];
    } else if (currentValue.length === this.items.length && this.allItemName) {
      this.prevSelectedItems = [this.ALL_ITEMS];
    } else if (currentValue.length === 0 && this.allItemName) {
      this.prevSelectedItems = [this.ALL_ITEMS];
    } else {
      this.prevSelectedItems = currentValue;
    }
    this.internalFormGroup.patchValue({multiSelect: this.prevSelectedItems});
    this.isPatchingFg = false;
  }

  onItemSelected(event: MatSelectChange): void {
    this.isPatchingFg = true;
    const value = event.value;
    if (value.includes(this.ALL_ITEMS) && !this.prevSelectedItems.includes(this.ALL_ITEMS)) {
      // ALL_ITEMS has just been selected => unselect everything else

      this.internalFormGroup.patchValue({multiSelect: [this.ALL_ITEMS]});
      this.patch(this.items.map(v => v.id));

    } else if (value.includes(this.NO_ITEMS) && !this.prevSelectedItems.includes(this.NO_ITEMS)) {
      // NO_ITEMS has just been selected => unselect everything else

      this.internalFormGroup.patchValue({multiSelect: [this.NO_ITEMS]});
      this.patch([]);

    } else if (value.length === 0) {
      // no item has been selected => either select NO_ITEMS or ALL_ITEMS

      if (this.noItemName) {
        this.internalFormGroup.patchValue({multiSelect: [this.NO_ITEMS]});
        this.patch([]);
      } else if (this.allItemName) {
        this.internalFormGroup.patchValue({multiSelect: [this.ALL_ITEMS]});
        this.patch(this.items.map(v => v.id));
      } else {
        this.patch(this.internalFormGroup.value.multiSelect);
      }

    } else if (value.length > 1 && (value.includes(this.ALL_ITEMS) || value.includes(this.NO_ITEMS))) {
      // selected another item in addition to ALL_ITEMS or NO_ITEMS => unselect ALL_ITEMS and NO_ITEMS

      const filteredItems = value.filter(item => item !== this.NO_ITEMS && item !== this.ALL_ITEMS);
      this.internalFormGroup.patchValue({multiSelect: filteredItems});
      this.patch(filteredItems);

    } else if (value.length === this.items.length && this.allItemName) {
      // all items have been selected manually => select ALL_ITEMS
      // (NO_ITEMS and ALL_ITEMS can not be included in the value because of previous cases)

      this.internalFormGroup.patchValue({multiSelect: [this.ALL_ITEMS]});
      this.patch(this.items.map(v => v.id));

    } else {
      // just patch through to external FormGroup
      this.patch(this.internalFormGroup.value.multiSelect);

    }
    this.prevSelectedItems = this.internalFormGroup.value.multiSelect;
    this.isPatchingFg = false;
  }

}
