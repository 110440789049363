import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GetPortfolioReports} from './GetPortfolioReports';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {FileMeta} from './FileMeta';
import {Page} from '../../model/Page';
import {GetTargetSettings} from './GetTargetSettings';
import {TargetType, targetTypeIndex} from './TargetType';
import { DateUtil } from '../../DateUtil';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private portfolioReports: BehaviorSubject<GetPortfolioReports[]> = new BehaviorSubject<GetPortfolioReports[]>([]);

  constructor(
    private client: HttpClient,
  ) { }

  loadFileMeta(fileId: string): Promise<FileMeta> {
    return firstValueFrom(this.client.get<FileMeta>(`api/files/${fileId}/meta`));
  }

  downloadFileByMeta(fileMeta: FileMeta) {
    this.downloadFile(fileMeta.id, fileMeta.fileName);
  }

  downloadFile(fileId: string, fileName: string) {
    firstValueFrom(this.client.get(`api/files/${fileId}`, {responseType: 'blob' as 'json'}))
      .then((response: any) => FileService.saveDownloadedFile(fileName, response));
  }

  public static saveDownloadedFile(fileName: string, blob: any) {
    const dataType = blob.type;
    const binaryData = [blob];
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
  }

  public static downloadFilePrependDateToFileName(fileName: string, blob: any) {
    const timestamp = DateUtil.fileNameTimeStamp(new Date(Date.now()));
    const filenameWithDate = timestamp+'UTC_'+fileName;
    this.saveDownloadedFile(filenameWithDate, blob);
  }

  observeAllActivePortfolioReports(): Observable<GetPortfolioReports[]> {
    return this.portfolioReports;
  }

  loadAllActivePortfolioReports(): Promise<GetPortfolioReports[]> {
    const promise = firstValueFrom(this.client.get<GetPortfolioReports[]>('api/portfolio-reports'));
    promise.then(slots => this.portfolioReports.next(this.sortPortfolioReportSlots(slots)));
    return promise;
  }

  uploadPortfolioReport(index: number, fileToUpload: File): Promise<GetPortfolioReports> {
    const url = `api/portfolio-reports/${index}`;
    const formData = new FormData();
    formData.append('file', fileToUpload);

    const promise = firstValueFrom(this.client.post<GetPortfolioReports>(url, formData));
    promise.then(uploadedSlot => this.replacePortfolioReportSlot(uploadedSlot));
    return promise;
  }

  archivePortfolioReport(index: number): Promise<GetPortfolioReports> {
    const url = `api/portfolio-reports/${index}`;
    const promise = firstValueFrom(this.client.delete<GetPortfolioReports>(url));
    promise.then(deletedSlot => this.replacePortfolioReportSlot(deletedSlot));
    return promise;
  }

  renamePortfolioReport(index: number, newName: string): Promise<GetPortfolioReports> {
    const url = `api/portfolio-reports/${index}`;
    const promise = firstValueFrom(this.client.put<GetPortfolioReports>(url, {newName}));
    promise.then(renamedSlot => this.replacePortfolioReportSlot(renamedSlot));
    return promise;
  }

  private replacePortfolioReportSlot(replacementSlot: GetPortfolioReports) {
    const oldSlots = this.portfolioReports.getValue();
    const newSlots = this.sortPortfolioReportSlots([
      ...oldSlots.filter(replaceSlot => replaceSlot.index !== replacementSlot.index), replacementSlot
    ]);
    this.portfolioReports.next(newSlots);
  }

  private sortPortfolioReportSlots(slots: GetPortfolioReports[]): GetPortfolioReports[] {
    return slots.sort((a, b) => a.index - b.index);
  }

  loadArchivedPortfolioReportsPage(offset: number, limit: number): Promise<Page<GetPortfolioReports>> {
    const url = 'api/portfolio-reports/archive';
    const options = {
      params: new HttpParams({
        fromObject: {
          offset: offset.toString(),
          limit: limit.toString(),
        }
      }),
    };
    return firstValueFrom(this.client.get<Page<GetPortfolioReports>>(url, options));
  }

  loadAllActiveTargetSettings(): Promise<GetTargetSettings[]> {
    return firstValueFrom(this.client.get<GetTargetSettings[]>('api/target-settings'))
      .then(settings => this.sortTargetSettings(settings));
  }

  uploadTargetSetting(type: TargetType, fileToUpload: File): Promise<GetTargetSettings> {
    const url = `api/target-settings/${type}`;
    const formData = new FormData();
    formData.append('file', fileToUpload);
    return firstValueFrom(this.client.put<GetTargetSettings>(url, formData));
  }

  uploadTargetSettingForDate(type: TargetType, year: number, month: number, fileToUpload: File): Promise<GetTargetSettings> {
    const url = `api/target-settings/${type}/${year}/${month}`;
    const formData = new FormData();
    formData.append('file', fileToUpload);
    return firstValueFrom(this.client.put<GetTargetSettings>(url, formData));
  }

  archiveTargetSetting(type: TargetType): Promise<GetTargetSettings> {
    const url = `api/target-settings/${type}`;
    return firstValueFrom(this.client.delete<GetTargetSettings>(url));
  }

  private sortTargetSettings(settings: GetTargetSettings[]): GetTargetSettings[] {
    return settings.sort((a, b) => targetTypeIndex[a.type] - targetTypeIndex[b.type]);
  }

  loadArchivedTargetSettingsPageForType(targetType: TargetType, offset: number, limit: number): Promise<Page<GetTargetSettings>> {
    const url = `api/target-settings/archive/${targetType}`;
    const options = {
      params: new HttpParams({
        fromObject: {
          offset: offset.toString(),
          limit: limit.toString(),
        }
      }),
    };
    return firstValueFrom(this.client.get<Page<GetTargetSettings>>(url, options));
  }

}
