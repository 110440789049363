import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'digires-infinity-scroll',
  templateUrl: './infinity-scroll.component.html',
  styleUrls: ['./infinity-scroll.component.scss']
})
export class InfinityScrollComponent {
  lastEmitStamp: number = Date.now();
  shouldObserveValue: boolean;

  @Input() set shouldObserve (shouldObserve: boolean) {
    this.shouldObserveValue = shouldObserve;
  }

  @Input() set hasEntities (hasEntities: any[]) {
    if (hasEntities && hasEntities.length && !this.isInitialized) {
      if (this.bottom && this.bottom.nativeElement) {
        const intersectionObserver: IntersectionObserver = new IntersectionObserver(() => {
          if (this.isInitialized && (Date.now() - this.lastEmitStamp) > 99 && this.shouldObserveValue) {
            this.step += 1;
            this.changeStep.emit(this.step);
            this.lastEmitStamp = Date.now();
          }

          this.isInitialized = true;
        });
        
        intersectionObserver.observe(this.bottom.nativeElement);
      }
    }
  }
  @Output() changeStep: EventEmitter<number> = new EventEmitter();

  @ViewChild('bottom') bottom: ElementRef<HTMLDivElement>;
  
  step: number = 0;

  isInitialized: boolean = false;
}
