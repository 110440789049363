import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractInputDirective } from '../AbstractInputDirective';
import { ReadOnlyReason, ReadOnlyReasonToTooltip } from '../../../project-details/ReadOnlyStateManager';

@Component({
  selector: 'digires-fake-input',
  templateUrl: './fake-input.component.html',
  styleUrls: ['./fake-input.component.scss']
})
export class FakeInputComponent extends AbstractInputDirective implements OnChanges {

  public readonly highlightTypes: typeof HighlightType = HighlightType;

  @Input() value: string;
  @Input() highlight: HighlightType = HighlightType.NEUTRAL;
  @Input() readOnlyReasonEnum: ReadOnlyReason;
  @Input() showIndicator: boolean = true;

  override ngOnChanges(changes: SimpleChanges) {
    this.readOnlyReason = changes.readOnlyReason?.currentValue
      || ReadOnlyReasonToTooltip[changes.readOnlyReasonEnum?.currentValue]
      || this.readOnlyReason
      || ReadOnlyReasonToTooltip[ReadOnlyReason.FEED_IN];
    super.ngOnChanges(changes);
  }

}
export enum HighlightType {
  NEUTRAL = 'NEUTRAL',
  GOOD = 'GOOD',
  BAD = 'BAD',
}
