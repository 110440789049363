export enum NewsCategory {
  STATUS_UPDATE = 'STATUS_UPDATE',
  HIGHLIGHT = 'HIGHLIGHT',
  RISK = 'RISK',
  REPORT_COMMENT = 'REPORT_COMMENT',
  REPORT_VALIDATION_PMD = 'REPORT_VALIDATION_PMD',
  REPORT_VALIDATION_TLD = 'REPORT_VALIDATION_TLD',
  REPORT_VALIDATION_PMC = 'REPORT_VALIDATION_PMC',
  REPORT_VALIDATION_PPU = 'REPORT_VALIDATION_PPU',
}
export const newsCategoryToLabel: {[newsCategory: string]: string} = {
  [NewsCategory.STATUS_UPDATE]: 'Status Update',
  [NewsCategory.HIGHLIGHT]: 'Highlight',
  [NewsCategory.RISK]: 'Risk',
  [NewsCategory.REPORT_COMMENT]: 'Report – Comment PMD',
  [NewsCategory.REPORT_VALIDATION_PMD]: 'Report – Data Validation PMD',
  [NewsCategory.REPORT_VALIDATION_TLD]: 'Report – Data Validation TLD',
  [NewsCategory.REPORT_VALIDATION_PMC]: 'Report – Data Validation PMC',
  [NewsCategory.REPORT_VALIDATION_PPU]: 'Project Pipeline Update Report',
};
