import { AfterViewInit, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { DateUtil } from 'src/app/shared/DateUtil';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { v4 } from 'uuid';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'digires-file-ref-table',
  templateUrl: './file-ref-table.component.html',
  styleUrls: ['./file-ref-table.component.scss']
})
export class FileRefTableComponent implements OnChanges, AfterViewInit {
  readonly destroy$: Subject<boolean> = new Subject<boolean>();

  formGroup: FormGroup = new FormGroup({});

  displayedColumns: string[] = [
    'ref',
    'title',
    'link',
    'information',
    'action',
  ];

  dataSource: MatTableDataSource<FileReferenceData>;

  @Input() title: string;
  @Input() inputTableData: FileReferenceData[] = [];
  @Input() isReadonly: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<FileReferenceData>;

  constructor(
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private userService: UserService,
  ) {
    this.dataSource = new MatTableDataSource(this.inputTableData?.slice());
  }

  ngOnInit() {
    this.userService
      .observeUserMap()
      .pipe(takeUntil(this.destroy$))
      .subscribe(map => {
        for (let el of this.dataSource.data) {
          el.lastUpdatedUsername = map[el.lastUpdatedByUserId]?.fullName ?? el.lastUpdatedByUserId;
          el.originalUploadUsername = map[el.originalUploadByUserId]?.fullName ?? el.originalUploadByUserId;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges() {
    const userIds = this.inputTableData
      .map(d => d.originalUploadByUserId)
      .concat(this.inputTableData
        .filter(d => !!d.lastUpdatedByUserId)
        .map(d => d.lastUpdatedByUserId)
      );
    this.userService.fetchUsersById(userIds);

    this.dataSource.data = this.inputTableData?.slice();

    if (this.paginator && this.sort) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    this.syncFormGroupWithDataSource();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private syncFormGroupWithDataSource() {
    for (const element of this.dataSource.data) {
      const id = element.id ? element.id : v4();
      this.formGroup.addControl(id, this.createElementFormGroup(element));
    }
  }

  private createElementFormGroup(fileRef: FileReferenceData): FormGroup {
    const { id, ref, title, link } = fileRef;
    return this.fb.group({
      id: [id],
      ref: [ref, [Validators.required, Validators.minLength(1)]],
      title: [title, [Validators.required, Validators.minLength(1)]],
      link: [
        link,
        [
          Validators.required,
          Validators.pattern(/^(https:\/\/rwe.sharepoint.com\/|I:\\)/),
        ],
      ],
      lastUpdatedByUserId: fileRef.lastUpdatedByUserId,
      lastUpdatedTime: fileRef.lastUpdatedTime,
      originalUploadByUserId: fileRef.originalUploadByUserId,
      originalUploadTime: fileRef.originalUploadTime,
    });
  }

  isChanged(fileRef: FileReferenceData) {
    const formElement = this.formGroup.get(fileRef.id).value;
    const originElement = this.dataSource.data.find(element => element.id === fileRef.id);

    return (
      formElement.ref !== originElement?.ref ||
      formElement.title !== originElement?.title ||
      formElement.link !== originElement?.link
    );
  }

  isFieldChanged(fileRef: FileReferenceData, field: string) {
    const formElement = this.formGroup.get(fileRef.id).value;
    const originElement = this.dataSource.data.find(element => element.id === fileRef.id);
    return formElement[field] !== originElement[field];
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDeleteConfirmationDialog(element: FileReferenceData) {
    this.dialog.open(
      ConfirmDialogComponent,
      {
        hasBackdrop: true,
        data: {
          title: `Delete Link`,
          message: `Do you really want to delete this link? ${element.title}`,
          actions: [
            {
              id: 'cancel',
              label: 'Cancel',
              tooltip: 'Keep the link',
              disabled: false,
              flair: 'neutral',
            },
            {
              id: 'delete',
              label: 'Delete',
              tooltip: 'Delete the link',
              disabled: false,
              flair: 'negative',
            },
          ],
        },
      }
    )
      .afterClosed()
      .subscribe(value => {
        if (value === 'delete') {
          this.removeItem(element.id);
          const indexToBeUpdated = this.dataSource.data.findIndex(e => e.id === element.id);
          this.dataSource.data.splice(indexToBeUpdated, 1);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.table.renderRows();

          console.log('formGroup=', this.formGroup);
        }
      });
  }

  addNewRow() {
    const newRow: FileReferenceData = {
      id: v4(),
      ref: '',
      title: '',
      link: '',
      lastUpdatedByUserId: '',
      lastUpdatedTime: null,
      lastUpdatedUsername: '',
      originalUploadByUserId: '',
      originalUploadTime: null,
      originalUploadUsername: ''
    }

    const elementFormGroup = this.createElementFormGroup(newRow);
    this.formGroup.addControl(newRow.id, elementFormGroup);
    this.dataSource.data.push(newRow);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.table.renderRows();
  }

  async saveData(userGuideTableData: FileReferenceData) {
    const indexToBeUpdated = this.dataSource.data.findIndex(e => e.id === userGuideTableData.id);

    const requestData = userGuideTableData;
    const createdData = {
      id: requestData.id,
      ref: requestData.ref,
      title: requestData.title,
      link: requestData.link,
      lastUpdatedByUserId: requestData.lastUpdatedByUserId,
      lastUpdatedTime: requestData.lastUpdatedTime,
      lastUpdatedUsername: requestData.lastUpdatedUsername,
      originalUploadByUserId: requestData.originalUploadByUserId,
      originalUploadTime: requestData.originalUploadTime,
      originalUploadUsername: requestData.originalUploadUsername,
    };

    this.removeItem(userGuideTableData.id);
    this.addItem(createdData);

    this.dataSource.data[indexToBeUpdated] = createdData;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.table.renderRows();
  }

  addItem(rowData: FileReferenceData) {
    const formArrayElement = this.createElementFormGroup(rowData);
    this.formGroup.addControl(rowData.id, formArrayElement);
  }

  removeItem(id: string) {
    this.formGroup.removeControl(id);
  }

  getText(el: FileReferenceData) {
    const {
      originalUploadUsername,
      originalUploadTime,
      lastUpdatedUsername,
      lastUpdatedTime,
    } = el;

    if (
      !originalUploadUsername &&
      !originalUploadTime &&
      !lastUpdatedUsername &&
      !lastUpdatedTime
    ) {
      return 'No Data to show';
    }
    return `
    added by: ${originalUploadUsername}
    added on: ${
      DateUtil.ddmmyyyyHHMM_TZ(DateUtil.anyToDate(originalUploadTime))
    }
    last updated by: ${lastUpdatedUsername}
    last updated on: ${
      DateUtil.ddmmyyyyHHMM_TZ(DateUtil.anyToDate(lastUpdatedTime))
    }`;
  }

  getSanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public hasErrorPattern(element: FileReferenceData, key: string) {
    return this.formGroup.get([element.id, key]).hasError('pattern')
  }

  public hasErrorRequired(element: FileReferenceData, key: string) {
    return this.formGroup.get([element.id, key]).hasError('required')
  }

  public isValid(element: FileReferenceData) {
    return this.formGroup.get(element.id).valid;
  }
}


export interface FileReferenceData {
  id: string;
  ref: string;
  title: string;
  link: string;
  lastUpdatedByUserId: string;
  lastUpdatedTime: Date;
  lastUpdatedUsername: string;
  originalUploadByUserId: string;
  originalUploadTime: Date;
  originalUploadUsername: string;
}
