export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
  SOLD = 'SOLD',
}
export const statusToLabel: {[value: string]: string} = {
  [ProjectStatus.ACTIVE]: 'Active',
  [ProjectStatus.ON_HOLD]: 'On Hold',
  [ProjectStatus.REJECTED]: 'Rejected',
  [ProjectStatus.DELETED]: 'Deleted',
  [ProjectStatus.SOLD]: 'Sold',
};
