import { PutSettingsAutomaticTableReport } from 'src/app/shared/model/reports/admin_area/PutSettingsAutomaticTableReport';
import { PostTableReport } from './PostTableReport';
import { Injectable } from '@angular/core';
import {ProjectFilter} from '../../shared/model/ProjectFilter';
import {TableReportProject} from './TableReportProject';
import {HttpClient, HttpParams} from '@angular/common/http';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { ProjectFilterService } from '../../shared/services/project-list/project-filter.service';
import { Chunk } from 'src/app/shared/services/Chunk';
import { Cache } from 'src/app/shared/services/Cache';
import { BulkLoadService } from 'src/app/shared/services/bulk-load.service';
import { TableReportSelectableColumn } from 'src/app/shared/model/reports/TableReportSelectableColumn';

@Injectable({
  providedIn: 'root'
})
export class TableReportService {

  private readonly cache = new Cache<TableReportProject>(p => p.id);

  constructor(
    private client: HttpClient,
    private filterService: ProjectFilterService,
    private bulkLoader: BulkLoadService,
  ) { }

  invalidateProjectCache(projectId: string) {
    this.cache.invalidateOne(projectId);
  }

  fetchProjectsByFilter(filter: ProjectFilter): Observable<Chunk<TableReportProject>> {
    const subject = new Subject<Chunk<TableReportProject>>();

    this.filterService.getProjectIdsFor(filter).then(projectIds => {
      let lastChunk: Chunk<TableReportProject> = null;
      this.fetchProjectsByIds(projectIds).subscribe({
        next: value => {
          lastChunk = value;
          subject.next(value);
        },
        error: error => subject.error(error),
        complete: () => {
          lastChunk = {
            expectedCount: lastChunk?.expectedCount || 0,
            loadedCount: lastChunk?.loadedCount || 0,
            loadedElements: lastChunk?.loadedElements || [],
            finished: true,
          };
          subject.next(lastChunk);
          subject.complete();
        }
      });
    })
    .catch(error => {
      subject.error(error);
      subject.complete();
    });
    return subject;
  }

  getAllColumns() {
    return firstValueFrom(
      this.client.get<TableReportSelectableColumn[]>("/api/table-report/columns")
    );
  }

  getAllColumnsById(selectableColumns: string[]) {
    return firstValueFrom(
      this.client.get<TableReportSelectableColumn[]>("/api/table-report/columns", {
        params: new HttpParams({
          fromObject: {
            selectableColumns: selectableColumns,
          }
        })
      })
    );
  }

  demandTableReportCreation(postTableReport: PostTableReport) {
    return firstValueFrom(
      this.client.post<void>('/api/table-report', postTableReport)
    );
  }

  updateTableReportUpdateSettings(automaticReport: PutSettingsAutomaticTableReport) {
    return firstValueFrom(
      this.client.put<void>('/api/settings/automatic-table-report', automaticReport)
    );
  }

  getTableReportUpdateSettings() {
    return firstValueFrom(
      this.client.get<PutSettingsAutomaticTableReport>('/api/settings/automatic-table-report')
    );
  }

  private loadAllProjects(projectIds: string[]): Observable<Chunk<TableReportProject>> {
    return this.bulkLoader.chunkedLoading<TableReportProject>('/api/table-report/projects', 'projectIds', projectIds);
  }

  fetchProjectsByIds(projectIds: string[]): Observable<Chunk<TableReportProject>> {
    return this.cache.getAllChunked(
      projectIds,
      this.loadAllProjects.bind(this),
    );
  }
}
