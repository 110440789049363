import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ProjectTypeImageComponent } from "./project-type-image.component";

@NgModule({
    declarations: [ProjectTypeImageComponent],
    exports: [ProjectTypeImageComponent],
    imports: [CommonModule]
})
export class ProjectTypeImageModule {}

