import { UserGuidesData } from 'src/app/shared/model/userGuide/UserGuideData';
import { Component, Input, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { FncGroup } from 'src/app/auth/FncGroup';
import { AuthService } from 'src/app/auth/auth.service';
import { v4 } from 'uuid';
import { DateUtil } from '../../DateUtil';
import {
  FileReferenceType,
  UserGuidesTableData,
  PostPutReferenceRequest,
} from '../../model/user-guides-table.model';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { UserGuidesService } from '../../services/user-guide/user-guides.service';

@Component({
  selector: 'digires-user-guides-table',
  templateUrl: './user-guides-table.component.html',
  styleUrls: ['./user-guides-table.component.scss'],
})
export class UserGuidesTableComponent implements OnChanges, AfterViewInit {
  formGroup: FormGroup = new FormGroup({});

  displayedColumns: string[] = [
    'ref',
    'title',
    'type',
    'link',
    'information',
  ];

  isItSupport: boolean = this.authService.isInGroup(FncGroup.IT_SUPPORT);
  dataSource: MatTableDataSource<UserGuidesTableData>;

  @Input() title: string;
  @Input() fileReferenceType: FileReferenceType;
  @Input() addButtonTitle: string;
  @Input() inputTableData: UserGuidesTableData[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<UserGuidesTableData>;

  constructor(
    private userGuidesService: UserGuidesService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private authService: AuthService,
  ) {
    this.dataSource = new MatTableDataSource(this.inputTableData?.slice());

    if(this.isItSupport) {
      this.displayedColumns.push('action');
    }
  }

  ngOnChanges() {
    this.dataSource.data = this.inputTableData?.slice();

    if (this.paginator && this.sort) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    this.syncFormGroupWithDataSource();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private syncFormGroupWithDataSource() {
    for (const element of this.dataSource.data) {
      this.formGroup.addControl(element.id, this.createElementFormGroup(element));
    }
  }

  private createElementFormGroup(fileRef: UserGuidesTableData): FormGroup {
    const { id, ref, title, type, link } = fileRef;
    return this.fb.group({
      id: [id],
      ref: [ref, [Validators.required, Validators.minLength(1)]],
      title: [title, [Validators.required, Validators.minLength(1)]],
      type: [type],
      link: [
        link,
        [
          Validators.required,
          Validators.pattern(/^(https:\/\/rwe.sharepoint.com\/|I:\\)/),
        ],
      ],
    });
  }

  isChanged(fileRef: UserGuidesTableData) {
    const formElement = this.formGroup.get(fileRef.id).value;
    const originElement = this.dataSource.data.find(element => element.id === fileRef.id);

    return (
      formElement.ref !== originElement?.ref ||
      formElement.title !== originElement?.title ||
      formElement.type !== originElement?.type ||
      formElement.link !== originElement?.link
    );
  }

  isFieldChanged(fileRef: UserGuidesTableData, field: string) {
    const formElement = this.formGroup.get(fileRef.id).value;
    const originElement = this.dataSource.data.find(element => element.id === fileRef.id);

    return formElement[field] !== originElement[field];
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDeleteConfirmationDialog(element: UserGuidesTableData) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      data: {
        id: element.id,
        name: element.title,
        title: this.title
      }
    }).afterClosed().subscribe((data) => {
      if (data?.id) {
        this.dataSource.data = this.dataSource.data.filter(
          (x) => data.id !== x.id
        );
        this.removeItem(data.id);
      }
    });
  }

  addNewRow() {
    const newRow: UserGuidesTableData = {
      id: v4(),
      ref: '',
      title: '',
      type: this.fileReferenceType,
      link: '',
      action: 'add',
      lastUpdatedByUserId: '',
      lastUpdatedTime: '',
      lastUpdatedUsername: '',
      originalUploadByUserId: '',
      originalUploadTime: '',
      originalUploadUsername: ''
    }

    const elementFormGroup = this.createElementFormGroup(newRow);
    this.formGroup.addControl(newRow.id, elementFormGroup);
    this.dataSource.data.push(newRow);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.table.renderRows();
  }

  async saveData(userGuideTableData: UserGuidesTableData) {
    const { ref, title, type, link } = this.formGroup.get(userGuideTableData.id).value;

    const request: PostPutReferenceRequest = {ref, title, type, link};
    const indexToBeUpdated = this.dataSource.data.findIndex(e => e.id === userGuideTableData.id);

    if (userGuideTableData.action === 'edit') {
      const editedData = await this.userGuidesService.editUserGuidesVideo(userGuideTableData.id, request)
      if (indexToBeUpdated < 0) {
        return;
      }
      const editedTableData = {
        id: editedData.id,
        ref: editedData.ref,
        title: editedData.title,
        type: editedData.type,
        link: editedData.link,
        action: null,
        lastUpdatedByUserId: editedData.lastUpdatedByUserId,
        lastUpdatedTime: DateUtil.ddmmyyyy(editedData.lastUpdatedTime),
        lastUpdatedUsername: editedData.lastUpdatedUsername,
        originalUploadByUserId: editedData.originalUploadByUserId,
        originalUploadTime: DateUtil.ddmmyyyy(editedData.originalUploadTime),
        originalUploadUsername: editedData.originalUploadUsername,
      };
      this.dataSource.data[indexToBeUpdated] = editedTableData;

      this.updateItem(userGuideTableData.id, editedTableData);
    } else {
      const requestData = await this.userGuidesService.createUserGuidesVideo(request);
      const createdData = {
        id: requestData.id,
        ref: requestData.ref,
        title: requestData.title,
        type: requestData.type,
        link: requestData.link,
        action: null,
        lastUpdatedByUserId: requestData.lastUpdatedByUserId,
        lastUpdatedTime: DateUtil.ddmmyyyy(requestData.lastUpdatedTime),
        lastUpdatedUsername: requestData.lastUpdatedUsername,
        originalUploadByUserId: requestData.originalUploadByUserId,
        originalUploadTime: DateUtil.ddmmyyyy(requestData.originalUploadTime),
        originalUploadUsername: requestData.originalUploadUsername,
      };
      this.removeItem(userGuideTableData.id);
      this.addItem(createdData);

      this.dataSource.data[indexToBeUpdated] = createdData;
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.table.renderRows();
    userGuideTableData.action = null;
  }

  discard(el: UserGuidesTableData) {
    const { action, id } = el;
    if (action === 'add') {
      this.dataSource.data = this.dataSource.data.filter(
        (row) => row?.id !== id
      );
      this.removeItem(id);
    } else {
      const originEl = this.dataSource.data.find((element) => element.id === id);
      this.updateItem(id, originEl);
    }

    el.action = null;
  }

  addItem(rowData: UserGuidesTableData) {
    const formArrayElement = this.createElementFormGroup(rowData);
    this.formGroup.addControl(rowData.id, formArrayElement);
  }

  updateItem(id: string, item: UserGuidesTableData) {
    this.formGroup.get(id).patchValue({ ...item });
  }

  removeItem(id: string) {
    this.formGroup.removeControl(id);
  }

  getText(el: UserGuidesTableData) {
    const {
      originalUploadUsername,
      originalUploadTime,
      lastUpdatedUsername,
      lastUpdatedTime,
    } = el;

    if (
      !originalUploadUsername &&
      !originalUploadTime &&
      !lastUpdatedUsername &&
      !lastUpdatedTime
    ) {
      return 'No Data to show';
    }

    return `
    added by: ${originalUploadUsername}
    added on: ${
      DateUtil.ddmmyyyyHHMM_TZ(DateUtil.anyToDate(originalUploadTime))
    } UTC
    last updated by: ${lastUpdatedUsername}
    last updated on: ${
      DateUtil.ddmmyyyyHHMM_TZ(DateUtil.anyToDate(lastUpdatedTime))
    } UTC`;
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public hasErrorPattern(element: UserGuidesTableData, key: string) {
    return this.formGroup.get([element.id, key]).hasError('pattern')
  }

  public hasErrorRequired(element: UserGuidesTableData, key: string) {
    return this.formGroup.get([element.id, key]).hasError('required')
  }

  public isValid(element: UserGuidesTableData) {
    return this.formGroup.get(element.id).valid;
  }
}
