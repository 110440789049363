import { revid, version } from './version';

export const environment = {
  production: false,
  name: 'preview-beta',
  title: 'DigiRES preview beta',
  showMap: true,

  version: version,
  revid: revid,
  apiURL: '/api/*',
  udpURL: 'https://udp-preview-beta.ext.np.renewables-apps.com/',
};
