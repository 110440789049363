<form [formGroup]="deepFormGroup">
  <digires-state-indicator [formCtrlName]="fullFormCtrlName" [readOnly]="readOnly" [readOnlyReason]="readOnlyReason" [finModel]="finModel"></digires-state-indicator>
  <mat-form-field [ngClass]="{'changed':isDirty()}">
    <label>
      <input matInput type="text"
             digiresThousandSeparator
             [decimalDigits]="decimalDigits"
             formControlName="{{formCtrlName}}"
             placeholder="{{placeholder}}"
             [required]="required && !readOnly"
             [readonly]="readOnly"
             (change)="onInputChanged()">
    </label>

    <mat-error>
      <div *ngIf="error">{{error}}</div>
      <ng-container *ngFor="let err of errors">
        <div *ngIf="deepFormGroup.controls[formCtrlName].hasError(err.id)">{{err.message}}</div>
      </ng-container>
    </mat-error>
  </mat-form-field>
  <mat-icon *ngIf="!!tooltip" class="tooltip-icon" matTooltipPosition="above" matTooltip="{{tooltip}}" svgIcon="tooltip"></mat-icon>
  <mat-icon *ngIf="!!finModel" class="warning-triangle" [class.transparent]="!finModel.hasUpload || !finModel.isReadable(fullFormCtrlName) || finModel.isRead(fullFormCtrlName)"
            matTooltip="Note: The value in this field was not populated by the most recent upload of the financial model">
    warning
  </mat-icon>
</form>
