<span *ngIf="!readOnly && (!finModel || !finModel.isReadable(formCtrlName))"
      class="dw-state-indicator"
></span>
<span *ngIf="finModel && !readOnly && finModel.isReadable(formCtrlName)"
      class="dw-state-indicator financial-model"
      matTooltipPosition="above"
      matTooltip="The value in this data field could be read from the Financial Model but in this case it hasn't been
      populated by the most recent upload so it can be manually overwritten"
></span>
<span *ngIf="readOnly"
      class="dw-state-indicator readonly"
      matTooltipPosition="above"
      [matTooltip]="readOnlyReason"
></span>
