import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AbstractInputDirective} from '../AbstractInputDirective';

@Component({
  selector: 'digires-multi-list-input',
  templateUrl: './multi-list-input.component.html',
  styleUrls: ['./multi-list-input.component.scss']
})
export class MultiListInputComponent extends AbstractInputDirective implements OnInit, OnDestroy {
  readonly destroy$: Subject<boolean> = new Subject<boolean>();

  public readonly NO_ITEMS = {dummy: -1};
  public readonly ALL_ITEMS = {dummy: 1};

  @ViewChild('input') inputElement: MatSelect;

  internalFormGroup: FormGroup;
  prevSelectedItems: any[];
  isPatchingFg: boolean = false;
  @Input() allItemName: string;
  @Input() noItemName: string;
  @Input() multiSelect: boolean = false;
  @Input() items: any[] = [];
  @Input() itemToId: ((value: any) => any) = value => value;
  @Input() itemToLabel: ((value: any) => string) = value => value;

  override ngOnInit(): void {
    super.ngOnInit();
    this.internalFormGroup = new FormGroup({multiSelect: new FormControl()});
    this.getCtrl()
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(newValue => {
        if (!this.isPatchingFg) {
          this.syncFormGroups(newValue);
        }
      });
    this.syncFormGroups(this.getValue());
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getReadonlyValue(): string {
    const formValue = this.internalFormGroup.value.multiSelect;
    if (!formValue) {
      return null;
    }
    if (this.multiSelect) {
      const formValues: any[] = formValue;
      if (this.allItemName && formValues.includes(this.ALL_ITEMS)) {
        return this.allItemName;
      }
      if (this.noItemName && formValues.includes(this.NO_ITEMS)) {
        return this.noItemName;
      }
      const itemValues: any[] = this.items.filter(item => formValues.includes(this.itemToId(item)));
      return itemValues.map(item => this.itemToLabel(item)).join(', ');
    } else {
      const itemValue = this.items?.find(item => this.itemToId(item) === formValue);
      if (!itemValue) {
        return null;
      }
      return this.itemToLabel(itemValue);
    }
  }

  private syncFormGroups(newValue: any) {
    this.isPatchingFg = true;
    const currentValue = newValue
      ? newValue.map(v => this.itemToId(v))
      : this.items.map(v => this.itemToId(v));
    if (newValue !== currentValue) {
      this.patch(currentValue);
    }
    if (currentValue && currentValue.length === 0 && this.noItemName) {
      this.prevSelectedItems = [this.NO_ITEMS];
    } else if (currentValue.length === this.items.length && this.allItemName) {
      this.prevSelectedItems = [this.ALL_ITEMS];
    } else {
      this.prevSelectedItems = currentValue;
    }
    this.internalFormGroup.patchValue({multiSelect: this.prevSelectedItems});
    this.isPatchingFg = false;
  }

  onItemSelected(event: MatSelectChange): void {
    this.isPatchingFg = true;
    if (event.value.includes(this.ALL_ITEMS) && !this.prevSelectedItems.includes(this.ALL_ITEMS)) {
      // ALL_ITEMS has just been selected => unselect everything else

      this.internalFormGroup.patchValue({multiSelect: [this.ALL_ITEMS]});
      this.patch(this.items.map(v => this.itemToId(v)));

    } else if (event.value.includes(this.NO_ITEMS) && !this.prevSelectedItems.includes(this.NO_ITEMS)) {
      // NO_ITEMS has just been selected => unselect everything else

      this.internalFormGroup.patchValue({multiSelect: [this.NO_ITEMS]});
      this.patch([]);

    } else if (event.value.length === 0) {
      // no item has been selected => either select NO_ITEMS or ALL_ITEMS

      if (this.noItemName) {
        this.internalFormGroup.patchValue({multiSelect: [this.NO_ITEMS]});
        this.patch([]);
      } else if (this.allItemName) {
        this.internalFormGroup.patchValue({multiSelect: [this.ALL_ITEMS]});
        this.patch(this.items.map(v => this.itemToId(v)));
      } else {
        this.patch(this.internalFormGroup.value.multiSelect);
      }

    } else if (event.value.length > 1 && (event.value.includes(this.ALL_ITEMS) || event.value.includes(this.NO_ITEMS))) {
      // selected another item in addition to ALL_ITEMS or NO_ITEMS => unselect ALL_ITEMS and NO_ITEMS

      const filteredItems = event.value.filter(item => item !== this.NO_ITEMS && item !== this.ALL_ITEMS);
      this.internalFormGroup.patchValue({multiSelect: filteredItems});
      this.patch(filteredItems.map(v => this.itemToId(v)));

    } else if (event.value.length === this.items.length && this.allItemName) {
      // all items have been selected manually => select ALL_ITEMS
      // (NO_ITEMS and ALL_ITEMS can not be included in the value because of previous cases)

      this.internalFormGroup.patchValue({multiSelect: [this.ALL_ITEMS]});
      this.patch(this.items.map(v => this.itemToId(v)));

    } else {
      // just patch through to external FormGroup
      this.patch(this.internalFormGroup.value.multiSelect);

    }
    this.prevSelectedItems = this.internalFormGroup.value.multiSelect;
    this.isPatchingFg = false;
  }

  requestFocusForInput() {
    if (this.inputElement) {
      this.inputElement.focus();
      this.inputElement.open();
    }
  }

}
