import { GridConnection } from '../../model/technical/GridConnection';
import { UnitTypeSolar } from '../../model/technical/UnitTypeSolar';
import { UnitTypeStorage } from '../../model/technical/UnitTypeStorage';
import { UnitTypeWindOnshore } from '../../model/technical/UnitTypeWindOnshore';

export interface GetProjectsTechnicalData {
  projectId: string;
  totalCapacity: number;
  shareNow: number;
  shareAtCod: number;
  attrition: number;
  success: number;
  cumulativeSuccess: number;

  wind: TechDataWind;
  solar: TechDataSolar;
  storage: TechDataStorage;
  grid: TechDataGrid;

  assumedAssetLife: number;
  averageMinimumLifetime: number;
  aepTotalP50Net: number;
  aepTotalP50Gross: number;
  aepTotalP75Net: number;
  aepTotalP75Gross: number;

  calculated: TechnicalCalculated;
}

export interface TechDataGrid {
  units: GridConnection[];
  gridA: GridConnection;
  gridB: GridConnection;
  gridC: GridConnection;
  gridD: GridConnection;
}

export interface TechDataWind {
  fullLoadHours: number;
  capacityFactor: number;
  units: UnitTypeWindOnshore[];
  unitA: UnitTypeWindOnshore;
  unitB: UnitTypeWindOnshore;
  unitC: UnitTypeWindOnshore;
  unitD: UnitTypeWindOnshore;
}
export interface TechDataSolar {
  totalCapacityMw: number;
  totalCapacityMwp: number;
  irradiation: number;
  energyYield: number;
  units: UnitTypeSolar[];
  unitA: UnitTypeSolar;
  unitB: UnitTypeSolar;
  unitC: UnitTypeSolar;
  unitD: UnitTypeSolar;
}
export interface TechDataStorage {
  totalCapacityMw: number;
  totalCapacityMwh: number;
  units: UnitTypeStorage[];
  unitA: UnitTypeStorage;
  unitB: UnitTypeStorage;
  unitC: UnitTypeStorage;
  unitD: UnitTypeStorage;
}
export interface TechnicalCalculated {
  totalCapacity: number;
  shareNow: number;
  shareAtCod: number;
  totalUnitCountWind: number;
  totalCapacityWind: number;

  activeUnitTypesWind: number;
  activeUnitTypesSolar: number;
  activeUnitTypesStorage: number;

  capacityTotal: TechnicalCalculatedCapacity;
  capacityWind: TechnicalCalculatedCapacity;
  capacitySolarMw: TechnicalCalculatedCapacity;
  capacitySolarMwp: TechnicalCalculatedCapacity;
  capacityStorageMw: TechnicalCalculatedCapacity;
  capacityStorageMwh: TechnicalCalculatedCapacity;
}
export interface TechnicalCalculatedCapacity {
  consolidatedAtCodAfterAttrition: number;
  consolidatedAtCodBeforeAttrition: number;
  consolidatedNowAfterAttrition: number;
  consolidatedNowBeforeAttrition: number;

  proRataAtCodAfterAttrition: number;
  proRataAtCodBeforeAttrition: number;
  proRataNowAfterAttrition: number;
  proRataNowBeforeAttrition: number;

  totalAtCodAfterAttrition: number;
  totalAtCodBeforeAttrition: number;
  totalNowAfterAttrition: number;
  totalNowBeforeAttrition: number;
}
export function calculateDerivedValues(data: GetProjectsTechnicalData) {
  let totalUnitCountWind = 0;
  let totalCapacityWind = 0;
  for (const unit of data.wind.units) {
    const count = unit.count || 0;
    const capacity = unit.capacity || 0;
    totalUnitCountWind += count;
    totalCapacityWind += capacity * count;
  }
  const totalCapacitySolarMw = data.solar.totalCapacityMw || 0;
  const totalCapacitySolarMwp = data.solar.totalCapacityMwp || 0;
  const totalCapacityStorageMw = data.storage.totalCapacityMw || 0;
  const totalCapacityStorageMwh = data.storage.totalCapacityMwh || 0;
  const shareAtCod = (data.shareAtCod || 0) / 100.0;
  const shareNow = (data.shareNow || 0) / 100.0;
  const cumulativeSuccess = data.cumulativeSuccess || 0;

  const calculateCapacity: (total: number) => TechnicalCalculatedCapacity =
    function (total: number) {
      return {
        consolidatedAtCodAfterAttrition:
          shareAtCod >= 0.5
            ? total * cumulativeSuccess
            : total * shareAtCod * cumulativeSuccess,
        consolidatedAtCodBeforeAttrition:
          shareAtCod >= 0.5 ? total : total * shareAtCod,
        consolidatedNowAfterAttrition:
          shareNow >= 0.5
            ? total * cumulativeSuccess
            : total * shareNow * cumulativeSuccess,
        consolidatedNowBeforeAttrition:
          shareNow >= 0.5 ? total : total * shareNow,

        proRataAtCodAfterAttrition: total * shareAtCod * cumulativeSuccess,
        proRataAtCodBeforeAttrition: total * shareAtCod,
        proRataNowAfterAttrition: total * shareNow * cumulativeSuccess,
        proRataNowBeforeAttrition: total * shareNow,

        totalAtCodAfterAttrition: total * cumulativeSuccess,
        totalAtCodBeforeAttrition: total,
        totalNowAfterAttrition: total * cumulativeSuccess,
        totalNowBeforeAttrition: total,
      };
    };

  data.calculated = {
    totalCapacity: totalCapacityWind + totalCapacitySolarMw + totalCapacityStorageMw,
    shareNow: shareNow,
    shareAtCod: shareAtCod,
    totalUnitCountWind: totalUnitCountWind,
    totalCapacityWind: totalCapacityWind,

    activeUnitTypesWind: data.wind.units.filter((unit) => unit.active).length,
    activeUnitTypesSolar: data.solar.units.filter((unit) => unit.active).length,
    activeUnitTypesStorage: data.storage.units.filter((unit) => unit.active).length,

    capacityTotal: calculateCapacity(
      totalCapacityWind + totalCapacitySolarMw + totalCapacityStorageMw
    ),
    capacityWind: calculateCapacity(totalCapacityWind),
    capacitySolarMw: calculateCapacity(totalCapacitySolarMw),
    capacitySolarMwp: calculateCapacity(totalCapacitySolarMwp),
    capacityStorageMw: calculateCapacity(totalCapacityStorageMw),
    capacityStorageMwh: calculateCapacity(totalCapacityStorageMwh),
  };
}
