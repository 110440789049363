import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {GetTeamRoles} from './GetTeamRoles';
import {Cache} from '../Cache';
import {NotificationService} from '../other/notification.service';
import {ProjectPhase} from '../../model/general/ProjectPhase';
import {NonDeletableRoleId} from './NonDeletableRoleId';

@Injectable({providedIn: 'root'})
export class TeamRoleService {

  private readonly cache = new Cache<GetTeamRoles>(element => element.id);

  constructor(
    private notificationService: NotificationService,
    private client: HttpClient,
  ) {}

  observeTeamRoleList(): Observable<GetTeamRoles[]> {
    return this.cache.observeAllElementsList();
  }

  observeTeamRoleMap(): Observable<{[id: string]: GetTeamRoles}> {
    return this.cache.observeAllElementsMap();
  }

  fetchAllTeamRoles(): Promise<GetTeamRoles[]> {
    return this.cache.getAllBulk(firstValueFrom(this.client.get<GetTeamRoles[]>('/api/team-roles')));
  }

  fetchAllTeamRolesAsMap(): Promise<{[id: string]: GetTeamRoles}> {
    return this.fetchAllTeamRoles().then(() => this.cache.getAllElementsMap());
  }

  fetchTeamRoleById(teamRoleId: string): Promise<GetTeamRoles> {
    return this.cache.getOne(
      teamRoleId,
      this.loadTeamRoleById.bind(this)
    );
  }

  private async loadTeamRoleById(teamRoleId: string): Promise<GetTeamRoles> {
    return firstValueFrom(this.client.get<GetTeamRoles>(`/api/team-roles/${teamRoleId}`));
  }

  isManagerRole(roleId: string, phase: ProjectPhase) {
    switch (phase) {
      case ProjectPhase.DEVELOPMENT: return roleId === NonDeletableRoleId.PMD;
      case ProjectPhase.CONSTRUCTION: return roleId === NonDeletableRoleId.PMC;
      case ProjectPhase.OPERATIONS: return roleId === NonDeletableRoleId.PMO;
    }
    return false;
  }

  isDeputyManagerRole(roleId: string, phase: ProjectPhase) {
    switch (phase) {
      case ProjectPhase.DEVELOPMENT: return roleId === NonDeletableRoleId.DPMD;
      case ProjectPhase.CONSTRUCTION: return roleId === NonDeletableRoleId.DPMC;
      case ProjectPhase.OPERATIONS: return roleId === NonDeletableRoleId.DPMO;
    }
    return false;
  }

  isManagerOrDeputyManagerRole(roleId: string, phase: ProjectPhase) {
    return this.isManagerRole(roleId, phase) || this.isDeputyManagerRole(roleId, phase);
  }

}
