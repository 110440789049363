<h2>{{title}}</h2>
<mat-dialog-content *ngIf="!isLoading()">
  <digires-lessons-learnt-dialog-content
    [lessonLearnt]="data.lessonLearnt"
    [project]="data.project"
    [projectName]="projectName"
    [allCountryIds]="allCountryIds"
    [allStatusOptions]="allStatusOptions"
    [anchorId]="anchorId"
  ></digires-lessons-learnt-dialog-content>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!isLoading() && dialogContent">
  <button mat-button type="button" class="btnNeutral" mat-dialog-close>Cancel</button>
  <button *ngIf="data.lessonLearnt" mat-button type="button" class="btnNegative" [mat-dialog-close]="true" (click)="deleteRestoreLessonLearnt()">{{deleteRestoreButtonLabel()}}</button>
  <button mat-button type="button" class="btnPositive" [disabled]="!dialogContent.formGroup.valid" [mat-dialog-close]="true" (click)="saveLessonLearnt()">
    Save
  </button>
</mat-dialog-actions>

<div *ngIf="isLoading()" class="spinner_background">
  <mat-progress-spinner [diameter]=25 class="spinner" mode="indeterminate"></mat-progress-spinner>
  <div class="spinner_label">Loading Lesson Learnt, please wait...</div>
</div>
