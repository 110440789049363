import { Component, Input } from '@angular/core';
import { ImportAction, ImportIptEntry } from '../standard-ipt-import-dialog.component';
import { ReadOnlyReason } from '../../../../project-details/ReadOnlyStateManager';

@Component({
  selector: 'digires-standard-ipt-import-entry',
  templateUrl: './standard-ipt-import-entry.component.html',
  styleUrls: ['./standard-ipt-import-entry.component.scss']
})
export class StandardIptImportEntryComponent {

  readonly ReadOnlyReason: typeof ReadOnlyReason = ReadOnlyReason;
  readonly importActionToLabel: Record<ImportAction, string> = {
    [ImportAction.REPLACE]: 'Overwrite',
    [ImportAction.DUPLICATE]: 'Duplicate',
    [ImportAction.KEEP]: 'Unchanged',
    [ImportAction.INSERT]: 'Import',
    [ImportAction.IGNORE]: 'Ignore',
  };
  readonly importActionToTooltip: Record<ImportAction, string> = {
    [ImportAction.REPLACE]: 'The current IPT member is to be overwritten with the person from the standard team',
    [ImportAction.DUPLICATE]: 'The current IPT member is to be retained and the person from the standard team is not added to the IPT',
    [ImportAction.KEEP]: 'The current IPT member is to be retained and the person from the standard team is not added to the IPT',
    [ImportAction.INSERT]: 'The person from the standard team is added to the IPT',
    [ImportAction.IGNORE]: 'The person from the standard team is not added to the IPT',
  };

  @Input() entry: ImportIptEntry;

}
