import { HybridProjectFilterOption } from './general/HybridProjectFilterOption';
import { BusinessModel } from './general/BusinessModel';
import { ProjectPhase } from './general/ProjectPhase';
import { ProjectStage } from './general/ProjectStage';
import { ProjectStatus } from './general/ProjectStatus';
import { ProjectType } from './general/ProjectType';
import { RouteToMarket } from './general/RouteToMarket';
import { Technology } from './general/Technology';
import { ConstructionCluster } from './geo/ConstructionCluster';
import { CountryAlpha2Code } from './geo/CountryAlpha2Code';
import { DevelopmentCluster } from './geo/DevelopmentCluster';
import { DevelopmentDirectorate } from './geo/DevelopmentDirectorate';
import { GlobalRegion } from './geo/GlobalRegion';
import { DropDownOption } from './drop-down/DropDownOption';
import { MyProjectsFilterOption } from './general/MyProjectsFilterOption';
import { ProjectManagingApplication } from './general/ProjectManagingApplication';

export class ProjectFilter {
  projectName: string;
  managerUserId: string;
  myProjects: MyProjectsFilterOption;
  hybridProject: HybridProjectFilterOption;
  managingApplication: ProjectManagingApplication[];
  technology: Technology[];
  status: ProjectStatus[];
  type: ProjectType[];
  phase: ProjectPhase[];
  stage: ProjectStage[];
  businessModel: BusinessModel[];
  routeToMarket: RouteToMarket[];
  developmentDirectorate: DevelopmentDirectorate[];
  countryCode: CountryAlpha2Code[];
  globalRegion: GlobalRegion[];
  developmentCluster: DevelopmentCluster[];
  constructionCluster: ConstructionCluster[];
  region: string[];
  teamId: string[];
}
