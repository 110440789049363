import { firstValueFrom } from 'rxjs';
import { NotificationService } from '../other/notification.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StandardIpt } from '../../model/standard-ipt/StandardIpt';
import { Technology, technologyToLabel } from '../../model/general/Technology';
import { CountryAlpha2Code } from '../../model/geo/CountryAlpha2Code';
import { ProjectPhase } from '../../model/general/ProjectPhase';
import { BY_ALPHA_2 } from '../../model/geo/Countries';
import { FileService } from '../files/file.service';
import { DateUtil } from '../../DateUtil';

@Injectable({
  providedIn: 'root'
})
export class StandardIptService {

  constructor(
    private notificationService: NotificationService,
    private client: HttpClient,
  ) {}

  public getStandardIptFor(
    technology: Technology,
    countryCode: CountryAlpha2Code,
    phase: ProjectPhase,
  ): Promise<StandardIpt[]> {
    const url = `/api/standard-ipt/${technology}/${countryCode}/${phase}`;

    const promise = firstValueFrom(this.client.get<StandardIpt[]>(url));
    this.notificationService.handlePromiseError('Loading of Standard IPT failed...', promise);
    return promise;
  }

  public updateStandardIptFor(
    ipt: StandardIpt[],
    technology: Technology,
    countryCode: CountryAlpha2Code,
    phase: ProjectPhase
  ): Promise<StandardIpt[]> {
    const url = `/api/standard-ipt/${technology}/${countryCode}/${phase}`;
    const promise = firstValueFrom(this.client.put<StandardIpt[]>(url, ipt));
    this.notificationService.handlePromise(
      'Standard IPT updated!',
      'Saving of Standard IPT failed...',
      promise);
    return promise;
  }

  downloadStandardIptExcel(
    technology: Technology,
    countryCode: CountryAlpha2Code,
  ): Promise<void> {
    const url = `/api/standard-ipt/${technology}/${countryCode}/export`;

    const promise = firstValueFrom(this.client.get(url, {responseType: 'blob' as 'json'}));
    this.notificationService.handlePromiseError('Excel export of Standard IPT failed...', promise);

    return promise.then((response: any) => {
      const timeStamp = DateUtil.fileNameTimeStamp(new Date());
      const fileName = `Standard-IPT-${technologyToLabel[technology]}-${BY_ALPHA_2[countryCode]?.name || countryCode}-${timeStamp}UTC.xlsx`;
      FileService.saveDownloadedFile(fileName, response);
    });
  }

}
