import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { RoutePaths } from '../RoutePaths';
import { FncGroup } from '../auth/FncGroup';
import { AuthService } from '../auth/auth.service';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';
import { FileReferenceType } from '../shared/model/user-guides-table.model';
import { DataProtectionDialogComponent } from './data-protection-dialog/data-protection-dialog.component';
import { HelpSupportDialogComponent } from './help-support-dialog/help-support-dialog.component';
import { UserGuidesTableDialogComponent } from './user-guides-dialog/user-guides-table-dialog.component';
import { Router } from '@angular/router';
@Component({
  selector: 'digires-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

  fileReferenceTypes = FileReferenceType

  items: {
    icon: string;
    routeTo: string;
    routeActive: string[];
    tooltip: string;
    accessible: boolean;
  }[] = [
    {
      icon: 'navbar-project-overview',
      routeTo: RoutePaths.PROJECT_OVERVIEW,
      routeActive: [RoutePaths.PROJECT_OVERVIEW, RoutePaths.PROJECT_DETAILS],
      tooltip: 'Home',
      accessible: true,
    },
    {
      icon: 'navbar-my-digires',
      routeTo: RoutePaths.MY_DIGIRES,
      routeActive: [RoutePaths.MY_DIGIRES],
      tooltip: 'My DigiRES',
      accessible: true,
    },
    {
      icon: 'navbar-report-overview',
      routeTo: RoutePaths.REPORTS_AREA,
      routeActive: [RoutePaths.REPORTS_AREA],
      tooltip: 'Reports',
      accessible: true,
    },
    {
      icon: 'navbar-address-book',
      routeTo: RoutePaths.PROJECT_PARTNERS,
      routeActive: [RoutePaths.PROJECT_PARTNERS],
      tooltip: 'Project Partners',
      accessible: true,
    },
    {
      icon: 'navbar-create-project',
      routeTo: RoutePaths.PROJECT_CREATION,
      routeActive: [RoutePaths.PROJECT_CREATION],
      tooltip: 'Project Creation',
      accessible: this.authService.isInGroup(FncGroup.PROJECT_CREATOR),
    },
    {
      icon: 'navbar-lessons-learnt',
      routeTo: RoutePaths.LESSONS_LEARNT,
      routeActive: [RoutePaths.LESSONS_LEARNT],
      tooltip: 'Lessons Learnt',
      accessible: !environment.production,
    },
    {
      icon: 'navbar-admin-area',
      routeTo: RoutePaths.ADMIN_AREA,
      routeActive: [RoutePaths.ADMIN_AREA],
      tooltip: 'Admin Area',
      accessible: this.authService.isInGroup(FncGroup.PROCESS_ADMIN),
    },
    {
      icon: 'navbar-acquisitions',
      routeTo: RoutePaths.ACQUISITION,
      routeActive: [RoutePaths.ACQUISITION],
      tooltip: 'Acquisitions',
      accessible: environment.production
        ? false
        : this.authService.isInGroup(FncGroup.ACQUISITION) ||
        this.authService.isInGroup(FncGroup.ACQUISITION_READING_ACCESS),
    },
    {
      icon: 'navbar-process-module',
      routeTo: RoutePaths.PROCESS_MODULE,
      routeActive: [RoutePaths.PROCESS_MODULE],
      tooltip: 'Process Module',
      accessible: true,
    },
  ];
  utcDate = 'dd/mm/yyyy';
  utcTime = 'hh:mm UTC';
  timer: any;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit() {
    this.updateUtcTime();
    this.timer = setInterval(() => {
      this.updateUtcTime();
    }, 30 * 1000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  isAnySegmentInUrl(segments: string[]): boolean {
    const currentUrl = window.location.href;
    return segments?.some((segment) => currentUrl.includes(segment));
  }

  updateUtcTime() {
    const today = new Date();
    const day = this.pad(today.getUTCDate());
    const month = this.pad(today.getUTCMonth() + 1);
    const year = this.pad(today.getUTCFullYear());
    const hour = this.pad(today.getUTCHours());
    const minute = this.pad(today.getUTCMinutes());
    this.utcDate = `${day}/${month}/${year}`;
    this.utcTime = `${hour}:${minute} UTC`;
  }

  pad(num: number) {
    return num < 10 ? '0' + num : '' + num;
  }

  openLogoutDialog() {
    this.dialog
      .open(ConfirmDialogComponent, {
        hasBackdrop: true,
        data: {
          title: 'Logout',
          message: 'Do you really want to logout from DigiRES?',
          actions: [
            {
              id: 'abort',
              label: 'No',
              tooltip: 'Stay logged in',
              disabled: false,
              flair: 'neutral',
            },
            {
              id: 'logout',
              label: 'Yes',
              tooltip: 'Logout from DigiRES',
              disabled: false,
              flair: 'negative',
            },
          ],
        },
      })
      .afterClosed()
      .subscribe((value) => {
        if (value === 'logout') {
          this.authService.logout();
        }
      });
  }

  openDataProtectionDialog() {
    this.dialog.open(DataProtectionDialogComponent, {
      hasBackdrop: true,
    });
  }

  openSupportDialog() {
    this.dialog.open(HelpSupportDialogComponent, {
      hasBackdrop: true,
    });
  }

  openIntroductionForEveryone() {
    window.open(
      '/assets/files/20221201_DigiRES_V1.0.0_An-Introduction-for-Everyone.pdf',
      '_blank'
    );
  }

  openUserGuide(fileReferenceType: FileReferenceType) {
    fileReferenceType === FileReferenceType.SLIDE ?
      this.router.navigate([RoutePaths.USER_GUIDES_SLIDES]) :
      this.router.navigate([RoutePaths.USER_GUIDES_VIDEOS]);
  }
}
