import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePaths } from './RoutePaths';
import { UdpComponent } from './udp/udp.component';
import {
  WebComponentWrapper, WebComponentWrapperOptions
} from '@angular-architects/module-federation-tools';
import { UserGuidesPageComponent } from './navbar/user-guides-page/user-guides-page.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: RoutePaths.PROJECT_OVERVIEW,
    pathMatch: 'full',
  },
  {
    path: RoutePaths.PROJECT_OVERVIEW,
    loadChildren: () =>
      import('./project-overview/project-overview.module').then(
        (m) => m.ProjectOverviewModule
      ),
  },
  {
    path: RoutePaths.PROJECT_CREATION,
    loadChildren: () =>
      import('./project-creation/project-creation.module').then(
        (m) => m.ProjectCreationModule
      ),
  },
    {
    path: RoutePaths.LESSONS_LEARNT,
    loadChildren: () =>
      import('./lessons-learnt/lessons-learnt.module').then(
        (m) => m.LessonsLearntModule
      ),
  },
  {
    path: RoutePaths.PROJECT_DETAILS,
    loadChildren: () =>
      import('./project-details/project-details.module').then(
        (m) => m.ProjectDetailsModule
      ),
  },
  {
    path: RoutePaths.PROJECT_DETAILS_EMBEDDED,
    loadChildren: () =>
      import('./project-details/project-details.module').then(
        (m) => m.ProjectDetailsModule
      ),
  },
  {
    path: RoutePaths.PROJECT_PARTNERS,
    loadChildren: () =>
      import('./partners-area/partners-area.module').then(
        (m) => m.PartnersAreaModule
      ),
  },
  {
    path: RoutePaths.MY_DIGIRES,
    loadChildren: () =>
      import('./my-digires/my-digires.module').then((m) => m.MyDigiresModule),
  },
  {
    path: RoutePaths.ADMIN_AREA,
    loadChildren: () =>
      import('./admin-area/admin-area.module').then((m) => m.AdminAreaModule),
  },
  {
    path: RoutePaths.ACQUISITION,
    component: WebComponentWrapper,
    data: {
      type: 'script',
      remoteEntry: '/aquisitionfe/remoteEntry.js',
      remoteName: 'remote',
      exposedModule: './acquisitions-module',
      elementName: 'acquisitions-module',
    } as WebComponentWrapperOptions,
  },
  {
    path: RoutePaths.REPORTS_AREA,
    loadChildren: () =>
      import('./reports-area/reports-area.module').then(
        (m) => m.ReportsAreaModule
      ),
  },
  {
    path: RoutePaths.USER_GUIDES_SLIDES,
    component: UserGuidesPageComponent,
  },
  {
    path: RoutePaths.USER_GUIDES_VIDEOS,
    component: UserGuidesPageComponent,
  },
  {
    path: RoutePaths.PROCESS_MODULE,
    component: UdpComponent,
    canActivate: [],
  },
  {
    path: '**',
    redirectTo: RoutePaths.PROJECT_OVERVIEW,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
