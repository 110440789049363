export enum MyProjectsFilterOption {
  ALL_PROJECTS = 'ALL_PROJECTS',
  ANY_ROLE = 'ANY_ROLE',
  MANAGER = 'MANAGER'
}

export const myProjectsFilterOptionToLabel: { [value: string]: string } = {
  [MyProjectsFilterOption.ALL_PROJECTS]: 'All Projects',
  [MyProjectsFilterOption.ANY_ROLE]: 'My Projects - All Roles',
  [MyProjectsFilterOption.MANAGER]: 'My Projects - Project Manager',
};
