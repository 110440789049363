<h2>Import Standard IPT Membership</h2>

<p *ngIf="importCoreEntries.length === 0 && importWiderEntries.length === 0">
  There are no changes to the Standard Team that can be imported to the Project Team.
</p>
<p *ngIf="importCoreEntries.length > 0 || importWiderEntries.length > 0">
  When importing the Standard IPT Membership the following conflicts have been identified that require the User to
  decide how they should be resolved. For each role below select the relevant course of action for how the change is
  to be made:
</p>

<mat-dialog-content>

  <h3 *ngIf="importCoreEntries.length > 0">
    Integrated Project Team - Core Roles
  </h3>

  <digires-standard-ipt-import-entry *ngFor="let entry of importCoreEntries"
                                     [entry]="entry"
  ></digires-standard-ipt-import-entry>

  <h3 *ngIf="importWiderEntries.length > 0">
    Integrated Project Team - Wider Roles
  </h3>

  <digires-standard-ipt-import-entry *ngFor="let entry of importWiderEntries"
                                     [entry]="entry"
  ></digires-standard-ipt-import-entry>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button type="button" class="btnNeutral" [mat-dialog-close]="[]">
    Cancel
  </button>
  <button mat-button type="button" class="btnPositive" [mat-dialog-close]="createReturnValue()">
    Confirm
  </button>
</mat-dialog-actions>
