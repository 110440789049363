import { ProjectPhase } from '../general/ProjectPhase';

export enum IptPhase {
  DEVELOPMENT_INITIAL = 'DEVELOPMENT_INITIAL',
  DEVELOPMENT_CLOSING = 'DEVELOPMENT_CLOSING',
  CONSTRUCTION = 'CONSTRUCTION',
  OPERATIONS = 'OPERATIONS',
}
export const iptPhaseToLabel = {
  [IptPhase.DEVELOPMENT_INITIAL]: 'Development Initial',
  [IptPhase.DEVELOPMENT_CLOSING]: 'Development Closing',
  [IptPhase.CONSTRUCTION]: 'Construction',
  [IptPhase.OPERATIONS]: 'Operations',
};
export const  iptPhaseToProjectPhase: Record<IptPhase, ProjectPhase> = {
  [IptPhase.DEVELOPMENT_INITIAL]: ProjectPhase.DEVELOPMENT,
  [IptPhase.DEVELOPMENT_CLOSING]: ProjectPhase.DEVELOPMENT,
  [IptPhase.CONSTRUCTION]: ProjectPhase.CONSTRUCTION,
  [IptPhase.OPERATIONS]: ProjectPhase.OPERATIONS,
};
export const iptPhaseToIndex = (iptPhase: IptPhase) => Object.values(IptPhase).indexOf(iptPhase);
