export enum RouteToMarket {
  TRADED_MERCHANT = 'TRADED_MERCHANT',
  PPA = 'PPA',
  SUPPORT_MECHANISM = 'SUPPORT_MECHANISM',
  OTHER = 'OTHER',
}
export const routeToMarketToLabel: {[value: string]: string} = {
  [RouteToMarket.TRADED_MERCHANT]: 'Traded Merchant',
  [RouteToMarket.PPA]: 'PPA (Power Purchase Agreement)',
  [RouteToMarket.SUPPORT_MECHANISM]: 'Support Mechanism',
  [RouteToMarket.OTHER]: 'Other',
};
