import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  SsoSilentRequest
} from '@azure/msal-browser';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ProjectPhase } from '../shared/model/general/ProjectPhase';
import { NewsItem } from '../shared/model/news/NewsItem';
import { Task } from '../shared/model/task/task';
import { ProjectDetailsService } from '../shared/services/project-details/project-details.service';
import { GetProjects } from '../shared/services/project-list/GetProjects';
import { NonDeletableRoleId } from '../shared/services/team-role/NonDeletableRoleId';
import { TeamRoleService } from '../shared/services/team-role/team-role.service';
import { FncGroup } from './FncGroup';
import { GetUserLogin } from './GetUserLogin';
import { TeamMembership } from './TeamMembership';
import { CurrentLoginState, CurrentLoginUser, UserLoginState } from './CurrentLoginUser';
import { isEmbeddedInIFrame } from '../app.module';

@Injectable()
export class AuthService {

  private readonly LOGIN_SCOPE = ['User.Read'];

  private readonly loginState = new BehaviorSubject<CurrentLoginState>(
    this.createInitialState()
  );
  private ongoingLoginAttempts = 0;

  constructor(
    private teamRoleService: TeamRoleService,
    private detailsService: ProjectDetailsService,
    private httpClient: HttpClient,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    if (isEmbeddedInIFrame) {
      this.initialiseRedirectLogin();
    }
    this.tryInitialMsalLogin();
  }

  observeLoginState(): Observable<CurrentLoginState> {
    return this.loginState.asObservable();
  }

  isLoginInProgress(): boolean {
    return this.ongoingLoginAttempts > 0;
  }

  retryLogin() {
    if (this.ongoingLoginAttempts > 0) {
      console.error('Login already in progress');
      return;
    }
    this.tryAcquireToken();
  }

  private createDefaultRequest(): SsoSilentRequest {
    const existingAccount = this.getAndSyncMsalAccount();
    return {
      scopes: this.LOGIN_SCOPE,
      account: existingAccount,
    };
  }

  private initialiseRedirectLogin() {
    this.msalService
      .handleRedirectObservable()
      .subscribe({
        next: (result: AuthenticationResult) => this.onMsalLoginSuccess(result),
        error: (error) => this.onMsalLoginFailed(error),
      });
  }

  private tryAcquireToken() {
    this.ongoingLoginAttempts++;
    if (isEmbeddedInIFrame) {
      this.msalService.acquireTokenPopup({scopes: this.LOGIN_SCOPE})
        .subscribe({
          next: result => this.onMsalLoginSuccess(result),
          error: error => this.onMsalLoginFailed(error),
        });
    } else {
      this.msalService.acquireTokenRedirect({scopes: this.LOGIN_SCOPE})
        .subscribe({
          next: () => {},
          error: error => this.onMsalLoginFailed(error),
        });
    }
  }

  private tryInitialMsalLogin() {
    const request = this.createDefaultRequest();
    this.ongoingLoginAttempts++;
    this.msalService.ssoSilent(request)
      .subscribe({
        next: result => this.onMsalLoginSuccess(result),
        error: () => {
          this.ongoingLoginAttempts--;
          this.tryAcquireToken();
        },
      });
  }

  private onMsalLoginSuccess(result: AuthenticationResult) {
    this.ongoingLoginAttempts--;
    if (result && result.account) {
      this.msalService.instance.setActiveAccount(result.account);
      this.tryDigiResLogin();

    } else if (this.getAndSyncMsalAccount()) {
      this.tryDigiResLogin();

    } else {
      this.tryAcquireToken();
    }
  }

  private onMsalLoginFailed(error) {
    console.error('login failed; error: ', error);
    this.ongoingLoginAttempts--;
    if (error?.errorMessage) {
      // const interactionInProgress = error.errorMessage.includes('Interaction is currently in progress.');
      // const openPopupFailed = error.errorMessage.includes('Error opening popup window.');
      // const timeoutTokenAcq = error.errorMessage.includes('Token acquisition in iframe failed due to timeout.');
      if (error.errorMessage.includes('Hash does not contain known properites.'/*<- this typo is indeed in the library!*/)) {
        this.loginState.next(this.createDigiResLoginFailedState(this.getAndSyncMsalAccount(), 'from MSAL.js'));
      } else {
        this.loginState.next(this.createMsalErrorState(error.errorMessage));
      }
    } else {
      this.loginState.next(this.createMsalErrorState(JSON.stringify(error)));
    }
  }

  private getAndSyncMsalAccount(): AccountInfo | null {
    const activeAccount = this.msalService.instance.getActiveAccount()
      || this.msalService.instance.getAllAccounts()[0]
      || null;

    if (activeAccount && !this.msalService.instance.getActiveAccount()) {
      this.msalService.instance.setActiveAccount(activeAccount);
    }

    return activeAccount;
  }

  private tryDigiResLogin() {
    const msalAccount = this.getAndSyncMsalAccount();
    this.loginState.next(this.createBeforeLoginState(msalAccount));

    firstValueFrom(this.httpClient.get<GetUserLogin>('api/login'))
      .then((userData) => {
        this.loginState.next(this.createDigiResLoginSuccessState(userData));
      })
      .catch((error) => {
        console.error(error);
        this.loginState.next(this.createDigiResLoginFailedState(msalAccount, JSON.stringify(error)));
      });
  }

  logout() {
    this.loginState.next(this.createAfterLogoutState());
    if (environment.name === 'local') {
      return;
    }
    this.msalService.logout();
  }

  private createInitialState(): CurrentLoginState {
    return {
      state: UserLoginState.AUTHENTICATION_NOT_STARTED,
      user: null,
      errorMessage: null,
    };
  }

  private createBeforeLoginState(account: AccountInfo): CurrentLoginState {
    return {
      state: UserLoginState.BEFORE_LOGIN,
      user: {
        id: account.username,
        fullName: account.username,
        groups: [],
        groupsSet: new Set<FncGroup>(),
      },
      errorMessage: null,
    };
  }

  private createMsalErrorState(errorMessage: string): CurrentLoginState {
    return {
      state: UserLoginState.LOGIN_MSAL_ERROR,
      user: null,
      errorMessage: errorMessage,
    };
  }

  private createDigiResLoginSuccessState(userData: GetUserLogin): CurrentLoginState {
    return {
      state: UserLoginState.FULL_LOGIN_SUCCESS,
      user: {
        id: userData.id,
        fullName: userData.fullName,
        groups: userData.groups,
        groupsSet: new Set<FncGroup>(userData.groups),
      },
      errorMessage: null,
    };
  }

  private createDigiResLoginFailedState(account: AccountInfo, errorMessage: string): CurrentLoginState {
    return {
      state: UserLoginState.LOGIN_DIGIRES_ERROR,
      user: {
        id: account?.username,
        fullName: account?.username,
        groups: [],
        groupsSet: new Set<FncGroup>(),
      },
      errorMessage: errorMessage,
    };
  }

  private createAfterLogoutState(): CurrentLoginState {
    return {
      state: UserLoginState.AUTHENTICATION_NOT_STARTED,
      user: {
        id: this.getLastUser()?.id || '',
        fullName: this.getLastUser()?.fullName || '',
        groups: [],
        groupsSet: new Set<FncGroup>(),
      },
      errorMessage: null,
    };
  }

  private getLastUser(): CurrentLoginUser | null {
    return this.loginState.getValue()?.user || null;
  }

  getUserId(): string {
    return this.getLastUser()?.id || '';
  }

  getUserName(): string {
    return this.getLastUser()?.fullName || '';
  }

  isInGroup(group: FncGroup): boolean {
    return this.getLastUser()?.groupsSet?.has(group) || false;
  }

  async checkTeamMembership(
    projectId: string,
    phase: ProjectPhase
  ): Promise<TeamMembership> {
    if (this.isInGroup(FncGroup.PROJECTS_WRITING_ACCESS)) {
      return {
        canRead: true,
        isMember: true,
        isManagerOrDeputy: true,
        isPmd: true,
        isPmc: true,
        isPmo: true,
        isTld: true,
        isIptChair: true,
        isGpm: true,
        isCpmc: true,
        isItSupport: this.isInGroup(FncGroup.IT_SUPPORT),
      };
    }
    const loginUserId = this.getLastUser()?.id;
    const teamData = await this.detailsService.fetchTeam(projectId);
    const membership = {
      canRead: this.isInGroup(FncGroup.PROJECTS_READING_ACCESS),
      isMember: false,
      isManagerOrDeputy: false,
      isPmd: false,
      isPmc: false,
      isPmo: false,
      isTld: false,
      isIptChair: false,
      isGpm: false,
      isCpmc: false,
      isItSupport: false,
    };
    for (const member of teamData.members) {
      if (member.userId === loginUserId) {
        membership.canRead = true;
        membership.isMember = true;
        membership.isManagerOrDeputy = membership.isManagerOrDeputy
          || this.teamRoleService.isManagerOrDeputyManagerRole(member.roleId, phase);
        switch (member.roleId) {
          case NonDeletableRoleId.PMD:
          case NonDeletableRoleId.DPMD:
            membership.isPmd = true;
            break;
          case NonDeletableRoleId.PMC:
          case NonDeletableRoleId.DPMC:
            membership.isPmc = true;
            break;
          case NonDeletableRoleId.PMO:
          case NonDeletableRoleId.DPMO:
            membership.isPmo = true;
            break;
          case NonDeletableRoleId.TLD:
            membership.isTld = true;
            break;
          case NonDeletableRoleId.IPTChair:
            membership.isIptChair = true;
            break;
          case NonDeletableRoleId.GPM:
            membership.isGpm = true;
            break;
          case NonDeletableRoleId.CPMC:
            membership.isCpmc = true;
            break;
        }
      }
    }
    return membership;
  }

  canEditNewsItem(newsItem: NewsItem): boolean {
    return (
      this.isInGroup(FncGroup.IT_SUPPORT) ||
      this.isSameUser(newsItem.authorUserId)
    );
  }

  canEditTaskItem(project: GetProjects, task: Task): boolean {
    return (
      this.isInGroup(FncGroup.IT_SUPPORT) ||
      this.isSameUser(task?.ownerId) ||
      this.isSameUser(project.manager.userId)
    );
  }

  isSameUser(userId: string): boolean {
    return this.getLastUser()?.id === userId;
  }
}
