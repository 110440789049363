import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ProjectOverviewTypeFilteringComponent } from "./project-overview-type-filtering.component";
import { TypeFilteringButtonModule } from "./type-filtering-button/type-filtering-button.module";

@NgModule({
    declarations: [ProjectOverviewTypeFilteringComponent],
    exports: [ProjectOverviewTypeFilteringComponent],
    imports: [CommonModule, TypeFilteringButtonModule]
})
export class ProjectOverviewTypeFilteringModule {}
