<h2>
  <ng-container *ngIf="isEdit">
    Edit
  </ng-container>
  <ng-container *ngIf="!isEdit">
    Create
  </ng-container>
  Project News
</h2>

<mat-dialog-content>
  <digires-text-input [formGroup]="formGroup"
                      formCtrlName="title"
                      placeholder="Title"
                      tooltip="The title of the News Item"
                      error="Please enter a meaningful title which summarises this news item"
                      [required]="true"
  ></digires-text-input>
  <digires-text-input [formGroup]="formGroup"
                      formCtrlName="content"
                      placeholder="News Item Body"
                      tooltip="The main content of the News item"
                      error="Please enter the main body of this news item"
                      [required]="true"
                      [textarea]="true"
                      fixedRowCount="5"
  ></digires-text-input>
  <div class="row">
    <digires-list-input class="grow"
                        [formGroup]="formGroup"
                        formCtrlName="category"
                        [items]="allCategories"
                        [itemToLabel]="categoryToLabel"
                        placeholder="Category"
                        tooltip="The category for this news item. News can be filtered by category."
    ></digires-list-input>
    <digires-list-input class="grow"
                        [formGroup]="formGroup"
                        formCtrlName="tag"
                        [items]="allTags"
                        [itemToLabel]="tagToLabel"
                        placeholder="Tag"
                        tooltip="The tag of this news item."
    ></digires-list-input>
  </div>
  <form [formGroup]="formGroup">
    <mat-checkbox formControlName="sendEmail">
      Send Email to all project members to inform them about this news item
<!--      <mat-icon class="tooltip-icon" svgIcon="tooltip" matTooltipPosition="above"-->
<!--                matTooltip="If ticked an email will be sent out to all members of this project informing them about these news"-->
<!--      ></mat-icon>-->
    </mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <span class="row">
    <button mat-button type="button" class="btnNeutral"
            [mat-dialog-close]="null">
      Cancel
    </button>
    <button *ngIf="isEdit && canDelete" mat-button type="button" class="btnNegative"
            [mat-dialog-close]="returnDeleteNews()">
      Delete
    </button>
  </span>
  <button *ngIf="isEdit" mat-button type="button" class="btnPositive"
          [disabled]="!formGroup.valid"
          [mat-dialog-close]="returnPutNews()">
    Save Changes
  </button>
  <button *ngIf="!isEdit" mat-button type="button" class="btnPositive"
          [disabled]="!formGroup.valid"
          [mat-dialog-close]="returnPostNews()">
    Publish
  </button>
</mat-dialog-actions>
