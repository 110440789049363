export class ReadOnlyState {

  constructor(private manager: ReadOnlyStateManager, private path: string[]) {}

  public isReadOnly(formCtrlName?: string): boolean {
    const fullPath = formCtrlName ? [...this.path, formCtrlName] : this.path;
    return !!this.manager.isReadOnly(...fullPath);
  }

  public getReadOnlyTooltip(formCtrlName?: string): string {
    const fullPath = formCtrlName ? [...this.path, formCtrlName] : this.path;
    return this.manager.getReadOnlyTooltip(...fullPath);
  }

}
export class ReadOnlyStateManager {

  private root: ReadOnlyStateNode = new ReadOnlyStateNode();

  public printAll() {
    this.root.print(0, 'root');
  }

  public of(path: string[]): ReadOnlyState {
    return new ReadOnlyState(this, path);
  }

  public isReadOnly(...path: string[]): boolean {
    return !!this.getReadOnlyReason(path);
  }

  public getReadOnlyTooltip(...path: string[]): string {
    return ReadOnlyReasonToTooltip[this.getReadOnlyReason(path)]
  }

  public getReadOnlyReason(path: string[]): ReadOnlyReason {
    let current = this.root;
    let currentReason: ReadOnlyReason = this.root.get();
    for (const part of path) {
      const key = part.toLowerCase();
      const next: ReadOnlyStateNode = current[key];
      if (!next) {
        break;
      }
      const nextReason = next.get();
      if (!!nextReason) {
        currentReason = nextReason;
      }
      current = next;
    }
    return currentReason;
  }

  public addState(reason: ReadOnlyReason, ...path: string[]): void {
    let current = this.root;
    for (const part of path) {
      const key = part.toLowerCase();
      let next = current[key];
      if (!next) {
        next = new ReadOnlyStateNode();
        current[key] = next;
      }
      current = next;
    }
    current.add(reason);
  }

  public removeState(reason: ReadOnlyReason, ...path: string[]): void {
    let current = this.root;
    for (const part of path) {
      const key = part.toLowerCase();
      let next = current[key];
      if (!next) {
        next = new ReadOnlyStateNode();
        current[key] = next;
      }
      current = next;
    }
    current.rem(reason);
  }

  public clear(): void {
    this.root = new ReadOnlyStateNode();
  }

  public removeAll(...reasons: ReadOnlyReason[]): void {
    // console.log('removeAll.reasons=', reasons);
    const stack = [this.root];
    while (stack.length > 0) {
      const current = stack.pop();
      // console.log('removeAll.current=', JSON.parse(JSON.stringify(current)), 'stack=', stack.slice());
      for (const reason of reasons) {
        current.rem(reason);
      }
      for (const child of Object.values(current)) {
        if (child._reasonSet) {
          stack.push(child);
        }
      }
    }
  }

}
export enum ReadOnlyReason {
  MANAGED_IN_GREEN_OS = 'MANAGED_IN_GREEN_OS',
  MANAGED_IN_DIGIRES = 'MANAGED_IN_DIGIRES',
  CALCULATED = 'CALCULATED',
  FEED_IN = 'FEED_IN',
  DISABLED = 'DISABLED',
  PIPELINE_EXIT = 'PIPELINE_EXIT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  IPT_TEAM = 'IPT_TEAM',
  LOCKED = 'LOCKED',
  REJECTED = 'REJECTED',
  CONSTRUCTION = 'CONSTRUCTION',
  SOLD = 'SOLD',
  FIN_MODEL = 'FIN_MODEL',
}
export const ReadOnlyReasonToTooltip: {[reason: string]: string} = {
  [ReadOnlyReason.MANAGED_IN_GREEN_OS]: 'This Value is now being managed in GreenOS Project Development so changes can'
  + ' only be made in that system.',
  [ReadOnlyReason.MANAGED_IN_DIGIRES]: 'This Value is no longer being managed in GreenOS Project Development so changes'
  + ' can not be made in this system any more.',
  [ReadOnlyReason.CALCULATED]: 'The value in this data field is calculated in DigiRES and can\'t be manually overwritten',
  [ReadOnlyReason.FEED_IN]: 'The value being shown in this data field is just for display purposes - it has been '
  + 'populated in the input field that is elsewhere in DigiRES',
  [ReadOnlyReason.DISABLED]: 'These inputs can not be edited here',
  [ReadOnlyReason.PIPELINE_EXIT]: 'The data in this field can only be edited when the project is not in an active status',
  [ReadOnlyReason.UNAUTHORIZED]: 'You are not authorized to edit this information',
  [ReadOnlyReason.IPT_TEAM]: 'You are not authorized to edit this information, the project team can be edited by '
  + 'the IPT Chair, Project Manager, Deputy Project Manager',
  [ReadOnlyReason.LOCKED]: 'The Project Manager has put a temporary \'Validation Lock\' on the project information '
  + 'which makes it Read Only (i.e. it can\'t be changed) until the end of the reporting cycle',
  [ReadOnlyReason.REJECTED]: 'The Project Status has been set to Rejected - changes are not possible to Rejected projects',
  [ReadOnlyReason.CONSTRUCTION]: 'The project is now in the Construction Phase and data in this field can only be '
  + 'edited when the project is in the Development Phase as it is retained as a record of the values reported in the '
  + 'Development Phase',
  [ReadOnlyReason.SOLD]: 'The Project Status has been set to Sold - changes are not possible to Sold projects',
  [ReadOnlyReason.FIN_MODEL]: 'The value in this data field has been read from the Financial Model and can\'t be '
  + 'manually overwritten',
};

const ReadOnlyReasonData: {[reason: string]: {index: number, name: ReadOnlyReason}} = {};
for (const [index, key] of Array.from(Object.keys(ReadOnlyReason).entries())) {
  ReadOnlyReasonData[key] = {
    index: index,
    name: ReadOnlyReason[key],
  };
}
const AllReadOnlyReasonsArr = Object.values(ReadOnlyReasonData);
class ReadOnlyStateNode {
  _reasonSet: boolean[] = AllReadOnlyReasonsArr.map(_ => false);

  add(reason: ReadOnlyReason): void {
    this._reasonSet[ReadOnlyReasonData[reason].index] = true;
  }

  rem(reason: ReadOnlyReason): void {
    this._reasonSet[ReadOnlyReasonData[reason].index] = false;
  }

  get(): ReadOnlyReason {
    for (const reason of AllReadOnlyReasonsArr) {
      if (this._reasonSet[reason.index]) {
        return reason.name;
      }
    }
    return null;
  }

  print(indent: number, label: string) {
    console.log('>'.repeat(indent), label, this._reasonSet);
    for (const key of Object.keys(this)) {
      if (key !== '_reasonSet') {
        this[key].print(indent + 1, key);
      }
    }
  }
}
