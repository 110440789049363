export enum NonDeletableRoleId {
  IPTChair = 'IPTChair',
  GPM = 'GPM',
  HOE = 'HOE',
  POD = 'POD',
  TLD = 'TLD',
  CHD = 'CHD',
  PMD = 'PMD',
  DPMD = 'DPMD',
  CPMC = 'CPMC',
  PMOC = 'PMOC',
  PMC = 'PMC',
  DPMC = 'DPMC',
  PMO = 'PMO',
  DPMO = 'DPMO',
}
