<h2 class="header">{{ data.title }}</h2>
<hr class="line" />
<div class="context">
  <h3 class="confirmation-text">Are you sure you want to delete {{ data.name }}?</h3>
</div>
<hr class="line" />
<div class="container">
  <button
    mat-raised-button
    class="confirmation-button delete"
    (click)="delete()"
  >
    Delete
  </button>
  <button
    mat-raised-button
    class="confirmation-button reset"
    [mat-dialog-close]="true"
  >
    Reset
  </button>
</div>
