import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {GetUsers} from './GetUsers';
import {GetUsersTeamMembership} from "./GetUsersTeamMembership";
import {Cache} from '../Cache';
import {BulkLoadService} from '../bulk-load.service';
import {Chunk} from '../Chunk';
import {NotificationService} from '../other/notification.service';
import {FncGroup} from '../../../auth/FncGroup';
import {GetUserRights} from './GetUserRights';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly usersCache = new Cache<GetUsers>(element => element.id);
  private readonly photoCache = new Cache<SafeResourceUrl>(_ => {throw new Error('No Bulk Load Supported')});
  private readonly teamMembershipCache = new Cache<GetUsersTeamMembership[]>(_ => {throw new Error('No Bulk Load Supported')});

  constructor(
    private bulkLoader: BulkLoadService,
    private notificationService: NotificationService,
    private client: HttpClient,
    private sanitizer: DomSanitizer,
  ) { }

  observeUserMap(): Observable<{[userId: string]: GetUsers}> {
    return this.usersCache.observeAllElementsMap();
  }

  observeUserList(): Observable<GetUsers[]> {
    return this.usersCache.observeAllElementsList();
  }

  async fetchAllUsers(): Promise<{[userId: string]: GetUsers}> {
    const rawUserData = firstValueFrom(this.client.get<GetUsers[]>('/api/users'));
    return this.usersCache.getAllBulk(rawUserData)
      .then(() => this.usersCache.getAllElementsMap());
  }

  fetchUsersById(userIds: string[]): Observable<Chunk<GetUsers>> {
    return this.usersCache.getAllChunked(
      userIds,
      this.loadUsers.bind(this),
    );
  }

  private loadUsers(ids: string[]): Subject<Chunk<GetUsers>> {
    return this.bulkLoader.chunkedLoading<GetUsers>('/api/users', 'userIds', ids);
  }

  async loadUserById(userId: string): Promise<GetUsers> {
    return this.usersCache.getOne(
      userId,
      id => firstValueFrom(this.client.get<GetUsers>(`/api/users/${id}`)),
    );
  }

  async fetchUserPhoto(userId: string): Promise<SafeResourceUrl> {
    return this.photoCache.getOne(
      userId,
      id => {
        const photoB64 = firstValueFrom(this.client.get(
          `/api/users/${id}/picture`,
          {responseType: 'text'}
        ));
        return photoB64.then(value => this.sanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64,${value}`));
      }
    );
  }

  fetchTeamMembership(userId: string): Promise<GetUsersTeamMembership[]> {
    return this.teamMembershipCache.getOne(
      userId,
      id => firstValueFrom(this.client.get<GetUsersTeamMembership[]>(`/api/users/${id}/team-membership`))
    );
  }

  fetchUserRights(fncGroup: FncGroup): Promise<GetUserRights[]> {
    return firstValueFrom(this.client.get<GetUserRights[]>(`/api/user-rights/${fncGroup}`));
  }

}
