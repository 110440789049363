export enum FncGroup {
  USER = 'USER',
  PROJECT_CREATOR = 'PROJECT_CREATOR',
  PROJECTS_READING_ACCESS = 'PROJECTS_READING_ACCESS',
  PROJECTS_WRITING_ACCESS = 'PROJECTS_WRITING_ACCESS',
  ACQUISITION = 'ACQUISITION',
  ACQUISITION_READING_ACCESS = 'ACQUISITION_READING_ACCESS',
  ADMIN = 'ADMIN',
  PROCESS_ADMIN = 'PROCESS_ADMIN',
  IT_SUPPORT = 'IT_SUPPORT',
  TEST_USER = 'TEST_USER',
}
export const fncGroupToDescription: Record<FncGroup, string> = {
  USER: 'You have the normal DigiRES-OPEA access rights',
  TEST_USER: 'You have the right to access the DigiRES-OPEA Test Environments (Staging, Feature Preview and Debug.)',
  PROJECT_CREATOR: 'You have the right to create projects in DigiRES-OPEA',
  PROJECTS_READING_ACCESS: 'You have the right to read all project information in DigiRES-OPEA',
  PROJECTS_WRITING_ACCESS: 'You have the right to edit all project information in DigiRES-OPEA',
  ACQUISITION: 'You have the right to access the Acquisition Module in DigiRES-OPEA',
  ACQUISITION_READING_ACCESS: 'You have the right to read all opportunity and project information in the Acquisition Module in DigiRES-OPEA',
  PROCESS_ADMIN: 'You have the right to access the Admin Area in the Process Module of DigiRES-OPEA',
  ADMIN: 'You have the right to access the Admin Area in DigiRES-OPEA',
  IT_SUPPORT: 'You have the right to access and make edits in all areas of DigiRES-OPEA',
}
