import { CommonModule, DecimalPipe, LowerCasePipe, registerLocaleData, } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import '@angular/compiler';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalBroadcastService, MsalInterceptor, MsalModule, MsalService, } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication, } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { NavbarComponent } from './navbar/navbar.component';

import localeEn from '@angular/common/locales/en';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { DragulaModule } from 'ng2-dragula';
import { DataProtectionDialogComponent } from './navbar/data-protection-dialog/data-protection-dialog.component';
import { HelpSupportDialogComponent } from './navbar/help-support-dialog/help-support-dialog.component';
import { UdpComponent } from './udp/udp.component';
import { AgGridModule } from 'ag-grid-angular';
import { UserGuidesTableDialogComponent } from './navbar/user-guides-dialog/user-guides-table-dialog.component';
import { SharedModule } from './shared/shared.module';
import { UserGuidesPageComponent } from './navbar/user-guides-page/user-guides-page.component';

registerLocaleData(localeEn);

export const isEmbeddedInIFrame = window !== window.parent && !window.opener;

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DataProtectionDialogComponent,
    HelpSupportDialogComponent,
    UserGuidesTableDialogComponent,
    UdpComponent,
    UserGuidesPageComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    AppRoutingModule,
    HttpClientModule,
    GoogleMapsModule,
    MatIconModule,
    DragulaModule.forRoot(),
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: '9e722cd9-130b-4332-be53-0ac55c0a5a07',
          authority:
            'https://login.microsoftonline.com/f481d56f-c63d-4d74-8406-d64e74f44395',
          redirectUri: '/',
          postLogoutRedirectUri: '/logout',
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: true, // set to true for IE 11
        },
        system: {
          windowHashTimeout: 300000,
          iframeHashTimeout: 300000,
          loadFrameTimeout: 300000,
          allowRedirectInIframe: true,
        }
      }),
      {
        interactionType: isEmbeddedInIFrame ? InteractionType.Popup : InteractionType.Redirect, // MSAL Guard Configuration
        loginFailedRoute: '/unauthorized',
      },
      {
        interactionType: isEmbeddedInIFrame ? InteractionType.Popup : InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map<string, Array<string> | null>([
          [
            environment.apiURL,
            ['api://9e722cd9-130b-4332-be53-0ac55c0a5a07/Read.All'],
          ],
        ]),
      }
    ),
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSelectModule,
    ReactiveFormsModule,
    AgGridModule,
    SharedModule,
  ],
  providers: [
    AuthService,
    DecimalPipe,
    LowerCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
