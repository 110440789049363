export enum HybridProjectFilterOption {
  ALL_PROJECTS = 'ALL_PROJECTS',
  ONLY_HYBRID = 'ONLY_HYBRID',
  NON_HYBRID = 'NON_HYBRID'
}

export const hybridProjectFilterOptionToLabel: { [value: string]: string } = {
  [HybridProjectFilterOption.ALL_PROJECTS]: 'All Projects',
  [HybridProjectFilterOption.ONLY_HYBRID]: 'Hybrid Only',
  [HybridProjectFilterOption.NON_HYBRID]: 'Non-Hybrid Only',
};
