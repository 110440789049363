export enum OpCo {
  NONE = 'NONE',
  OPEA = 'OPEA',
  OFF = 'OFF',
  OPAM = 'OPAM',
}
export const OpCoToLabel = {
  [OpCo.NONE]: 'Not within a defined RWE Renewables OpCo',
  [OpCo.OPEA]: 'OPEA',
  [OpCo.OFF]: 'OFF',
  [OpCo.OPAM]: 'OPAM',
};
