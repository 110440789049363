import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private currentMessage = new Subject<string>();

  observeNotification(): Observable<string> {
    return this.currentMessage.asObservable();
  }

  push(message: string) {
    this.currentMessage.next(message);
  }

  handlePromiseError(errorMessage: string, promise: Promise<any>) {
    promise.catch(error => {
      console.error('error=', error);
      this.push(errorMessage);
    });
  }

  handlePromise(successMessage: string, errorMessage: string, promise: Promise<any>) {
    promise.then(() => {
      this.push(successMessage);
    }).catch(error => {
      this.push(this.getDeepestErrorMessage(error) || errorMessage);
    });
  }

  private getDeepestErrorMessage(error: any) {
    let current = error;
    while (current?.error?.message) {
      current = current.error;
    }
    return current?.message || null;
  }

}
