<ng-container *ngIf="loginState" [ngSwitch]="loginState.state">

  <div *ngSwitchCase="UserLoginState.AUTHENTICATION_NOT_STARTED" class="login col">
    <div class="login-panel col">
      <h1>DigiRES</h1>
      <div>Waiting for Authentication...</div>
    </div>
  </div>

  <div *ngSwitchCase="UserLoginState.BEFORE_LOGIN" class="login col">
    <div class="login-panel col">
      <h1>DigiRES</h1>
      <div>Waiting for Login...</div>
    </div>
  </div>

  <div *ngSwitchCase="UserLoginState.LOGIN_MSAL_ERROR" class="login col">
    <div class="login-panel col">
      <h1>DigiRES</h1>
      <p>The login with RWE Account has failed.</p>
      <p *ngIf="loginState.errorMessage"><span>The error message is: </span>{{loginState.errorMessage}}</p>
      <p>
        If you are logged in with a valid RWE Account you can try to login again with the button below or
        by reloading this page.
      </p>
      <div>
        <digires-button [disabled]="authService.isLoginInProgress()"
                        [tooltip]="authService.isLoginInProgress()
                          ? 'Please wait for the current login attempt to finish'
                          : 'If the login continues to fail, please contact DigiRES support'"
                        (onClick)="authService.retryLogin()">
          Try to login again
        </digires-button>
      </div>
      <p>If this problem persists please contact DigiRES IT support:</p>
      <p><a href="mailto:digires_support@rwe.com">digires_support@rwe.com</a></p>
    </div>
  </div>

  <div *ngSwitchCase="UserLoginState.LOGIN_DIGIRES_ERROR" class="login col">
    <div class="login-panel col">
      <h1>DigiRES</h1>
      <p> You do not have the required permission to access DigiRES.</p>
      <p> To request access please send the following text: </p>
      <p> "I would like to request access to DigiRES for [Surname, Given Name; User ID; e-mail address]"</p>
      <p> to: <a href="mailto:digires_support@rwe.com">digires_support@rwe.com</a></p>
    </div>
  </div>

  <ng-container *ngSwitchCase="UserLoginState.FULL_LOGIN_SUCCESS">
    <digires-navbar *ngIf="!isEmbedded"></digires-navbar>
    <main>
      <router-outlet></router-outlet>
    </main>
  </ng-container>

</ng-container>
