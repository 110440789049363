<mat-dialog-content xmlns="http://www.w3.org/1999/html">
  <h2>Support & Help</h2>
  <p class="support_line">
    <mat-icon>email</mat-icon>
    <span class="support_label">Email:</span>
    <a class="support_label" href="mailto:digires_support@rwe.com">digires_support@rwe.com</a>
  </p>
  <p class="support_line">
    <mat-icon>info_outline</mat-icon>
    <span class="support_label">Version:</span>
    <span>{{environment.version}} - {{environment.revid}}</span>
  </p>
</mat-dialog-content>
<mat-dialog-actions class="ok_button_bar">
  <div><!--Intentionally left blank--></div>
  <button mat-button class="btnNeutral" [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
