import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PipelineType } from './model/general/PipelineType';

export class FormUtils {

  public static arraysEquals(a: any[], b: any[]): boolean {
    return a?.length === b?.length && a.every(elementInA => b.some(elementInB => elementInA === elementInB));
  }

  public static clearFormGroup(formGroup: FormGroup) {
    for (const key of Object.keys(formGroup.controls)) {
      formGroup.removeControl(key);
    }
    formGroup.markAsPristine();
    formGroup.markAsUntouched();
  }

  public static checkForErrors(formGroup: FormGroup): number {
    let errors = 0;
    if (formGroup && formGroup.invalid) {
      const stack: AbstractControl[] = [formGroup];
      while (stack.length > 0) {
        const current = stack.pop();
        if (current['controls']) {
          stack.push(...Object.values((current as FormGroup).controls));
        }
        if (!!current.errors) {
          errors++;
        }
      }
    }
    return errors;
  }

  public static checkDirty(formGroup: FormGroup): boolean {
    let formIsDirty = false;
    if (formGroup) {
      for (const control of Object.values(formGroup['controls'])) {
        if (control['controls']) {
          formIsDirty = this.checkDirty(control as FormGroup);
        } else if (control.dirty) {
          formIsDirty = true;
          control.markAsDirty();
        }
      }
    }
    return formIsDirty;
  }

  public static calculateChangesBetween(initialValues: any, currentValues: any): Change[] {
    return this.recursiveChangeCheck('', [], initialValues, currentValues);
  }

  private static recursiveChangeCheck(
    path: string,
    changes: Change[],
    initialValues: any,
    currentValues: any,
  ): Change[] {
    const keys = new Set<string>();
    if (initialValues) {
      Object.keys(initialValues).forEach(key => keys.add(key));
    }
    if (currentValues) {
      Object.keys(currentValues).forEach(key => keys.add(key));
    }
    for (const key of Array.from(keys)) {
      const initialValue = initialValues?.[key];
      const currentValue = currentValues?.[key];
      if (this.isObject(initialValue || this.isObject(currentValue))) {
        this.recursiveChangeCheck(path + key, changes, initialValue, currentValue);
      } else if (Array.isArray(initialValue) || Array.isArray(currentValue)) {
        if (!this.arrayEquals(initialValue, currentValue)) {
          changes.push({
            path, key, initialValue, currentValue, type: this.typeOfChange(initialValue, currentValue),
          });
        }
      } else if (initialValue !== currentValue) {
        changes.push({
          path, key, initialValue, currentValue, type: this.typeOfChange(initialValue, currentValue),
        });
      }
    }
    return changes;
  }

  private static isObject(obj: any): boolean {
    return !!obj && typeof obj === 'object' && !Array.isArray(obj);
  }

  private static typeOfChange(initialValue: any, currentValue: any): 'ADD' | 'REMOVE' | 'REPLACE' {
    if (!initialValue && !!currentValue) {
      return 'ADD';
    }
    if (!!initialValue && !currentValue) {
      return 'REMOVE';
    }
    return 'REPLACE';
  }

  private static arrayEquals(initial: any, current: any): boolean {
    if (initial === current) {
      return true;
    }
    if (initial.length !== current.length) {
      return false;
    }
    for (let i = 0; i < initial.length; i++) {
      if (initial[i] !== current[i]) {
        return false;
      }
    }
    return true;
  }

  public static validatorProjectAcquiredDate(ctrlNamePipelineType: string, ctrlNameAcquiredDate: string): ValidatorFn {
    return (control: FormGroup): { [key: string]: string } | null => {
      const ctrlPipelineType = control.get(ctrlNamePipelineType);
      const ctrlAcquiredDate = control.get(ctrlNameAcquiredDate);
      const isAcquisition = ctrlPipelineType?.value === PipelineType.ACQUISITION;
      const isPresent = !!ctrlAcquiredDate?.value;
      if (isAcquisition && !isPresent) {
        ctrlAcquiredDate.setErrors({'missing': null});
      } else {
        ctrlAcquiredDate.setErrors(null);
      }
      return null;
    };
  }

  public static validatorBothOrNeither(a: string, b: string, mandatory: boolean = false): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const ctrlA = group.get(a);
      const ctrlB = group.get(b);
      const valueA = ctrlA?.value;
      const valueB = ctrlB?.value;
      if (!valueA !== !valueB) {
        if (valueA) {
          ctrlB.setErrors({missing: true});
        }
        if (valueB) {
          ctrlA.setErrors({missing: true});
        }
      } else {
        if (ctrlA) {
          ctrlA.setErrors((mandatory && !valueA) ? {required: true} : null);
        }
        if (ctrlB) {
          ctrlB.setErrors((mandatory && !valueB) ? {required: true} : null);
        }
      }
      return null;
    };
  }

}
export interface Change {
  path: string;
  key: string;
  initialValue: any;
  currentValue: any;
  type: 'ADD' | 'REMOVE' | 'REPLACE';
}
