export enum DevelopmentCluster {
  NONE = 'NONE',
  AUSTRALIA = 'AUSTRALIA',
  FRANCE = 'FRANCE',
  GERMANY_NETHERLANDS = 'GERMANY_NETHERLANDS',
  ITALY_IBERIA = 'ITALY_IBERIA',
  SCANDINAVIA_BALTICS = 'SCANDINAVIA_BALTICS',
  UK_IRELAND = 'UK_IRELAND',
}
export const devClusterToLabel: {[developmentCluster: string]: string} = {
  [DevelopmentCluster.NONE]: 'Not within a defined Cluster',
  [DevelopmentCluster.AUSTRALIA]: 'Australia',
  [DevelopmentCluster.FRANCE]: 'France',
  [DevelopmentCluster.GERMANY_NETHERLANDS]: 'Germany/Netherlands',
  [DevelopmentCluster.ITALY_IBERIA]: 'Italy/Iberia',
  [DevelopmentCluster.SCANDINAVIA_BALTICS]: 'Scandinavia/Baltics',
  [DevelopmentCluster.UK_IRELAND]: 'UK/Ireland',
};
