import {Directive, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {
  ReadOnlyReason,
  ReadOnlyReasonToTooltip,
  ReadOnlyState,
} from '../../project-details/ReadOnlyStateManager';
import {FinancialModelReadManager} from '../../project-details/FinancialModelReadManager';

@Directive()
@Injectable()
export class AbstractInputDirective implements OnInit, OnChanges {

  @Output() onChange: EventEmitter<{ property: string | string[], value: string }>
    = new EventEmitter<{ property: string | string[], value: string }>();
  @Input() formGroup: FormGroup;
  @Input() initialData: any;
  @Input() formCtrlName: string;
  @Input() formCtrlPath: string[] = [];
  @Input() placeholder: string;
  @Input() tooltip: string = undefined;
  @Input() required: boolean = false;
  @Input() readOnlyState: ReadOnlyState;
  @Input() readOnly: boolean = false;
  @Input() readOnlyReason: string = undefined;
  @Input() finModel: FinancialModelReadManager;
  @Input() error: string = undefined;
  @Input() errors: { id: string, message: string }[] = [];

  cachedValidator: ValidatorFn;
  deepFormGroup: FormGroup;
  deepInitialData: any;
  fullFormCtrlName: string;

  ngOnInit() {
    this.deepFormGroup = this.getDeepFormGroup();
    this.deepInitialData = this.getInitial();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fullFormCtrlName = this.calculateFullFormCtrlName();
    if ((changes.readOnlyState && this.readOnlyState) || (changes.finModel && this.finModel)) {
      const oldReadOnly = this.readOnly;
      const readOnlyBecauseOfFm = this.finModel?.isRead(this.fullFormCtrlName);
      const calculatedReadOnly = this.readOnlyState?.isReadOnly(this.formCtrlName) || readOnlyBecauseOfFm || null;
      if (calculatedReadOnly !== null && calculatedReadOnly !== undefined) {
        this.readOnly = calculatedReadOnly;
      }
      this.readOnlyReason = this.readOnlyState
        ? (this.readOnlyState.getReadOnlyTooltip(this.formCtrlName) || '')
        : this.readOnlyReason;
      if (!this.readOnlyReason && readOnlyBecauseOfFm) {
        this.readOnlyReason = ReadOnlyReasonToTooltip[ReadOnlyReason.FIN_MODEL];
      }
      if (this.deepFormGroup && oldReadOnly !== this.readOnly) {
        if (this.readOnly) {
          if (this.deepFormGroup.validator) {
            this.cachedValidator = this.deepFormGroup.validator;
            this.deepFormGroup.setValidators(null);
            this.deepFormGroup.updateValueAndValidity();
          }
        } else if (this.cachedValidator) {
          this.deepFormGroup.setValidators(this.cachedValidator);
          this.deepFormGroup.updateValueAndValidity();
        }
      }
    }
  }

  isDirty(): boolean {
    if (this.formGroup && this.initialData) {
      const control = this.getCtrl();
      return control && control.dirty && control.value !== this.deepInitialData;
    }
    return false;
  }

  onInputChanged(): void {
    const currentVal = this.getValue();
    this.onChange.emit({property: this.formCtrlName, value: currentVal});
  }

  private getDeepFormGroup(): FormGroup {
    let current = this.formGroup;
    for (const part of this.formCtrlPath) {
      const next = current.get(part);
      if (next instanceof FormGroup) {
        current = next;
      } else {
        throw new Error(`Can not find FormGroup at path ${this.formCtrlPath}`);
      }
    }
    return current;
  }

  getCtrl(): AbstractControl {
    return this.deepFormGroup.get(this.formCtrlName);
  }

  getValue() {
    return this.getCtrl()?.getRawValue();
  }

  patch(value: any) {
    this.getCtrl().patchValue(value);
  }

  getInitial() {
    if (!this.initialData) {
      return null;
    }
    let current = this.initialData;
    for (const part of this.formCtrlPath) {
      const next = current[part];
      if (next) {
        current = next;
      } else {
        return undefined;
      }
    }
    if (current) {
      return current[this.formCtrlName];
    }
    return undefined;
  }

  private calculateFullFormCtrlName() {
    if (this.formCtrlPath) {
      return this.formCtrlPath.join('.') + this.formCtrlName;
    }
    return this.formCtrlName;
  }

}
