export enum ProjectPhase {
  DEVELOPMENT = 'DEVELOPMENT',
  CONSTRUCTION = 'CONSTRUCTION',
  OPERATIONS = 'OPERATIONS',
}
export const phaseToLabel: {[value: string]: string} = {
  [ProjectPhase.DEVELOPMENT]: 'Development',
  [ProjectPhase.CONSTRUCTION]: 'Construction',
  [ProjectPhase.OPERATIONS]: 'Operations',
};
