export enum ProjectStage {
  ORIGINATION = 'ORIGINATION',
  EARLY_DEV = 'EARLY_DEV',
  MID_DEV = 'MID_DEV',
  LATE_DEV = 'LATE_DEV',
  READY_FID = 'READY_FID',
  POST_FID = 'POST_FID',
  POST_FID_FULL = 'POST_FID_FULL',
  PRE_CONSTRUCTION = 'PRE_CONSTRUCTION',
  CONSTRUCTION = 'CONSTRUCTION',
  ENERGIZATION_COD = 'ENERGIZATION_COD',
  POST_COD = 'POST_COD',
  OPERATIONS = 'OPERATIONS',
}
export const stageToLabel: Record<ProjectStage, string> = {
  [ProjectStage.ORIGINATION]: '1. - Origination',
  [ProjectStage.EARLY_DEV]: '2. - Early Development',
  [ProjectStage.MID_DEV]: '3. - Mid Development',
  [ProjectStage.LATE_DEV]: '4. - Late Development',
  [ProjectStage.READY_FID]: '5. - Ready for FID',
  [ProjectStage.POST_FID]: '5a. - Post FID (Conditionally)',
  [ProjectStage.POST_FID_FULL]: '5b. - Post FID (Fully Secured)',
  [ProjectStage.PRE_CONSTRUCTION]: '6. - Pre-Construction',
  [ProjectStage.CONSTRUCTION]: '7. - Construction',
  [ProjectStage.ENERGIZATION_COD]: '8. - Energization / COD',
  [ProjectStage.POST_COD]: '9. - Post COD',
  [ProjectStage.OPERATIONS]: 'Operations',
};

export const stageToPmaRefIndex: Record<ProjectStage, string> = {
  [ProjectStage.ORIGINATION]: '1',
  [ProjectStage.EARLY_DEV]: '2',
  [ProjectStage.MID_DEV]: '3',
  [ProjectStage.LATE_DEV]: '4',
  [ProjectStage.READY_FID]: '5',
  [ProjectStage.POST_FID]: '5',
  [ProjectStage.POST_FID_FULL]: '5',
  [ProjectStage.PRE_CONSTRUCTION]: '6',
  [ProjectStage.CONSTRUCTION]: '7',
  [ProjectStage.ENERGIZATION_COD]: '8',
  [ProjectStage.POST_COD]: '9',
  [ProjectStage.OPERATIONS]: '10',
};

export const stageToShortLabel: Record<ProjectStage, string> = {
  [ProjectStage.ORIGINATION]: '1.',
  [ProjectStage.EARLY_DEV]: '2.',
  [ProjectStage.MID_DEV]: '3.',
  [ProjectStage.LATE_DEV]: '4.',
  [ProjectStage.READY_FID]: '5.',
  [ProjectStage.POST_FID]: '5a.',
  [ProjectStage.POST_FID_FULL]: '5b.',
  [ProjectStage.PRE_CONSTRUCTION]: '6.',
  [ProjectStage.CONSTRUCTION]: '7.',
  [ProjectStage.ENERGIZATION_COD]: '8.',
  [ProjectStage.POST_COD]: '9.',
  [ProjectStage.OPERATIONS]: '10.',
}

export const devStages: ProjectStage[] = [
  ProjectStage.ORIGINATION,
  ProjectStage.EARLY_DEV,
  ProjectStage.MID_DEV,
  ProjectStage.LATE_DEV,
  ProjectStage.READY_FID,
  ProjectStage.POST_FID,
  ProjectStage.POST_FID_FULL,
];

export const conStages: ProjectStage[] = [
  ProjectStage.PRE_CONSTRUCTION,
  ProjectStage.CONSTRUCTION,
  ProjectStage.ENERGIZATION_COD,
  ProjectStage.POST_COD,
];

export const stageToHtmlChevron: {[value: string]: string} = Object.assign(Object.assign({}, stageToLabel), {
  [ProjectStage.POST_FID]: '5a. - Post FID<br>(Conditionally)',
  [ProjectStage.POST_FID_FULL]: '5b. - Post FID<br>(Fully Secured)',
});
export function getStageIndex(stage: ProjectStage): number {
  return Object.values(ProjectStage).indexOf(stage);
}
export function isLesserStage(currentStage: ProjectStage, previousStage: ProjectStage): boolean {
  return getStageIndex(currentStage) < getStageIndex(previousStage);
}

export function isDevelopmentStage(currentStage: ProjectStage): boolean {
  return devStages.includes(currentStage);
}

export function isConstructionStage(currentStage: ProjectStage): boolean {
  return conStages.includes(currentStage);
}
