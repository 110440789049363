import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileRefTableComponent, FileReferenceData } from './file-ref-table/file-ref-table.component';


@Component({
  selector: 'digires-file-ref-dialog',
  templateUrl: './file-ref-dialog.component.html',
  styleUrls: ['./file-ref-dialog.component.scss']
})
export class FileRefDialogComponent implements AfterViewInit {

  @ViewChild(FileRefTableComponent) fileRefTableComponent: FileRefTableComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {entries: FileReferenceData[]},
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

}
