import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserGuidesService } from '../../../services/user-guide/user-guides.service';

@Component({
  selector: 'digires-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {id: string, title: string, name: string},
    private userGuidesService: UserGuidesService,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private snackBar: MatSnackBar,
  ) {}

  delete() {
    this.userGuidesService.deleteUserGuidesVideo(this.data.id).subscribe((_) => {
      this.snackBar.open('Item Deleted!', 'OK', {
        duration: 2000,
      });
      this.dialogRef.close({ event: 'success', id: this.data.id });
    });
  }
}
