import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UserGuidesService } from 'src/app/shared/services/user-guide/user-guides.service';
import {
  FileReferenceType,
  fileRefTypeToAddButtonLabel,
  fileRefTypeToTitle,
  GetReferenceResponse
} from 'src/app/shared/model/user-guides-table.model';
import { UserGuidesData } from 'src/app/shared/model/userGuide/UserGuideData';

@Component({
  selector: 'digires-user-guides-table-dialog',
  templateUrl: './user-guides-table-dialog.component.html',
})
export class UserGuidesTableDialogComponent {

  readonly title = fileRefTypeToTitle[this.data.type];
  readonly addButtonLabel = fileRefTypeToAddButtonLabel[this.data.type];
  readonly allUserGuidesData$: Observable<GetReferenceResponse[]> =
    this.userGuideService.getAllUserGuidesData(this.data.type);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {type: FileReferenceType},
    private userGuideService: UserGuidesService
  ) {}

}
