import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LessonLearnt } from '../../model/lessons-learnt/LessonLearnt';
import { Cache } from '../Cache';

@Injectable({
  providedIn: 'root'
})
export class LessonsLearntService {

  private readonly lessonsLearntCache = new Cache<LessonLearnt>(element => element.id);

  constructor(
    private client: HttpClient,
  ) { }

  observeLessonsLearnt(): Observable<LessonLearnt[]> {
    return this.lessonsLearntCache.observeAllElementsList();
  }

  fetchFilteredLessonsLearnt(filter: any) {
    this.lessonsLearntCache.invalidateAll();
    return this.lessonsLearntCache.putAll(firstValueFrom(this.client.get<LessonLearnt[]>('/api/lessonsLearnt', {
      params: new HttpParams({
        fromObject: filter
      })
    })));
  }

  async getLessonsLearntForProject(projectId: String) {
    return firstValueFrom(this.client.get<LessonLearnt[]>('/api/lessonsLearnt/' + projectId));
  }

  async addLessonLearnt(lessonLearnt: LessonLearnt) {
    const promise = firstValueFrom(this.client.post<LessonLearnt[]>('/api/lessonsLearnt', lessonLearnt));
    return await promise;
  }

  async updateLessonLearnt(lessonLearnt: LessonLearnt) {
    const promise = firstValueFrom(this.client.put<LessonLearnt>('/api/lessonsLearnt', lessonLearnt));
    await promise;
    return this.lessonsLearntCache.putOne(lessonLearnt.id, promise);
  }
}
