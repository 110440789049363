<div class="row">
  <h2>{{data.title}}</h2>
</div>

<mat-dialog-content>
  <p>{{data.message}}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <div *ngFor="let action of data.actions" class="col">
    <button mat-button type="button" class="{{flairToCss[action.flair]}}"
            [disabled]="action.disabled"
            [mat-dialog-close]="action.id">
      {{action.label}}
    </button>
    <p>{{action.tooltip}}</p>
  </div>
</mat-dialog-actions>
