export enum ProjectManagingApplication {
  DIGIRES_PORTFOLIO = 'DIGIRES_PORTFOLIO',
  GREEN_OS = 'GREEN_OS',
	ASSET_MASTER_DATA = 'ASSET_MASTER_DATA',
}

export const projectManagingApplicationToLabel: {[value: string]: string} = {
  [ProjectManagingApplication.DIGIRES_PORTFOLIO]: 'DigiRES - Project List',
  [ProjectManagingApplication.GREEN_OS]: 'GreenOS - Development',
  [ProjectManagingApplication.ASSET_MASTER_DATA]: 'Asset Master Data',
};
