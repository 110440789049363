import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FileReferenceType,
  fileRefTypeToAddButtonLabel,
  fileRefTypeToTitle,
  GetReferenceResponse
} from '../../shared/model/user-guides-table.model';
import { UserGuidesService } from '../../shared/services/user-guide/user-guides.service';
import { ActivatedRoute } from '@angular/router';
import { RoutePaths } from '../../RoutePaths';

@Component({
  selector: 'digires-user-guides-page',
  templateUrl: './user-guides-page.component.html',
  styleUrls: ['./user-guides-page.component.scss']
})
export class UserGuidesPageComponent implements OnInit{

  fileRefType = FileReferenceType.SLIDE;
  title = fileRefTypeToTitle[this.fileRefType];
  addButtonLabel = fileRefTypeToAddButtonLabel[this.fileRefType];
  allUserGuidesData$: Observable<GetReferenceResponse[]> =
    this.userGuideService.getAllUserGuidesData(this.fileRefType);

  constructor(
    private userGuideService: UserGuidesService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.fileRefType = this.getFileRefTypeByUrlSegment();
    this.title = fileRefTypeToTitle[this.fileRefType];
    this.addButtonLabel = fileRefTypeToAddButtonLabel[this.fileRefType];
    this.allUserGuidesData$ =
      this.userGuideService.getAllUserGuidesData(this.fileRefType);
  }

  private getFileRefTypeByUrlSegment(): FileReferenceType {
    const url = this.route.snapshot.url;
    const lastSegment = url[url.length - 1]?.path || '';
    switch (lastSegment) {
      case RoutePaths.USER_GUIDES_SLIDES: return FileReferenceType.SLIDE;
      case RoutePaths.USER_GUIDES_VIDEOS: return FileReferenceType.VIDEO;
      default:
        console.error(`Unsupported URL segment '${lastSegment}' in URL '${url}'`);
        return FileReferenceType.SLIDE;
    }
  }

}
