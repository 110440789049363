import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateUtil } from '../../../DateUtil';
import { NewsCategory, newsCategoryToLabel } from '../../../model/news/NewsCategory';
import { NewsItem } from '../../../model/news/NewsItem';
import { newsTagToLabel } from '../../../model/news/NewsTag';
import { UserService } from '../../../services/user/user.service';
import { GetUsers } from '../../../services/user/GetUsers';
import { NewsItemDialogComponent, NewsItemDialogResult } from '../../news-item-dialog/news-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NewsService } from '../../../services/other/news.service';
import { PutNews } from '../../../services/other/PutNews';
import { AuthService } from '../../../../auth/auth.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { newsCategoryToFilter } from '../NewsFilter';
import { ProjectListService } from 'src/app/shared/services/project-list/project-list.service';
import { GetProjects } from 'src/app/shared/services/project-list/GetProjects';
import { RoutePaths } from 'src/app/RoutePaths';

@Component({
  selector: 'digires-news-list-entry',
  templateUrl: './news-list-entry.component.html',
  styleUrls: ['./news-list-entry.component.scss']
})
export class NewsListEntryComponent implements OnChanges {

  public readonly newsCategory: typeof NewsCategory = NewsCategory;

  public readonly categoryToFilter = newsCategoryToFilter;
  public readonly categoryToLabel = newsCategoryToLabel;
  public readonly tagToLabel = newsTagToLabel;

  @Input() newsItem: NewsItem;
  @Input() editable: boolean = true;
  author: GetUsers;
  canEdit: boolean;
  loading: boolean;

  projectName: string;
  projectId: string;

  routePaths = RoutePaths;

  constructor(
    private userService: UserService,
    private newsService: NewsService,
    private authService: AuthService,
    private projectsService: ProjectListService,
    private dialog: MatDialog,
  ) { }

  ngOnChanges() {
    this.projectId = this.newsItem.projectIds[0];
    this.projectsService.fetchProjectById(this.projectId).then((result: GetProjects) => {
      this.projectName = result.name;
    });

    this.canEdit = this.authService.canEditNewsItem(this.newsItem);
    if (this.newsItem?.authorUserId) {
      this.loading = true;
      this.userService.loadUserById(this.newsItem.authorUserId)
        .then(user => {
          this.author = user;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  getCreatedDateFormatted(): string {
    return DateUtil.ddmmyyyyHHMM_TZ(this.newsItem.createdAt);
  }

  onEdit() {
    this.dialog.open(NewsItemDialogComponent,
      {
        hasBackdrop: true,
        minWidth: '50%',
        data: { existingNewsItem: this.newsItem },
      })
      .afterClosed()
      .subscribe((value: NewsItemDialogResult) => {
        if (value?.operation === 'UPDATE') {
          this.updateNewsItem(value);
        } else if (value?.operation === 'DELETE') {
          this.deleteNewsItem();
        }
      });
  }

  private updateNewsItem(value: NewsItemDialogResult) {
    this.loading = true;
    this.newsService.putNewsItem(this.newsItem.id, value.payload as PutNews)
      .finally(() => {
        this.loading = false;
      });
  }

  private deleteNewsItem() {
    this.dialog.open(ConfirmDialogComponent,
      {
        hasBackdrop: true,
        data: {
          title: 'Delete News item',
          message: 'Do you want to permanently delete this news item?',
          actions: [
            {
              id: 'abort',
              label: 'Abort',
              tooltip: 'Keeps the News Item',
              disabled: false,
              flair: 'neutral',
            },
            {
              id: 'delete',
              label: 'Delete',
              tooltip: 'Deletes the News Item',
              disabled: false,
              flair: 'negative',
            },
          ],
        },
      })
      .afterClosed()
      .subscribe(value => {
        if (value === 'delete') {
          this.loading = true;
          this.newsService.deleteNewsItem(this.newsItem)
            .finally(() => {
              this.loading = false;
            });
        }
      });
  }

}
