<form [formGroup]="entry.radBtnFormGroup" class="row-left">

  <digires-fake-input class="standard-ipt-import-role"
                      placeholder="Role"
                      [readOnlyReasonEnum]="ReadOnlyReason.FEED_IN"
                      [showIndicator]="false"
                      [value]="entry.role?.name || 'Unknown Role'"
  ></digires-fake-input>

  <digires-fake-input class="standard-ipt-import-name"
                      placeholder="{{entry.existingIptMember ? 'Existing Team Member' : 'No Team Member'}}"
                      [readOnlyReasonEnum]="ReadOnlyReason.FEED_IN"
                      [showIndicator]="false"
                      [value]="entry.existingIptMember?.getUser()?.fullName || ''"
  ></digires-fake-input>

  <digires-fake-input class="standard-ipt-import-name"
                      placeholder="Standard IPT Member"
                      [readOnlyReasonEnum]="ReadOnlyReason.FEED_IN"
                      [showIndicator]="false"
                      [value]="entry.standardIptMemberName"
  ></digires-fake-input>

  <ng-container *ngFor="let action of entry.actions">
    <div *ngIf="!action" class="standard-ipt-import-radio"><!--layout dummy intentionally left blank--></div>
  </ng-container>
  <mat-radio-group formControlName="action">
    <ng-container *ngFor="let action of entry.actions">
      <mat-radio-button *ngIf="!!action"
                        class="standard-ipt-import-radio"
                        [value]="action"
      >
        {{importActionToLabel[action]}}
        <mat-icon
          svgIcon="tooltip" class="tooltip-icon"
          [matTooltip]="importActionToTooltip[action]"
        ></mat-icon>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</form>
