export enum GlobalRegion {
  NONE = 'NONE',
  EU = 'EU',
  APAC = 'APAC',
  NA = 'NA', // just USA and Canada
  LATAM = 'LATAM',
}
export const globalRegionToLabel: {[globalRegion: string]: string} = {
  [GlobalRegion.NONE]: 'None',
  [GlobalRegion.EU]: 'EU',
  [GlobalRegion.APAC]: 'APAC',
  [GlobalRegion.NA]: 'NA',
  [GlobalRegion.LATAM]: 'LATAM',
};
export function sortGlobalRegions(globalRegions: GlobalRegion[]) {
  const sortGlobalRegion = {
    EU: 1,
    APAC: 2,
    NA: 3,
    LATAM: 4,
    NONE: 5,
  };
  const maxIdx = Object.values(sortGlobalRegion).length + 1;
  return globalRegions.sort((a, b) => {
    return (sortGlobalRegion[a] || maxIdx) - (sortGlobalRegion[b] || maxIdx);
  });
}
