export enum NewsTag {
  GENERAL = 'GENERAL',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  FINANCIAL = 'FINANCIAL',
  TECHNICAL = 'TECHNICAL',
  SCHEDULE = 'SCHEDULE',
  GRID = 'GRID',
  PERMITS = 'PERMITS',
  ROUTE_TO_MARKET = 'ROUTE_TO_MARKET',
  PROCUREMENT = 'PROCUREMENT',
  FID = 'FID',
  DATA_APPROVAL_PMD = 'DATA_APPROVAL_PMD',
  DATA_APPROVAL_TLD = 'DATA_APPROVAL_TLD',
  DATA_APPROVAL_PMC = 'DATA_APPROVAL_PMC',
}
export const newsTagToLabel: {[newsTag: string]: string} = {
  [NewsTag.GENERAL]: 'General',
  [NewsTag.PROJECT_MANAGEMENT]: 'Project Management',
  [NewsTag.FINANCIAL]: 'Financial',
  [NewsTag.TECHNICAL]: 'Technical',
  [NewsTag.SCHEDULE]: 'Schedule',
  [NewsTag.GRID]: 'Grid',
  [NewsTag.PERMITS]: 'Permits, Planning & Environmental',
  [NewsTag.ROUTE_TO_MARKET]: 'Route to Market',
  [NewsTag.PROCUREMENT]: 'Procurement',
  [NewsTag.FID]: 'FID Approval',
  [NewsTag.DATA_APPROVAL_PMD]: 'Data Approval - PMD',
  [NewsTag.DATA_APPROVAL_TLD]: 'Data Approval - TLD',
  [NewsTag.DATA_APPROVAL_PMC]: 'Data Approval - PMC - Reporting',
};
