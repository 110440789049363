export enum DevelopmentDirectorate {
  FRANCE = 'FRANCE',
  GERMANY_OTHER = 'GERMANY_OTHER',
  OTHER = 'OTHER',
}
export const devDirectorateToLabel: {[value: string]: string} = {
  [DevelopmentDirectorate.FRANCE]: 'France',
  [DevelopmentDirectorate.GERMANY_OTHER]: 'Rest of Germany (excl. North West)',
  [DevelopmentDirectorate.OTHER]: 'Rest of Europe & Asia Pacific',
};
