import {Directive, ElementRef, forwardRef, HostListener, Input, OnChanges} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn} from '@angular/forms';
import {MAT_INPUT_VALUE_ACCESSOR} from '@angular/material/input';
import {formatNumber} from '@angular/common';

export function tsdMin(minValue: number): ValidatorFn {
  return ThousandSeparatorDirective.min(minValue);
}
export function tsdMoreThan(minValue: number): ValidatorFn {
  return ThousandSeparatorDirective.moreThan(minValue);
}
export function tsdMax(maxValue: number): ValidatorFn {
  return ThousandSeparatorDirective.max(maxValue);
}

@Directive({
  selector: 'input[digiresThousandSeparator]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: ThousandSeparatorDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThousandSeparatorDirective),
      multi: true
    }
  ]
})
export class ThousandSeparatorDirective implements ControlValueAccessor, OnChanges {

  @Input() decimalDigits: number = 2;
  private _value: number | null;

  constructor(private element: ElementRef<HTMLInputElement>) {}

  public static min(minValue: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control || !control.value) {
        return null;
      }
      if (typeof control.value === 'number') {
        if (control.value < minValue) {
          return {'lessThenMin': control.value};
        }
      } else if (typeof control.value === 'string') {
        const asNum = this.unformat(control.value);
        if (isNaN(asNum)) {
          return {'NaN': control.value};
        }
        if (asNum < minValue) {
          return {'lessThenMin': asNum};
        }
      } else {
        return {'NaN': control.value};
      }
      return null;
    };
  }

  public static moreThan(lowerBound: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control || !control.value) {
        return null;
      }
      if (typeof control.value === 'number') {
        if (control.value <= lowerBound) {
          return {'lessThenMin': control.value};
        }
      } else if (typeof control.value === 'string') {
        const asNum = this.unformat(control.value);
        if (isNaN(asNum)) {
          return {'NaN': control.value};
        }
        if (asNum <= lowerBound) {
          return {'lessThenMin': asNum};
        }
      } else {
        return {'NaN': control.value};
      }
      return null;
    };
  }

  public static max(maxValue: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control || !control.value) {
        return null;
      }
      if (typeof control.value === 'number') {
        if (control.value > maxValue) {
          return {'moreThanMax': control.value};
        }
      } else if (typeof control.value === 'string') {
        const asNum = this.unformat(control.value);
        if (isNaN(asNum)) {
          return {'NaN': control.value};
        }
        if (asNum > maxValue) {
          return {'moreThanMax': asNum};
        }
      } else {
        return {'NaN': control.value};
      }
      return null;
    };
  }

  private static unformat(value: string): number {
    const localeInfo = this.getLocaleInfo();
    value = value.split(localeInfo.thousandSeparator).join('');
    if (localeInfo.decimalMarker !== '.') {
      value = value.replace(localeInfo.decimalMarker, '.');
    }
    return parseFloat(value);
  }

  private static getLocaleInfo(): {locale: string, thousandSeparator: string, decimalMarker: string} {
    // As per Chris' request we always use the english locale for DigiRES
    return {
      locale: 'en-US',
      thousandSeparator: ',',
      decimalMarker: '.'
    };
  }

  // dummy function
  _onChange(_value: any): void {}

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {
  }

  get value(): string | null {
    return this.decimalDigits < 0 ? this._value + '' : this._value?.toFixed(this.decimalDigits);
  }

  @Input()
  set value(value: string | null) {
    this.formatValue(value, true);
  }

  // called when the user finishes input
  @HostListener('change') ngOnChanges() {
    this.formatValue(this._value, true);
  }

  // called each time the user makes a partial input
  @HostListener('input', ['$event.target.value'])
  input(value) {
    this.formatValue(value, false);
  }

  // called when the value is changed through the form group
  writeValue(value: any) {
    this.formatValue(value, true);
  }

  // takes the string input and formats it nicely as a number. Resets input if it is not a number.
  private formatValue(value: any, updateNative: boolean) {
    if (value === null || value === undefined) {
      this._value = null;
      if (updateNative) {
        this.element.nativeElement.value = '';
      }
      return;
    }
    let asNumber;
    if (typeof value === 'number') {
      asNumber = value;
    } else if (typeof value === 'string') {
      asNumber = ThousandSeparatorDirective.unformat(value);
    } else {
      asNumber = NaN;
    }
    let asString;
    if (isNaN(asNumber)) {
      asString = '';
    } else {
      const localeInfo = ThousandSeparatorDirective.getLocaleInfo();
      const decimalDigits = this.decimalDigits < 0 ? 0 : this.decimalDigits;
      const formatString = `1.0-${decimalDigits}`;
      asString = formatNumber(value, localeInfo.locale, formatString);
    }
    const previousValue: number = this._value;
    if (this.decimalDigits < 0) {
      this._value = asNumber;
    } else {
      this._value = parseFloat(asNumber.toFixed(this.decimalDigits));
    }
    if (updateNative) {
      this.element.nativeElement.value = asString;
    } else {
      this.element.nativeElement.value = value;
    }
    if (previousValue !== asNumber) {
      this._onChange(this._value);
    }
  }
}
