import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { NotificationService } from './shared/services/other/notification.service';
import { CurrentLoginState, UserLoginState } from './auth/CurrentLoginUser';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { RoutePaths } from './RoutePaths';

@Component({
  selector: 'digires-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  readonly UserLoginState: typeof UserLoginState = UserLoginState;
  loginState: CurrentLoginState;
  isEmbedded: boolean;

  constructor(
    public authService: AuthService,
    private notificationService: NotificationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    const icons = {
      tooltip: 'tooltip',
      cancel: 'cancel',
      'navbar-digires-logo': 'navbar/digires-logo',
      'navbar-project-overview': 'navbar/project-overview',
      'navbar-create-project': 'navbar/create-project',
      'navbar-my-digires': 'navbar/my-digires',
      'navbar-address-book': 'navbar/address-book',
      'navbar-report-overview': 'navbar/report-overview',
      'navbar-admin-area': 'navbar/admin-area',
      'navbar-lessons-learnt': 'navbar/lessons-learnt',
      'navbar-acquisitions': 'navbar/acquisitions',
      'navbar-user-menu': 'navbar/user-menu',
      'navbar-user-rights': 'navbar/user-rights',
      'navbar-pma': 'navbar/progression-management-area',
      'navbar-process-module': 'navbar/process-module',
      technology_wind_onshore: 'technology/Wind_Onshore',
      technology_wind_offshore: 'technology/Wind_Offshore',
      technology_solar: 'technology/Solar',
      technology_storage: 'technology/Storage',
      technology_wind_neutral: 'technology/Wind-Neutral',
      technology_solar_neutral: 'technology/Solar-Neutral',
      technology_storage_neutral: 'technology/Storage-Neutral',
      technology_wind_filter_active: 'technology/RWE_Wind_onshore-active',
      technology_solar_filter_active: 'technology/RWE_Photovoltaics-active',
      technology_storage_filter_active: 'technology/RWE_storage-active',
      technology_wind_filter_inactive: 'technology/RWE_Wind_onshore-inactive',
      technology_solar_filter_inactive: 'technology/RWE_Photovoltaics-inactive',
      technology_storage_filter_inactive: 'technology/RWE_storage-inactive',
      'news-category-highlight': 'news/highlight',
      milestone_governance: 'milestone-categories/governance',
      milestone_common_activities: 'milestone-categories/common_activities',
      milestone_project_management: 'milestone-categories/project_management',
      milestone_project_economics: 'milestone-categories/project_economics',
      milestone_land: 'milestone-categories/land',
      milestone_permit: 'milestone-categories/permit',
      milestone_grid: 'milestone-categories/grid',
      milestone_wind: 'milestone-categories/wind',
      milestone_yield: 'milestone-categories/yield',
      milestone_other: 'milestone-categories/other',
      milestone_contracts: 'milestone-categories/contracts',
      milestone_perf_tracked: 'navbar/report-overview',
      finance: 'navbar/finance',
      information: 'RWE_Information',
    };
    for (const iconEntry of Object.entries(icons)) {
      const iconUrl = '/assets/icons/' + iconEntry[1] + '.svg';
      const trustedResUrl =
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl);
      this.matIconRegistry.addSvgIcon(iconEntry[0], trustedResUrl);
    }
  }

  ngOnInit() {
    this.titleService.setTitle(environment.title || 'DigiRES');
    this.authService
      .observeLoginState()
      .subscribe(loginState => {
        this.loginState = loginState;
      });
    this.notificationService
      .observeNotification()
      .subscribe((message) => {
        this.snackbar.open(message, 'Dismiss', {duration: 15000});
      });
    this.route.url.subscribe(() => {
      const fullPath = this.location.path(false);
      this.isEmbedded = fullPath.startsWith(`/${RoutePaths.PROJECT_DETAILS_EMBEDDED}/`);
    })

    window.addEventListener('table-report-ready', () => {
      const wrapper = document.querySelector('mft-wc-wrapper');
      if (wrapper) {
        wrapper.innerHTML = '';
        const tableReportElement = document.createElement('table-report');
        wrapper.appendChild(tableReportElement);
      }
    });
  }

}
