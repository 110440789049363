import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NewsCategory } from '../../model/news/NewsCategory';
import { NewsItem } from '../../model/news/NewsItem';
import { Page } from '../../model/Page';
import { NewsService } from '../../services/other/news.service';
import { NewsFilterState } from './NewsFilterState';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllNewsFilters, NewsFilter } from './NewsFilter';

@Component({
  selector: 'digires-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, OnDestroy, OnChanges {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  private readonly PAGE_SIZE = 5;
  private readonly MAX_AUTO_RELOAD_ATTEMPTS = 5;
  allFilters: NewsFilter[] = AllNewsFilters;

  @Input() projectId: string;
  @Input() allProjectIds: string[];
  filterState: NewsFilterState = {
    [NewsCategory.STATUS_UPDATE]: true,
    [NewsCategory.HIGHLIGHT]: true,
    [NewsCategory.RISK]: true,
    [NewsCategory.REPORT_COMMENT]: true,
    [NewsCategory.REPORT_VALIDATION_PMD]: true,
    [NewsCategory.REPORT_VALIDATION_TLD]: true,
    [NewsCategory.REPORT_VALIDATION_PMC]: true,
    [NewsCategory.REPORT_VALIDATION_PPU]: true,
  };
  allNews: NewsItem[];
  filteredNews: NewsItem[];
  currentPageNr: number;
  totalPages: number;
  autoReloadAttempts: number;
  loading: boolean;

  constructor(
    private newsService: NewsService,
  ) {}

  ngOnInit() {
    this.newsService
      .observeNewsUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe(projectIds => {
        if (this.projectId && !projectIds.includes(this.projectId)) {
          return;
        }
        this.reset();
        this.loadMoreNews();
      });
    if (!this.projectId) {
      this.reset();
      this.loadMoreNews();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnChanges() {
    this.reset();
    this.loadMoreNews();
  }

  private reset() {
    this.allNews = [];
    this.filteredNews = [];
    this.currentPageNr = 0;
    this.totalPages = 1;
    this.autoReloadAttempts = 0;
    this.loading = false;
  }

  private loadMoreNews() {
    this.loading = true;
    if (this.projectId) {
      this.newsService.loadNewsForProject(this.projectId, this.currentPageNr, this.PAGE_SIZE)
        .then(page => {
          this.onNewsLoaded(page);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.newsService.loadNewsForAllProjects(this.allProjectIds || [], this.currentPageNr, this.PAGE_SIZE)
        .then(page => {
          this.onNewsLoaded(page);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  private onNewsLoaded(page: Page<NewsItem>) {
    this.totalPages = page.totalPages;
    this.allNews = this.allNews.concat(page.content);
    this.filteredNews = this.allNews.filter(item => this.filterState[item.category]);
    if (this.filteredNews.length === 0 && this.autoReloadAttempts < this.MAX_AUTO_RELOAD_ATTEMPTS) {
      this.autoReloadAttempts++;
      this.onLoadNextPage();
    }
  }

  onLoadNextPage(): void {
    this.currentPageNr++;
    if (this.currentPageNr < this.totalPages) {
      this.loadMoreNews();
    }
  }

  onFilterChanged(filter: NewsFilter) {
    for (const category of filter.categories) {
      this.filterState[category] = !this.filterState[category];
    }

    const filterIndex: number = this.allFilters.findIndex(f => f.id === filter.id);
    if (filterIndex < 0) {
      return;
    }

    this.allFilters[filterIndex].checked = !this.allFilters[filterIndex].checked;
    this.allFilters = JSON.parse(JSON.stringify(this.allFilters));
    this.filteredNews = this.allNews.filter(item => this.filterState[item.category]);
  }

}
