export enum Technology {
  WIND_ONSHORE = 'WIND_ONSHORE',
  SOLAR = 'SOLAR',
  STORAGE = 'STORAGE',
}
export const technologyToLabel: {[technology: string]: string} = {
  [Technology.WIND_ONSHORE]: 'Onshore Wind',
  [Technology.SOLAR]: 'Solar',
  [Technology.STORAGE]: 'Storage',
};
export const technologyToIcon: {[technology: string]: string} = {
  [Technology.WIND_ONSHORE]: 'technology_wind_onshore',
  [Technology.SOLAR]: 'technology_solar',
  [Technology.STORAGE]: 'technology_storage',
};
export const technologyToNeutralIcon: {[technology: string]: string} = {
  [Technology.WIND_ONSHORE]: 'technology_wind_neutral',
  [Technology.SOLAR]: 'technology_solar_neutral',
  [Technology.STORAGE]: 'technology_storage_neutral',
};
export const technologyToFilterIcon: {[technology: string]: {[active: string]: string}} = {
  [Technology.WIND_ONSHORE]: {
    true: 'technology_wind_filter_active',
    false: 'technology_wind_filter_inactive',
  },
  [Technology.SOLAR]: {
    true: 'technology_solar_filter_active',
    false: 'technology_solar_filter_inactive',
  },
  [Technology.STORAGE]: {
    true: 'technology_storage_filter_active',
    false: 'technology_storage_filter_inactive',
  },
};
export const technologyToColor: {[technology: string]: string} = {
  [Technology.WIND_ONSHORE]: '#1D4477', // see $RWE-Blue in src/_colors
  [Technology.SOLAR]: '#EF7D00', // see $Accent-Orange in src/_colors
  [Technology.STORAGE]: '#00B1EB', // see $Energy-Blue in src/_colors
};

export enum TechnologyUrls {
  windonshore = 'windonshore',
  solar = 'solar',
  storage = 'storage',
}
export const technologyToUrl = {
  [Technology.WIND_ONSHORE]: TechnologyUrls.windonshore,
  [Technology.SOLAR]: TechnologyUrls.solar,
  [Technology.STORAGE]: TechnologyUrls.storage,
};
export const urlToTechnology = {
  [TechnologyUrls.windonshore]: Technology.WIND_ONSHORE,
  [TechnologyUrls.solar]: Technology.SOLAR,
  [TechnologyUrls.storage]: Technology.STORAGE,
};
export function getTechnologyIndex(technology: Technology): number {
  return Object.values(Technology).indexOf(technology);
}
