import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import {
  FileReferenceType,
  PostPutReferenceRequest,
  GetReferenceResponse,
} from '../../model/user-guides-table.model';
import { NotificationService } from '../other/notification.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuidesService {
  private readonly URL = '/api/file-reference';

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {}

  getAllUserGuidesData(
    type: FileReferenceType
  ): Observable<GetReferenceResponse[]> {
    return this.http.get<GetReferenceResponse[]>(`${this.URL}?type=${type}`);
  }

  createUserGuidesVideo(
    body: PostPutReferenceRequest
  ): Promise<GetReferenceResponse> {
    const promise = firstValueFrom(this.http.post<GetReferenceResponse>(this.URL, body));
    this.notificationService.handlePromise(
      'User Guides created successfully!',
      'Failed to create User Guides ...',
      promise
    );
    return promise;
  }

  editUserGuidesVideo(
    id: string,
    body: PostPutReferenceRequest
  ): Promise<GetReferenceResponse> {
    const promise =  firstValueFrom(this.http.put<GetReferenceResponse>(`${this.URL}/${id}`, body));

    this.notificationService.handlePromise(
      'User Guides saved successfully!',
      'Failed to save User Guides ...',
      promise
    );
    return promise;
  }

  deleteUserGuidesVideo(id: string): Observable<void> {
    return this.http.delete<void>(`${this.URL}/${id}`);
  }

}
