import {Component, Inject, OnInit} from '@angular/core';
import {NewsCategory, newsCategoryToLabel} from '../../model/news/NewsCategory';
import {NewsTag, newsTagToLabel} from '../../model/news/NewsTag';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PostNews} from '../../services/other/PostNews';
import {PutNews} from '../../services/other/PutNews';
import {NewsItem} from '../../model/news/NewsItem';

@Component({
  selector: 'digires-news-item-dialog',
  templateUrl: './news-item-dialog.component.html',
  styleUrls: ['./news-item-dialog.component.scss']
})
export class NewsItemDialogComponent implements OnInit {

  static readonly DEFAULT_CATEGORY = NewsCategory.STATUS_UPDATE;
  static readonly DEFAULT_TAG = NewsTag.GENERAL;

  readonly allCategories = Object.values(NewsCategory);
  readonly allTags = Object.values(NewsTag);
  readonly isEdit;
  readonly canDelete;
  formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    projectId: string,
    existingNewsItem: NewsItem,
  }) {
    this.isEdit = !!data.existingNewsItem;
    this.canDelete = true; // TODO: right now, everybody who can edit can also delete, is this correct?
  }

  ngOnInit(): void {
    const title = this.data.existingNewsItem?.title || '';
    const content = this.data.existingNewsItem?.content || '';
    const category = this.data.existingNewsItem?.category || NewsItemDialogComponent.DEFAULT_CATEGORY;
    const tag = this.data.existingNewsItem?.tag || NewsItemDialogComponent.DEFAULT_TAG;

    this.formGroup = new FormGroup<any>({
      title: new FormControl(title, [Validators.required, Validators.minLength(1)]),
      content: new FormControl(content, [Validators.required, Validators.minLength(1)]),
      category: new FormControl(category),
      tag: new FormControl(tag),
      sendEmail: new FormControl(false),
    });
    this.formGroup.markAllAsTouched();
  }

  categoryToLabel(value: NewsCategory): string {
    return newsCategoryToLabel[value];
  }

  tagToLabel(value: NewsTag): string {
    return newsTagToLabel[value];
  }

  returnPostNews(): NewsItemDialogResult {
    const fgValue = this.formGroup.value;
    return {
      operation: 'CREATE',
      payload: {
        projectId: this.data.projectId,
        title: fgValue.title,
        content: fgValue.content,
        category: fgValue.category,
        tag: fgValue.tag,
        sendEmail: fgValue.sendEmail,
      },
    };
  }

  returnPutNews(): NewsItemDialogResult {
    const fgValue = this.formGroup.value;
    return {
      operation: 'UPDATE',
      payload: {
        title: fgValue.title,
        content: fgValue.content,
        category: fgValue.category,
        tag: fgValue.tag,
        sendEmail: fgValue.sendEmail,
      },
    };
  }

  returnDeleteNews(): NewsItemDialogResult {
    return {
      operation: 'DELETE',
      payload: null,
    };
  }

}
export interface NewsItemDialogResult {
  operation: 'UPDATE' | 'CREATE' | 'DELETE';
  payload: PutNews | PostNews;
}
