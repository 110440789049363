import { ProjectListService } from './../../../services/project-list/project-list.service';
import { DropDownService } from './../../../services/other/drop-down.service';
import { DropDownOption } from './../../../model/drop-down/DropDownOption';
import { CountryAlpha2Code } from './../../../model/geo/CountryAlpha2Code';
import { DateUtil } from './../../../DateUtil';
import { LessonsLearntService } from './../../../services/lessons-learnt/lessons-learnt.service';
import { GetProjects } from 'src/app/shared/services/project-list/GetProjects';
import { LessonLearnt } from './../../../model/lessons-learnt/LessonLearnt';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import { GetUsers } from 'src/app/shared/services/user/GetUsers';
import { LessonsLearntDialogContentComponent } from './lessons-learnt-dialog-content/lessons-learnt-dialog-content.component';

@Component({
  selector: 'digires-lessons-learnt-dialog',
  templateUrl: './lessons-learnt-dialog.component.html',
  styleUrls: ['./lessons-learnt-dialog.component.scss']
})
export class LessonsLearntDialogComponent implements OnInit, AfterContentChecked {

  public allCountryIds: CountryAlpha2Code[] = [];

  @ViewChild(LessonsLearntDialogContentComponent) dialogContent: LessonsLearntDialogContentComponent;

  title = 'Add Lesson Learnt';

  loading = {
    userLoading: true,
    dropDownConfigLoading: true,
  };

  allStatusOptions: DropDownOption[] = [];
  projectName = "";
  anchorId = "";
  userMap: {[userId: string]: GetUsers} = {}
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { project: GetProjects, lessonLearnt: LessonLearnt, column: string },
    private lessonsLearntService: LessonsLearntService,
    private dropDownService: DropDownService,
    private userService: UserService,
    private projectListService: ProjectListService,
    private cdref: ChangeDetectorRef
  ) {
    if (data && data.lessonLearnt) {
      this.title = 'Edit Lesson Learnt';
    }

    this.anchorId = this.data.column;
  }

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();
  }

  private async loadData() {
    this.userService.observeUserMap().subscribe(userMap => {
      this.userMap = userMap;
      this.loading.userLoading = false;
    });

    this.userService.fetchAllUsers();

    this.allCountryIds = await this.projectListService.getUsedCountryCodes();

    this.projectName = this.data.lessonLearnt?.projectId ? (await this.projectListService.fetchProjectById(this.data.lessonLearnt.projectId)).name : 'No Project'

    this.allStatusOptions = (await this.dropDownService.fetchConfig('lessons-learnt-status')).values || [];
    this.loading.dropDownConfigLoading = false;
  }

  public async saveLessonLearnt() {
    const lessonLearnt: LessonLearnt = this.createLessonLearnt();

    if (this.data && this.data.lessonLearnt && this.data.lessonLearnt.id) {
      lessonLearnt.id = this.data.lessonLearnt.id;
      lessonLearnt.itemId = this.data.lessonLearnt.itemId;
      await this.lessonsLearntService.updateLessonLearnt(lessonLearnt);
    } else {
      await this.lessonsLearntService.addLessonLearnt(lessonLearnt);
    }
  }

  private createLessonLearnt() {
    const formValues = this.dialogContent.formGroup.getRawValue();
    const lessonLearnt: LessonLearnt = new LessonLearnt();

    lessonLearnt.applicableTo = formValues.applicableTo;
    lessonLearnt.authorUserId = formValues.authorUserId?.id;
    lessonLearnt.identifierUserId = formValues.identifierUserId?.id;
    lessonLearnt.categoryIds = formValues.categories;
    lessonLearnt.comment = formValues.comment;
    lessonLearnt.countryCodes = formValues.countryCodes;
    lessonLearnt.createDate = DateUtil.anyToDate(formValues.createDate);
    lessonLearnt.currency = formValues.currency;
    lessonLearnt.delay = formValues.delay;
    lessonLearnt.detailsOfIssue = formValues.detailsOfIssue;
    lessonLearnt.projectStage = formValues.projectStage;
    lessonLearnt.discussedInTeam = formValues.discussedInTeam;
    lessonLearnt.hse = formValues.hse;
    lessonLearnt.impactOnBudget = formValues.impactOnBudget;
    lessonLearnt.lessonsLearnedBestPractice = formValues.lessonsLearnedBestPractice;
    lessonLearnt.mitigationForFutureProjects = formValues.mitigationForFutureProjects;
    lessonLearnt.projectId = null;

    if (formValues.projectName === 'No Project') {
      lessonLearnt.projectId = null;
    } else if (this.data.project) {
      lessonLearnt.projectId = this.data.project.projectId;
    } else {
      lessonLearnt.projectId = this.data.lessonLearnt.projectId;
    }

    lessonLearnt.projectPhase = formValues.projectPhase;
    lessonLearnt.quality = formValues.quality;
    lessonLearnt.links = formValues.links;
    lessonLearnt.severity = formValues.severity;
    lessonLearnt.technologies = formValues.technologies;

    if(formValues.status === 'archived' && this.data.lessonLearnt?.status !== 'archived') {
      lessonLearnt.restoreStatus = this.data.lessonLearnt.status;
    } else {
      lessonLearnt.restoreStatus = this.data.lessonLearnt?.restoreStatus;
    }

    lessonLearnt.status = formValues.status;

    return lessonLearnt;
  }

  public async deleteRestoreLessonLearnt() {
    const lessonLearnt = this.data.lessonLearnt;
    const newStatus = lessonLearnt.status === "archived" ? lessonLearnt.restoreStatus : 'archived';
    lessonLearnt.restoreStatus = lessonLearnt.status;
    lessonLearnt.status = newStatus
    await this.lessonsLearntService.updateLessonLearnt(lessonLearnt);
  }

  public deleteRestoreButtonLabel() {
    return this.data.lessonLearnt?.status === "archived" ? 'Restore' : 'Archive';
  }

  public isLoading() {
    return this.loading.dropDownConfigLoading || this.loading.userLoading;
  }


}
