export enum TargetType {
  ATTRITION = 'ATTRITION',
  VOLUME = 'VOLUME',
  COMPLETION_WEIGHT = 'COMPLETION_WEIGHT',
  BUSINESS_MODEL = 'BUSINESS_MODEL',
  ROUTE_TO_MARKET = 'ROUTE_TO_MARKET',
  EBIT = 'EBIT',
  CAPEX = 'CAPEX',
  DEVEX = 'DEVEX',
  NPV = 'NPV',
  HORIZONS = 'HORIZONS',
  PERFORMANCE = 'PERFORMANCE',
  FINANCIAL_DEVEX_ACTUALS = 'FINANCIAL_DEVEX_ACTUALS',
  FINANCIAL_EXCHANGE = 'FINANCIAL_EXCHANGE',
  FINANCIAL_DEVEX_BUDGETS = 'FINANCIAL_DEVEX_BUDGETS',
  FINANCIAL_DEVEX_FORECASTS = 'FINANCIAL_DEVEX_FORECASTS',
}
export const targetTypeToLabel = {
  [TargetType.ATTRITION]: 'Attrition Rates',
  [TargetType.VOLUME]: 'Volume Targets',
  [TargetType.COMPLETION_WEIGHT]: 'Construction Completion Weighting',
  [TargetType.BUSINESS_MODEL]: 'Exposure to Business Model',
  [TargetType.ROUTE_TO_MARKET]: 'Exposure to Route to Market',
  [TargetType.EBIT]: 'EBIT & EBITDA Targets',
  [TargetType.CAPEX]: 'CAPEX Targets',
  [TargetType.DEVEX]: 'DEVEX Targets',
  [TargetType.NPV]: 'NPV Targets',
  [TargetType.HORIZONS]: 'Acquisition & Origination Horizons',
  [TargetType.PERFORMANCE]: 'Performance Tracked Milestones',
  [TargetType.FINANCIAL_DEVEX_ACTUALS]: 'Devex Financial Actuals',
  [TargetType.FINANCIAL_DEVEX_BUDGETS]: 'Devex Financial Budgets',
  [TargetType.FINANCIAL_DEVEX_FORECASTS]: 'Devex Financial Forecast',
  [TargetType.FINANCIAL_EXCHANGE]: 'Exchange Rates Information',
};
export const targetTypeIndex = {}
for (const type of Object.values(TargetType)) {
  targetTypeIndex[type] = Object.values(TargetType).indexOf(type);
}
