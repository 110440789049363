export enum RoutePaths {
  PROJECT_OVERVIEW = 'project-overview',
  PROJECT_DETAILS = 'project-details',
  PROJECT_DETAILS_EMBEDDED = 'project-details-embedded',
  ADMIN_AREA = 'admin-area',
  LESSONS_LEARNT = 'lessons-learnt',
  PROJECT_PARTNERS = 'project-partners',
  PROJECT_CREATION = 'project-creation',
  MY_DIGIRES = 'my-digires',
  REPORTS_AREA = 'reports',
  ACQUISITION = 'acquisitions',
  PROCESS_MODULE = 'process',
  USER_GUIDES_SLIDES = 'slide-pack',
  USER_GUIDES_VIDEOS = 'training-videos',
}
