<mat-dialog-content>
  <h2>Purpose</h2>
  <p>
    The aim of the DigiRES application is to manage projects for the development, construction and operations of
    Onshore Wind plants of RWE Renewables. In case of using our application, information about the user’s behaviour are
    automatically collected in a log file and stored on our web server without assigning them to a specific person.
    According to this, we cannot retrace the user’s data retrieval and for which purposes he or she used the application
    in general. The anonymized user behaviour data are stored for up to six months. They are not utilized to control the
    user’s performance and behaviour.
  </p>

  <p>
    During the sign-in process, you have to use your Active Directory (Group) user to authenticate yourself. Your
    username will be saved in case of changing and saving project information in order to reproduce changes in single
    cases if required.
  </p>
  <p>
    Projects in DigiRES contain information about project managers and other project team members with their
    corresponding role. In addition, each project has a task board for managing project specific tasks and assigning
    them to the different users. This board is visible to dedicated users and all project team members. By agreeing to
    the data privacy guidelines, you allow the project creators/managers to add you to the projects in order to allow
    you to contribute to them and work on the several tasks which you are assigned to. The project information as well
    as the task board are not used for performance tracking and there is no aggregated information on your work which is
    visible to others.
  </p>
  <h2>
    Processing of personal data on behalf
  </h2>
  <p>
    The RWE Renewables has individual tasks and services performed by carefully selected and commissioned IT service
    providers located outside the EU. Therefore, a transmission of personal data to third countries takes place. In
    addition, a third country transmission may also take place if the contractual relationship with you so provides.
    The transmission of data to third countries takes place in compliance with the data protection regulations of the
    EU and national law. To this end, data protection agreements corresponding to the legal requirement.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="ok_button_bar">
  <div><!--Intentionally left blank--></div>
  <button mat-button class="btnNeutral" [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
