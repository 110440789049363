export enum Continent {
  AFRICA = 'AFRICA',
  ANTARCTICA = 'ANTARCTICA',
  ASIA = 'ASIA',
  AUSTRALIA = 'AUSTRALIA',
  EUROPE = 'EUROPE',
  NORTH_AMERICA = 'NORTH_AMERICA',
  SOUTH_AMERICA = 'SOUTH_AMERICA',
}
export const continentToLabel = {
  [Continent.AFRICA]: 'Africa',
  [Continent.ANTARCTICA]: 'Antarctica',
  [Continent.ASIA]: 'Asia',
  [Continent.AUSTRALIA]: 'Australia',
  [Continent.EUROPE]: 'Europe',
  [Continent.NORTH_AMERICA]: 'North America',
  [Continent.SOUTH_AMERICA]: 'South America',
};

