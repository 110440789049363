import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'digires-button',
  templateUrl: './digi-res-button.component.html',
  styleUrls: ['./digi-res-button.component.scss']
})
export class DigiResButtonComponent {

  @Output() onClick = new EventEmitter<MouseEvent>();

  @Input() flair: 'Positive' | 'Negative' | 'Neutral' = 'Neutral';
  @Input() tooltip: string;
  @Input() disabled: boolean = false;
  @Input() icon: string;

}
