export enum FileReferenceType {
  VIDEO = 'VIDEO',
  SLIDE = 'SLIDE',
  ISA = 'ISA',
  LESSONS_LEARNED = 'LESSONS_LEARNED',
}
export const fileRefTypeToTitle: Record<FileReferenceType, string> = {
  [FileReferenceType.SLIDE]: 'User Guide - Slide Packs',
  [FileReferenceType.VIDEO]: 'User Guide - Training Videos',
  [FileReferenceType.ISA]: 'ISA Documents',
  [FileReferenceType.LESSONS_LEARNED]: 'Lessons Learned Documents',
};
export const fileRefTypeToAddButtonLabel: Record<FileReferenceType, string> = {
  [FileReferenceType.SLIDE]: 'Add New Slide Pack',
  [FileReferenceType.VIDEO]: 'Add New Video',
  [FileReferenceType.ISA]: 'Add New ISA Document',
  [FileReferenceType.LESSONS_LEARNED]: ' Add new Lessons Learned Document',
};

export interface PostPutReferenceRequest {
  ref: string;
  title: string;
  type: FileReferenceType;
  link: string;
}

export interface PutFileReferenceRequest {
  id: string;
  ref: string;
  title: string;
  link: string;
}

export interface GetReferenceResponse {
  id: string;
  ref: string;
  title: string;
  type: FileReferenceType;
  link: string;
  lastUpdatedByUserId: string;
  lastUpdatedTime: Date;
  lastUpdatedUsername: string;
  originalUploadByUserId: string;
  originalUploadTime: Date;
  originalUploadUsername: string;
}

export interface UserGuidesTableData {
  id: string;
  ref: string;
  title: string;
  type: string;
  link: string;
  action?: string | null;
  lastUpdatedByUserId: string;
  lastUpdatedTime: string;
  lastUpdatedUsername: string;
  originalUploadByUserId: string;
  originalUploadTime: string;
  originalUploadUsername: string;
}
