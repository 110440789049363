import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { CountryAlpha2Code } from 'src/app/shared/model/geo/CountryAlpha2Code';
import { LessonLearnt } from 'src/app/shared/model/lessons-learnt/LessonLearnt';
import { GetProjects } from 'src/app/shared/services/project-list/GetProjects';
import { ProjectPhase, phaseToLabel } from 'src/app/shared/model/general/ProjectPhase';
import { Technology, technologyToLabel } from 'src/app/shared/model/general/Technology';
import { Currency, currencyToLabel } from 'src/app/shared/model/general/Currency';
import { DiscussedInTeam, discussedInTeamToLabel } from 'src/app/shared/model/lessons-learnt/DiscussedInTeam';
import { LessonLearntBestPractice, lessonLearntBestPracticeToLabel } from 'src/app/shared/model/lessons-learnt/LessonLearntBestPractice';
import { DropDownOption } from 'src/app/shared/model/drop-down/DropDownOption';
import { Country } from 'src/app/shared/model/geo/Country';
import { ALL_COUNTRIES } from 'src/app/shared/model/geo/Countries';
import { GetReferenceResponse } from 'src/app/shared/model/user-guides-table.model';
import { FileRefDialogComponent } from '../../../file-ref-dialog/file-ref-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectStage, stageToLabel } from 'src/app/shared/model/general/ProjectStage';

@Component({
  selector: 'digires-lessons-learnt-dialog-content',
  templateUrl: './lessons-learnt-dialog-content.component.html',
  styleUrls: ['./lessons-learnt-dialog-content.component.scss']
})
export class LessonsLearntDialogContentComponent implements OnInit, AfterViewInit {

  readonly projectStages = Object.values(ProjectStage);
  readonly projectPhases = Object.keys(ProjectPhase);
  readonly technologies = Object.keys(Technology);
  readonly currencies = Object.keys(Currency);
  readonly allDiscussedInTeamOptions = Object.keys(DiscussedInTeam);
  readonly allLessonLearntBestPracticeOptions = Object.keys(LessonLearntBestPractice);
  readonly errorText = 'Please enter a Value, this field is mandatory.';

  @Input() lessonLearnt: LessonLearnt;
  @Input() project: GetProjects;
  @Input() projectName: string;
  @Input() allCountryIds: CountryAlpha2Code[];
  @Input() allStatusOptions: DropDownOption[];

  @Input() anchorId: string;

  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }


  ngOnInit(): void {
    this.initFormGroup();
  }

  ngAfterViewInit(): void {
    const element = document.getElementById(this.anchorId);

    element?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
  }

  private initFormGroup() {
    let itemId: string;
    let countryCodes: CountryAlpha2Code[];
    let projectName = this.projectName;
    let createDate: Date;
    let authorUserId: string = this.authService.getUserId();
    let identifierUserId: string;
    let discussedInTeam: string;
    let status: string;
    let technologies: string[];
    let applicableTo: string;
    let lessonsLearnedBestPractice: string;
    let projectPhase: string[];
    let projectStage: string[];
    let categoryIds: string[];
    let detailsOfIssue: string;
    let mitigationForFutureProjects: string;
    let comment: string;
    let severity: string;
    let delay: Number;
    let impactOnBudget: Number;
    let currency: string;
    let hse: Number;
    let quality: string;
    let links: GetReferenceResponse[];

    if (this.lessonLearnt) {
      itemId = this.lessonLearnt.itemId;
      countryCodes = this.lessonLearnt.countryCodes;
      createDate = this.lessonLearnt.createDate;
      authorUserId = this.lessonLearnt.authorUserId;
      identifierUserId = this.lessonLearnt.identifierUserId;
      discussedInTeam = this.lessonLearnt.discussedInTeam;
      status = this.lessonLearnt.status;
      technologies = this.lessonLearnt.technologies;
      applicableTo = this.lessonLearnt.applicableTo;
      lessonsLearnedBestPractice = this.lessonLearnt.lessonsLearnedBestPractice;
      projectPhase = this.lessonLearnt.projectPhase;
      projectStage = this.lessonLearnt.projectStage;
      categoryIds = this.lessonLearnt.categoryIds;
      detailsOfIssue = this.lessonLearnt.detailsOfIssue;
      mitigationForFutureProjects = this.lessonLearnt.mitigationForFutureProjects;
      comment = this.lessonLearnt.comment;
      severity = this.lessonLearnt.severity;
      delay = this.lessonLearnt.delay;
      impactOnBudget = this.lessonLearnt.impactOnBudget;
      currency = this.lessonLearnt.currency;
      hse = this.lessonLearnt.hse;
      quality = this.lessonLearnt.quality;
      links = this.lessonLearnt.links;
    } else if (this.project) {
      projectName = this.project.name;
      countryCodes = [this.project.countryCode];
    }

    this.formGroup = this.fb.group({
      itemId: new FormControl(itemId),
      projectName: new FormControl(projectName),
      countryCodes: new FormControl(countryCodes, Validators.required),
      createDate: new FormControl(createDate, Validators.required),
      authorUserId: new FormControl(authorUserId, Validators.required),
      identifierUserId: new FormControl(identifierUserId),
      discussedInTeam: new FormControl(discussedInTeam),
      status: new FormControl(status, Validators.required),
      technologies: new FormControl(technologies),
      applicableTo: new FormControl(applicableTo),
      lessonsLearnedBestPractice: new FormControl(lessonsLearnedBestPractice, Validators.required),
      projectPhase: new FormControl(projectPhase, Validators.required),
      projectStage: new FormControl(projectStage, Validators.required),
      categories: new FormControl(categoryIds, Validators.required),
      detailsOfIssue: new FormControl(detailsOfIssue, Validators.required),
      mitigationForFutureProjects: new FormControl(mitigationForFutureProjects, Validators.required),
      comment: new FormControl(comment || ''),
      severity: new FormControl(severity, Validators.required),
      delay: new FormControl(delay, Validators.pattern('^[0-9]*$')),
      impactOnBudget: new FormControl(impactOnBudget),
      currency: new FormControl(currency),
      hse: new FormControl(hse, Validators.pattern('^-?[0-9]*$')),
      quality: new FormControl(quality || ''),
      links: new FormControl(links),
    });

    this.formGroup.markAllAsTouched();
  }

  openReferenceDialog() {
    this.dialog.open(FileRefDialogComponent, {
      hasBackdrop: true,
      width: '80vw',
      minWidth: '1024px',
      data: {
        entries: this.formGroup.value.links,
    } as GetReferenceResponse[]})
      .afterClosed()
      .subscribe((results: {[key: string]: {id: string, ref: string, title: string, link: string}}) => {
        if (!results) {
          return;
        }
        const userId = this.authService.getUserId();
        const userName = this.authService.getUserName();
        const time = new Date();
        this.formGroup.patchValue({
          links: Object.values(results).map(
            data => {
              return {
                id: data.id,
                ref: data.ref,
                title: data.title,
                type: null,
                link: data.link,
                lastUpdatedByUserId: userId,
                lastUpdatedUsername: userName,
                lastUpdatedTime: time,
                originalUploadByUserId: userId,
                originalUploadUsername: userName,
                originalUploadTime: time,
              };
            }
          ),
        });
      });
  }

  isReadOnly() {
    return this.lessonLearnt?.status === 'archived';
  }

  readOnlyReason() {
    return this.lessonLearnt?.status === 'archived' ? 'Status is "archived"' : ''
  }

  getStatusOptionsForTooltip() {
    return 'This identifies the current Status of the Item as it progresses from: ' + this.allStatusOptions.map(ddo => ddo.label).toString();
  }

  dropDownOptionItemToID(value: DropDownOption) {
    return value.id;
  }

  dropDownOptionItemToLabel(value: DropDownOption) {
    return value.label;
  }

  phaseToLabel(phase: ProjectPhase) {
    return phaseToLabel[phase];
  }

  stageToLabel(stage: ProjectStage) {
    return stageToLabel[stage];
  }

  technologyToLabel(technology: Technology) {
    return technologyToLabel[technology];
  }

  currencyToLabel(currency: Currency) {
    return currencyToLabel[currency];
  }

  countryToId(country: Country) {
    return country.alpha2Code ? country.alpha2Code : country;
  }

  countryToLabel(countryCode: CountryAlpha2Code) {
    return ALL_COUNTRIES.find(country => country.alpha2Code === countryCode).name;
  }

  discussedInTeamToLabel(discussedInTeam: DiscussedInTeam) {
    return discussedInTeamToLabel[discussedInTeam];
  }

  lessonLearntBestPracticeToLabel(lessonLearntBestPractice: LessonLearntBestPractice) {
    return lessonLearntBestPracticeToLabel[lessonLearntBestPractice];
  }
}
